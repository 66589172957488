import React from 'react'
import { inject, observer } from 'mobx-react'
import { RemindersStore } from '../../store/RemindersStore'
import { IonLabel, IonSegment, IonSegmentButton, IonBadge, IonIcon } from '@ionic/react'
import { AppStore } from 'src/stores/AppStore'
import { list, informationCircle, compass, pricetags } from 'ionicons/icons'

interface Props {
  remindersStore?: RemindersStore
  appStore?: AppStore
}

const TabButtons: React.FC<Props> = ({ remindersStore, appStore }) => {
  const { editVM } = remindersStore
  if (!editVM) return null

  return (
    <div id='TabButtons'>
      <IonSegment
        value={editVM.currentTabIndex}
        scrollable
        onIonChange={(e) => editVM.setCurrentTab(e.detail.value)}
        color={appStore.isIos ? null : 'tertiary'}
      >
        <IonSegmentButton value='0' className='info' layout='icon-top'>
          <IonIcon icon={informationCircle} />
          <IonLabel>Info</IonLabel>
        </IonSegmentButton>
        <IonSegmentButton value='1' layout='icon-top'>
          <IonIcon icon={list} />
          <IonLabel>Schedule</IonLabel>
        </IonSegmentButton>
        <IonSegmentButton value='2' layout='icon-top'>
          <IonIcon icon={compass} />
          <IonLabel>Transactions</IonLabel>
        </IonSegmentButton>
      </IonSegment>
    </div>
  )
}

export default inject('remindersStore', 'appStore')(observer(TabButtons))
