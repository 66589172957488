import React from 'react'
import {
  IonList,
  IonItem,
  IonButton,
  IonModal,
  IonLabel,
  IonInput,
  IonCard,
  IonCardHeader,
  IonTitle,
  IonCardContent,
  IonToolbar,
  IonItemDivider,
} from '@ionic/react'
import { observer, inject } from 'mobx-react'
import { BoardsStore } from '../store/BoardsStore'
import './../styles/BoardNewModal.scss'

interface Props {
  boardsStore?: BoardsStore
}

const JoinBoardRow: React.FC<Props> = ({ boardsStore }) => {
  const { editVM: vm } = boardsStore
  if (!vm) return null

  return (
    <IonList mode='md'>
      <IonItemDivider color='medium' mode='md'>
        Join Another Board
      </IonItemDivider>
      <IonItem lines={'inset'}>
        <IonLabel>
          <IonButton color='primary' onClick={() => vm.openJoinBoardModal()}>
            Enter Code
          </IonButton>
        </IonLabel>
      </IonItem>
    </IonList>
  )
}

export default inject('boardsStore')(observer(JoinBoardRow))
