import React, { useState } from 'react'
import { inject, Observer, observer } from 'mobx-react'
import { RemindersStore } from '../../store/RemindersStore'
import { IonBadge, IonIcon, IonList, IonText } from '@ionic/react'
import { List } from '@elexient/elexiapp.bits.shared'
import { ReminderGroupRowVM } from '../../view-models/list/ReminderGroupRowVM'
import { personCircleOutline, chevronDown, chevronForward, refresh } from 'ionicons/icons'
import { ReminderRowVM } from '../../view-models/list/ReminderRowVM'
import { AppStore } from '../../../stores/AppStore'

interface Props {
  remindersStore?: RemindersStore
  appStore?: AppStore
}

const RemindersList: React.FC<Props> = ({ remindersStore, appStore }) => {
  const listVM = remindersStore.listVM
  if (!listVM) return null
  if (!listVM.shown) return null

  const renderAutoPaid = (row: ReminderRowVM) => {
    if (!row.autoPaid) return
    return (
      <div className='auto-paid'>
        <IonIcon icon={refresh} size='small' />
      </div>
    )
  }

  const renderCategory = (row: ReminderRowVM) => {
    if (!row.hasCategory) return
    return (
      <IonBadge color='dark' style={{ backgroundColor: row.categoryColor }}>
        {row.categoryName}
      </IonBadge>
    )
  }

  const renderNormalRow = (row: ReminderRowVM, index, style) => {
    const style2 = {
      borderLeftColor: row.color,
      ...style,
    }

    return (
      <Observer key={row.key + String(index) + row.rowType}>
        {() => (
          <div style={style2} key={row.key} className='normal-row' onClick={() => row.click()}>
            <div className='date'>
              <div>{row.day}</div>
            </div>
            <div className='payee'>
              <div>{row.name}</div>
              <div className={row.hasNotes ? 'rem-notes' : 'rem-notes no-notes'}>{row.notes}</div>
            </div>
            <div className='badges'>{renderCategory(row)}</div>
            {renderAutoPaid(row)}
            <IonText className={row.isPositive ? 'numbers positive' : 'numbers negative'}>{row.amount}</IonText>
          </div>
        )}
      </Observer>
    )
  }

  const renderRightIcon = (row: ReminderGroupRowVM) => {
    if (!row.isCollapsed) return
    return <IonIcon icon={chevronForward} />
  }

  const renderDownIcon = (row: ReminderGroupRowVM) => {
    if (row.isCollapsed) return
    return <IonIcon icon={chevronDown} />
  }

  const renderGroupRow = (row: ReminderGroupRowVM, index, style) => {
    return (
      <Observer key={row.key + String(index) + row.rowType + row.isCollapsed + row.isInvView}>
        {() => (
          <div style={style} className={row.rowType} onClick={() => row.click()}>
            <div className='main-cell'>{row.displayName}</div>
            {renderRightIcon(row)}
            {renderDownIcon(row)}
          </div>
        )}
      </Observer>
    )
  }

  return (
    <List
      listVM={listVM}
      className='list'
      normalRow={(row, index, style) => renderNormalRow(row, index, style)}
      groupRow={(row, index, style) => renderGroupRow(row, index, style)}
      onRef={(e) => appStore.applyScrollStyles(e)}
    />
  )
}

export default inject('remindersStore', 'appStore')(observer(RemindersList))
