import { observable, action, computed, makeObservable } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { Transaction } from '../aggregate/Transaction'
import { TransactionsService } from '../services/TransactionsService'
import { SelectableColors } from '../../shared/SelectableColors'
import { Category } from '../../categories/aggregate/Category'
import { TransactionStatusEnumName } from '../enum/TransactionStatusEnumName'
import { TransactionTypeEnumName } from '../enum/TransactionTypeEnumName'
import { NewTransactionTypeEnum } from '../enum/NewTransactionTypeEnum'
import { NewTransactionTypeEnumName } from '../enum/NewTransactionTypeEnumName'
import { TransactionTypeEnum } from '../enum/TransactionTypeEnum'
import moment from 'moment'
import { TransactionStatusEnum } from '../enum/TransactionStatusEnum'
import { CategorySplit } from '../aggregate/CategorySplit'
import { ExpenseEditVM } from './ExpenseEditVM'

export class CategorySplitRowVM {
  private rootStore: RootStore
  private categorySplit: CategorySplit
  private parentVM: ExpenseEditVM
  parentAmtTO: NodeJS.Timeout

  constructor(rootStore: RootStore, parentVM: ExpenseEditVM, categorySplit: CategorySplit) {
    makeObservable(this)
    this.rootStore = rootStore
    this.parentVM = parentVM
    this.categorySplit = categorySplit
  }

  @computed
  public get categoryGuid(): string {
    return this.categorySplit.CategoryGuid
  }

  @action
  public setCategory(val: string) {
    this.categorySplit.setCategory(val)
  }

  @action
  public delete() {
    this.categorySplit.markAsDeleted()
  }

  @action
  public setAmount(val: number) {
    this.categorySplit.setAmount(val)
    if (this.parentAmtTO) clearTimeout(this.parentAmtTO)
    this.parentAmtTO = setTimeout(() => {
      if (this.parentVM.amountTouched) return
      this.parentVM.setAmountToCategorySplitsTotal()
    }, 600)
  }

  @computed
  public get amount(): number {
    return Math.abs(this.categorySplit.Amount)
  }
  
  @computed
  public get amountAsString(): string {
    if (this.amount === 0) return ''
    return this.amount.toString()
  }
}
