import { observable, action, computed, runInAction, makeObservable } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { Account } from '../aggregate/Account'
import { AccountsService } from '../services/AccountsService'
import { DateRangeEnumName } from '../../accounts/enum/DateRangeEnumName'
import { SelectableColors } from '../../shared/SelectableColors'
import AgentV2 from '../../AgentV2'
import { ITradePlaidPublicTokenRequest } from '../../bank-transactions/interfaces/ITradePlaidPublicTokenRequest'
import { PlaidItem } from '../../plaid-items/aggregate/PlaidItem'
import { Account as PlaidItemAccount } from '../../plaid-items/aggregate/Account'

export class AccountEditVM {
  private rootStore: RootStore
  private account: Account

  constructor(rootStore: RootStore, account: Account, isNew: boolean = false) {
    makeObservable(this)
    this.rootStore = rootStore
    this.account = account.clone()
    this.isNew = isNew
    this.getPlaidLinkToken()
    this.getPlaidLinkTokenForUpdate()
  }

  @observable public deleteConfirmShown: boolean = false
  @observable public plaidLinkToken: string = undefined
  public isNew: boolean = false

  @action
  public setName(val) {
    this.account.setName(val)
  }

  @computed
  public get name(): string {
    return this.account.Name
  }

  @computed
  public get clearedBalance(): number {
    return this.account.ClearedBalance
  }

  @action
  public setClearedBalance(val: number) {
    this.account.setClearedBalance(val)
  }

  @computed
  public get isValid(): boolean {
    if (!this.name || this.name === '') return false
    return true
  }

  @computed
  public get pageTitle(): string {
    if (this.isNew) return 'New Account'
    return 'Edit Account'
  }

  @action
  public save() {
    const svc = new AccountsService(this.rootStore)
    svc.save(this.account.toDTO())
    this.rootStore.appStore.history.push('/transactionslist/' + this.account.AccountGuid)
  }

  @action
  public showDeleteConfirm() {
    this.deleteConfirmShown = true
  }

  @action
  public hideDeleteConfirm() {
    this.deleteConfirmShown = false
  }

  @computed
  public get color(): string {
    if (!this.account.Color) return ''
    return this.account.Color
  }

  @action
  public setColor(hex: string) {
    this.account.setColor(hex)
  }

  public delete() {
    const svc = new AccountsService(this.rootStore)
    svc.delete(this.account.toDTO())
    this.rootStore.appStore.handleGoBack()
  }

  @computed
  public get notes(): string {
    return this.account.Notes
  }

  @action
  public setNotes(val: string) {
    this.account.setNotes(val)
  }

  @action
  public setType(val: number) {
    this.account.setType(val)
  }

  @computed
  public get typeId(): number {
    return this.account.TypeId
  }

  public get accountTypes() {
    return [
      { id: 1, name: 'Cash' },
      { id: 2, name: 'Checking' },
      { id: 3, name: 'Savings' },
      { id: 4, name: 'Credit Card' },
      { id: 5, name: 'Loan' },
    ]
  }

  @computed
  public get dateRangeId() {
    return this.account.DateRangeId
  }

  public get dateRanges() {
    return DateRangeEnumName.list
  }

  @action
  public setDateRange(val: number) {
    this.account.setDateRange(val)
  }

  public get colors() {
    return SelectableColors.colors
  }

  @action
  private async getPlaidLinkToken() {
    if (this.selectedPlaidItem) return
    const token = await AgentV2.PlaidItems.getLinkToken(this.rootStore.boardsStore.currentBoardId)
    runInAction(() => (this.plaidLinkToken = token))
  }

  @action
  private async getPlaidLinkTokenForUpdate() {
    if (!this.selectedPlaidItem) return
    if (!this.selectedPlaidItem.NeedsUpdating) return
    const token = await AgentV2.PlaidItems.getLinkTokenForUpdate(this.selectedPlaidItem.PlaidItemGuid)
    runInAction(() => (this.plaidLinkToken = token))
  }

  @action
  public async onLinkSuccess(publicToken: string) {
    if (this.plaidItemNeedsUpdating) {
      const response = await AgentV2.PlaidItems.doneUpdating(this.selectedPlaidItem.PlaidItemGuid)
      alert(response)
      return
    }
    await this.tradePlaidPublicToken(publicToken)
  }

  @action
  public async tradePlaidPublicToken(publicToken: string) {
    const req: ITradePlaidPublicTokenRequest = {
      publicToken: publicToken,
      boardId: this.rootStore.boardsStore.currentBoardId,
    }
    const plaidItem = await AgentV2.PlaidItems.tradePublicToken(req)
    this.rootStore.plaidItemsStore.updateFromServer(plaidItem)
    this.account.setPlaidItem(plaidItem.PlaidItemGuid)
  }

  @computed
  public get plaidItemGuid(): string {
    return this.account.PlaidItemGuid
  }

  @computed
  public get plaidItems(): PlaidItem[] {
    return this.rootStore.plaidItemsStore.currentBoardRecords
  }

  @computed
  public get selectedPlaidItem(): PlaidItem {
    return this.rootStore.plaidItemsStore.get(this.account.PlaidItemGuid)
  }

  @computed
  public get bankAccounts(): PlaidItemAccount[] {
    if (!this.selectedPlaidItem) return []
    return this.selectedPlaidItem.Accounts
  }

  @computed
  public get plaidItemAccountGuid(): string {
    return this.account.PlaidItemAccountGuid
  }

  @action
  public setPlaidItem(val: string) {
    this.account.setPlaidItem(val)
    console.log(val)
  }

  @action
  public setPlaidItemAccount(val: string) {
    this.account.setPlaidItemAccount(val)
  }

  @action
  public clearPlaidItemAccount() {
    this.account.clearPlaidItemAccount()
    this.getPlaidLinkToken()
  }

  @computed
  public get plaidItemNeedsUpdating(): boolean {
    if (!this.selectedPlaidItem) return false
    return this.selectedPlaidItem.NeedsUpdating
  }

  @computed
  public get hasLinkedPlaidAccount(): boolean {
    return Boolean(this.account.PlaidItemAccountGuid)
  }
}
