import React from 'react'
import { inject, observer } from 'mobx-react'
import { RemindersStore } from '../../store/RemindersStore'
import { AppStore } from '../../../stores/AppStore'
import {
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonSelect,
  IonSelectOption,
  IonCol,
  IonGrid,
  IonRow,
  IonToggle,
} from '@ionic/react'
import { CirclePicker } from 'react-color'

interface Props {
  remindersStore?: RemindersStore
  appStore?: AppStore
}

const InfoForm: React.FC<Props> = ({ remindersStore, appStore }) => {
  const { editVM } = remindersStore
  if (!editVM) return null
  if (appStore.bodyHeight === 0) return null
  if (editVM.currentTabIndex !== '0') return null

  const renderColor = () => {
    return (
      <IonItem lines='inset'>
        <IonLabel position='floating' className='forced-floating'>
          Color
        </IonLabel>
        <CirclePicker
          color={editVM.color}
          circleSize={20}
          width={'100%'}
          colors={editVM.colors}
          onChange={(e) => editVM.setColor(e.hex)}
        />
      </IonItem>
    )
  }

  const renderName = () => {
    return (
      <IonItem lines={'inset'}>
        <IonLabel position='floating'>Name</IonLabel>
        <IonInput type='text' value={editVM.name} onIonChange={(e: any) => editVM.setName(e.target.value)} />
      </IonItem>
    )
  }

  const renderNotes = () => {
    return (
      <IonItem lines={'inset'}>
        <IonLabel position='floating'>Notes</IonLabel>
        <IonInput type='text' value={editVM.notes} onIonChange={(e: any) => editVM.setNotes(e.target.value)} />
      </IonItem>
    )
  }

  const renderType = () => {
    return (
      <IonItem lines={'inset'}>
        <IonLabel position='floating'>Type</IonLabel>
        <IonSelect interface='popover' value={editVM.typeId} onIonChange={(e: any) => editVM.setType(e.target.value)}>
          {editVM.types.map((e) => (
            <IonSelectOption key={e.id} value={e.id}>
              {e.name}
            </IonSelectOption>
          ))}
        </IonSelect>
      </IonItem>
    )
  }

  const renderAccount = () => {
    return (
      <IonItem lines={'inset'}>
        <IonLabel position='floating'>Account</IonLabel>
        <IonSelect
          interface='popover'
          value={editVM.accountGuid}
          onIonChange={(e: any) => editVM.setAccount(e.target.value)}
        >
          {editVM.accounts.map((e) => (
            <IonSelectOption key={e.AccountGuid} value={e.AccountGuid}>
              {e.Name}
            </IonSelectOption>
          ))}
        </IonSelect>
      </IonItem>
    )
  }

  const renderAutoPaid = () => {
    return (
      <IonItem lines={'inset'}>
        <IonLabel position='floating' className='forced-floating'>
          Auto Paid
        </IonLabel>
        <IonToggle color='tertiary' checked={editVM.autoPaid} onIonChange={(val) => editVM.toggleAutoPaid()} />
      </IonItem>
    )
  }

  const renderSendPastDueNotification = () => {
    return (
      <IonItem lines={'inset'}>
        <IonLabel position='floating' className='forced-floating'>
          Send Past Due Notification
        </IonLabel>
        <IonToggle
          color='tertiary'
          checked={editVM.sendPastDueNotification}
          onIonChange={(val) => editVM.toggleSendPastDueNotification()}
        />
      </IonItem>
    )
  }

  const renderCategory = () => {
    return (
      <IonItem lines={'inset'}>
        <IonLabel position='floating'>Category</IonLabel>
        <IonSelect
          interface='popover'
          value={editVM.categoryGuid}
          onIonChange={(e: any) => editVM.setCategory(e.target.value)}
        >
          {editVM.categories.map((e) => (
            <IonSelectOption key={e.CategoryGuid} value={e.CategoryGuid}>
              {e.Name}
            </IonSelectOption>
          ))}
        </IonSelect>
      </IonItem>
    )
  }

  const renderAmount = () => {
    return (
      <IonItem lines={'inset'}>
        <IonLabel position='floating'>Amount</IonLabel>
        <IonInput
          type='number'
          value={editVM.amountAsString}
          onIonChange={(e: any) => editVM.setAmount(e.target.value)}
        />
      </IonItem>
    )
  }

  return (
    <form
      id='InfoForm'
      onSubmit={(e) => {
        e.preventDefault()
        editVM.save()
      }}
    >
      <IonGrid>
        <IonRow>
          <IonCol sizeXs='12'> {renderName()}</IonCol>
        </IonRow>
        <IonRow>
          <IonCol sizeMd='6' sizeXs='6'>
            {renderType()}
          </IonCol>
          <IonCol sizeMd='6' sizeXs='6'>
            {renderAmount()}
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol sizeMd='6' sizeXs='6'>
            {renderAccount()}
          </IonCol>
          <IonCol sizeMd='6' sizeXs='6'>
            {renderCategory()}
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol sizeMd='6' sizeXs='6'>
            {renderAutoPaid()}
          </IonCol>
          <IonCol sizeMd='6' sizeXs='6'>
            {renderSendPastDueNotification()}
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol sizeXs='12'>{renderColor()}</IonCol>
        </IonRow>
        <IonRow>
          <IonCol sizeMd='12'>{renderNotes()}</IonCol>
        </IonRow>
      </IonGrid>
    </form>
  )
}

export default inject('remindersStore', 'appStore')(observer(InfoForm))
