import { observable, action, runInAction, computed, makeObservable } from 'mobx'
import agent from '../../Agent'
import _ from 'lodash'
import { User } from '../aggregate/User'
import { UserEditVM } from '../view-models/UserEditVM'
import ForgotPasswordForm from '../view-models/ForgotPasswordForm'
import ResetPasswordForm from '../view-models/ResetPasswordForm'
import { RootStore } from '../../stores/RootStore'
import { makePersistable } from 'mobx-persist-store'
import { deserialize } from 'serializr'

export class UserStore {
  private rootStore: RootStore

  constructor(rootStore) {
    makeObservable(this)
    makePersistable(this, { name: 'UserStore', properties: ['user'] }).then((st) => {
      let isHydrated = false
      if (process.env.NODE_ENV === 'test') isHydrated = true
      if (st && st.isHydrated) isHydrated = true
      if (isHydrated) this.onHydrationCompleted()
    })
    this.rootStore = rootStore
  }

  public onHydrationCompleted() {
    this.forgotPasswordForm = new ForgotPasswordForm(this.rootStore)
  }

  @observable public userEditVM: UserEditVM
  @observable public forgotPasswordForm: ForgotPasswordForm
  @observable public resetPasswordForm: ResetPasswordForm
  @observable public user: User = new User()

  @action
  public async loadUser() {
    try {
      const response = await agent.Users.getInfo()
      if (response) {
        runInAction(() => {
          this.updateUserFromServer(response)
          this.loadUserEditVM()
        })
      }
    } catch (e) {
      console.log(JSON.stringify(e))
      if (JSON.stringify(e).includes(':401')) {
        this.rootStore.authStore.logout()
        this.rootStore.appStore.history.push('/login')
      }
    }
  }

  public updateUserFromServer(fromUser) {
    this.user = deserialize(User, fromUser)
    return this.user
  }

  @action
  public loadResetPasswordForm(token: string) {
    if (!token) return
    this.resetPasswordForm = new ResetPasswordForm(this.rootStore, token)
  }

  @action
  public loadUserEditVM() {
    if (!this.user) {
      setTimeout(() => this.loadUserEditVM(), 1000)
      return
    }
    this.userEditVM = new UserEditVM(this.rootStore, this.user)
  }

  public clearData() {
    this.user = new User()
  }
}
