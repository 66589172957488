import { observable, computed, makeObservable, action } from 'mobx'
import { IUpcomingBillDTO } from '../dtos/IUpcomingBillDTO'
import { serialize, deserialize, serializable } from 'serializr'
import { IAggregrate } from '../../shared/IAggregate'

export class UpcomingBill implements IUpcomingBillDTO, IAggregrate {
  constructor() {
    makeObservable(this)
  }

  @serializable @observable public BankTransactionGuid: string
  @serializable @observable public BoardId: number
  @serializable @observable public PayeeName: string
  @serializable @observable public Amount: number
  @serializable @observable public TransactionDate: string
  @serializable @observable public AccountGuid: string
  @serializable @observable public IsDeleted: boolean
  @serializable @observable public TransactionGuid: string
  @serializable @observable public ReminderGuid: string
  @serializable @observable public CategoryGuid: string
  @serializable @observable public Notes: string
  @serializable @observable public AutoPaid: boolean

  public clone(): UpcomingBill {
    return deserialize(UpcomingBill, serialize(this))
  }

  public toDTO(): IUpcomingBillDTO {
    return serialize(this)
  }
}
