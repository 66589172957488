import moment from 'moment'
import { RootStore } from '../../stores/RootStore'
import { UpcomingBill } from '../aggregate/UpcomingBill'
import Sugar from 'sugar'
import { computed } from 'mobx'
import { CategoryBadgeVM } from './CategoryBadgeVM'

export class UpcomingBillRowVM {
  constructor(private rootStore: RootStore, private record: UpcomingBill) {}

  public get payeeName(): string {
    return this.record.PayeeName
  }

  public get key(): string {
    return this.record.ReminderGuid
  }

  public click() {
    this.rootStore.appStore.navigateTo('/reminderedit/' + this.record.ReminderGuid)
  }

  public get monthAbbr(): string {
    let str = Sugar.Date(this.record.TransactionDate).format('%b').raw
    return str
  }

  public get day(): string {
    if (!this.record.TransactionDate) return ''
    const day = this.transactionDate.clone().format('D')
    return day
  }

  public get transactionDate(): moment.Moment {
    return moment(this.record.TransactionDate, moment.ISO_8601).add(1, 'hour')
  }
  
  public get autoPaid(): boolean {
    return Boolean(this.record.AutoPaid)
  }

  @computed
  public get accountName(): string {
    const acc = this.rootStore.accountsStore.get(this.record.AccountGuid)
    if (!acc) return ''
    return acc.Name
  }

  public get amountFormatted(): string {
    let str = Sugar.Number(this.record.Amount).format(2).raw
    if (this.isPositive) str = '+' + str
    return str
  }

  public get isPositive(): boolean {
    return this.record.Amount > 0
  }

  public get amount(): number {
    return this.record.Amount
  }

  public get hasNotes(): boolean {
    return Boolean(this.record.Notes)
  }

  public get notes(): string {
    if (!this.hasNotes) return 'No Notes'
    return this.record.Notes
  }

  @computed
  public get accountBadge(): CategoryBadgeVM {
    return new CategoryBadgeVM(this.rootStore, 'transfer', undefined, undefined, this.record.AccountGuid)
  }

  @computed
  public get categoryBadge(): CategoryBadgeVM {
    if (!this.record.CategoryGuid) return undefined
    return new CategoryBadgeVM(this.rootStore, 'category', this.record.CategoryGuid)
  }
}
