import * as signalR from '@microsoft/signalr'
import { MessagePackHubProtocol } from '@microsoft/signalr-protocol-msgpack'
import * as Sentry from '@sentry/browser'
import { RootStore } from '../stores/RootStore'

declare global {
  interface Window {
    Offline: any
  }
}

export class SignalRServiceV3 {
  private rootStore: RootStore
  private connection: signalR.HubConnection
  private listeners = []

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    this.listenToServer()
  }

  private listenToServer() {
    if (!this.rootStore.appStore || !this.rootStore.appStore.isLoggedIn) {
      setTimeout(() => this.listenToServer(), 5000)
      return
    }
    if (window.Offline.state === 'down') {
      setTimeout(() => this.listenToServer(), 5000)
      return
    }
    setTimeout(() => this.restartConnection(), 5000)
  }

  public attachNewListener(updateType: string, callback) {
    if (!this.listeners.find((e) => e.updateType === updateType)) {
      this.listeners.push({ updateType, callback })
    }
  }

  private attachListeners() {
    this.listeners.forEach((e) => this.attachListener(e.updateType, e.callback))
  }

  private attachListener(updateType: string, callback) {
    console.log('attached listener: ' + updateType.toLowerCase())
    this.connection.on(updateType.toLowerCase(), (e) => {
      console.log('received update: ' + updateType + ':')
      console.log(e)
      callback(e)
    })
  }

  private restartConnection() {
    const url = process.env.REACT_APP_API_URL + '/appupdates'
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(url, {
        accessTokenFactory: () => this.rootStore.appStore.token,
      })
      .build()
    this.attachListeners()
    this.connection.on('send', (e) => console.log(e))
    this.connection.onclose(() => setTimeout(() => this.listenToServer(), 5000))
    this.connection
      .start()
      // .then(() => this.connection.invoke('Echo', 'Hello: UPDATES'))
      .catch((e) => {
        console.error(e)
        // Sentry.captureException(e)
      })
  }
}
