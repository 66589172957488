import { observable, action } from 'mobx'
import { serialize, deserialize } from 'serializr'
import { IBoardInvitationDTO } from '../dto/IBoardInvitationDTO'
import { persist } from '../../shared/persist'

export default class BoardInvitation {
  public static create(boardGuid) {
    const inv = new BoardInvitation()
    inv.BoardGuid = boardGuid
    return inv
  }

  @persist @observable public BoardInvitationId: number = 0
  @persist @observable public BoardInvitationGuid: string = ''
  @persist @observable public FromName: string = ''
  @persist @observable public ToEmailAddress: string = ''
  @persist @observable public FromAppUserId: number = 0
  @persist @observable public BoardName: string = ''
  @persist @observable public BoardGuid: string = ''
  @persist @observable IsDeleted: boolean = false

  @action
  public setToEmailAddress(val) {
    this.ToEmailAddress = val
  }

  public clone(): BoardInvitation {
    return deserialize(BoardInvitation, serialize(this))
  }

  public toDTO(): IBoardInvitationDTO {
    return serialize(this)
  }
}
