import { RootStore } from '../../../stores/RootStore'
import { ListVM } from '@elexient/elexiapp.bits.shared'
import { Reminder } from '../../aggregate/Reminder'
import { ReminderRowVM } from './ReminderRowVM'
import { ReminderGroupRowVM } from './ReminderGroupRowVM'
import { action, computed, observable, reaction } from 'mobx'
import { RefresherEventDetail } from '@ionic/core'
import { ReminderTypeEnum } from '../../enum/ReminderTypeEnum'

export class RemindersListVM extends ListVM<RootStore, Reminder, ReminderGroupRowVM, ReminderRowVM, undefined> {
  constructor(rootStore: RootStore) {
    super(rootStore, ReminderRowVM, ReminderGroupRowVM, undefined)
    this.autoGrow = true
    this.startCollapsed = false
    this.stickyHeaders = true
    this.atTopCheckTimeout = 500
    this.setGroups('accountName', '')
    reaction(
      () => this.rootStore.appStore.listHeight,
      () => this.setHeight(this.rootStore.appStore.listHeight),
      { delay: 200 }
    )
    reaction(
      () => this.rootStore.remindersStore.recordJustUpdated,
      () => this.reloadRows()
    )
    reaction(
      () => this.rootStore.accountsStore.isHydrated,
      () => {
        this.reloadRows()
        this.shown = true
      }
    )
    this.setHeight(this.rootStore.appStore.listHeight)
  }

  @observable public shown: boolean = false

  protected getRecords() {
    return this.rootStore.remindersStore.currentBoardRecords
  }

  protected aggregateSortFunction = (a: Reminder, b: Reminder): number => {
    const aVal = a.day.toLowerCase()
    const bVal = b.day.toLowerCase()
    if (aVal < bVal) return -1
    return 0
  }

  @action
  public async refresh(e: CustomEvent<RefresherEventDetail>) {
    await this.rootStore.remindersStore.loadData()
    if (e) (e.target as any).complete()
  }

  @action
  public addReminder(newType: ReminderTypeEnum) {
    let url = '/reminderedit'
    this.rootStore.appStore.navigateTo(url + '/new/' + newType)
  }
}
