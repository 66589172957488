import { RootStore } from '../../../stores/RootStore'
import { IMatch } from '../../interfaces/IMatch'
import Sugar from 'sugar'
import { BankTransactionEditVM } from './BankTransactionEditVM'
import { action, makeObservable, observable } from 'mobx'
import { OptionType } from '../../types/OptionType'

export class OptionRowVM {
  private rootStore: RootStore
  private match: IMatch
  private editVM: BankTransactionEditVM

  constructor(rootStore: RootStore, editVM: BankTransactionEditVM, type: OptionType, match?: IMatch) {
    makeObservable(this)
    this.rootStore = rootStore
    this.editVM = editVM
    this.match = match
    this.type = type
  }

  @observable public checked: boolean = false
  public type: OptionType

  public get transactionGuid(): string {
    return this.match.Transaction.TransactionGuid
  }

  public get payeeName(): string {
    if (this.type !== 'trans') return ''
    return this.match.Transaction.PayeeName
  }

  public get amountFormatted(): string {
    if (this.type !== 'trans') return ''
    let str = Sugar.Number(Math.abs(this.match.Transaction.Amount)).format(2).raw
    return str
  }

  public get col1(): string {
    if (this.type === 'trans') return this.dateFormatted
    if (this.type === 'skip') return 'Skip'
    if (this.type === 'add-new') return 'Import This Transaction'
    return ''
  }

  public get dateFormatted(): string {
    if (this.type !== 'trans') return ''
    let str = Sugar.Date(this.match.Transaction.TransactionDate).short().raw
    return str
  }

  public get relative(): string {
    if (this.type !== 'trans') return ''
    let str = Sugar.Date(this.match.Transaction.TransactionDate).relative().raw
    return str
  }

  public get className(): string {
    if (this.type !== 'trans') return 'other'
    return 'trans'
  }

  public get key(): string {
    let key = this.type
    if (this.type === 'trans') key += this.transactionGuid
    return key
  }

  @action
  public click() {
    const wasChecked = Boolean(this.checked)
    this.editVM.clearAllChecked()
    if (!wasChecked) this.checked = true
  }

  @action
  public clearChecked() {
    this.checked = false
  }
}
