import { observable, computed } from 'mobx'
import { IBoardInvitationDTO } from '../dtos/IBoardInvitationDTO'
import { persist } from '../../shared/persist'

export class BoardInvitation implements IBoardInvitationDTO {
  @persist @observable BoardInvitationGuid: string = ''
  @persist @observable ToEmailAddress: string = ''
  @persist @observable StatusId: Number = 0
  @persist @observable FromAppUserId: Number = 0
}
