import agent from '../../AgentV2'
import { RootStore } from '../../stores/RootStore'
import { IBankTransactionDTO } from '../dtos/IBankTransactionDTO'
import { ActionTypeEnum } from '../../actions/enum/ActionTypeEnum'

export class BankTransactionsService {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  public save(dto: IBankTransactionDTO) {
    this.rootStore.bankTransactionsStore.updateFromServer(dto)
    this.rootStore.actionsStore.addAction(ActionTypeEnum.BANK_TRANSACTION_SAVE, dto)
  }

  public async saveOnServer(dto: IBankTransactionDTO) {
    const savedDTO = await agent.BankTransactions.save(dto)
    this.rootStore.bankTransactionsStore.updateFromServer(savedDTO)
  }

  public delete(dto: IBankTransactionDTO) {
    dto.IsDeleted = true
    this.save(dto)
  }
}
