import { RootStore } from '../../stores/RootStore'
import { ListVM } from '@elexient/elexiapp.bits.shared'
import { Category } from '../aggregate/Category'
import { CategoryRowVM } from './CategoryRowVM'
import { CategoryGroupRowVM } from './CategoryGroupRowVM'
import { action, makeObservable, reaction } from 'mobx'
import { CategoryGroupEnum } from '../enums/CategoryGroupEnum'

export class CategoriesListVM extends ListVM<RootStore, Category, CategoryGroupRowVM, CategoryRowVM, undefined> {
  constructor(rootStore: RootStore) {
    super(rootStore, CategoryRowVM, CategoryGroupRowVM, undefined)
    makeObservable(this)
    this.setGroups('groupName', '')
    this.autoGrow = true
    this.startCollapsed = false
    this.stickyHeaders = true
    reaction(
      () => this.rootStore.appStore.listHeight,
      () => this.setHeight(this.rootStore.appStore.listHeight),
      { delay: 200 }
    )
    reaction(
      () => this.rootStore.categoriesStore.recordJustUpdated,
      () => this.reloadRows()
    )
    this.setHeight(this.rootStore.appStore.listHeight)
  }

  protected getRecords() {
    return this.rootStore.categoriesStore.currentBoardRecords
  }

  protected sortFunction = (a: Category, b: Category): number => {
    const aVal = a.Name.toLowerCase()
    const bVal = b.Name.toLowerCase()
    if (aVal < bVal) return -1
    return 0
  }

  @action
  public addCategory(newType: CategoryGroupEnum) {
    let url = '/categoryedit'
    this.rootStore.appStore.navigateTo(url + '/new/' + newType)
  }
}
