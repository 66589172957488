import { observable, action, computed, makeObservable } from 'mobx'
import { ITransactionDTO } from '../dtos/ITransactionDTO'
import { serialize, deserialize, serializable, date, object, list } from 'serializr'
import { IAggregrate } from '../../shared/IAggregate'
import { TransactionStatusEnumName } from '../enum/TransactionStatusEnumName'
import { TransactionTypeEnumName } from '../enum/TransactionTypeEnumName'
import { TransactionTypeEnum } from '../enum/TransactionTypeEnum'
import { TransactionStatusEnum } from '../enum/TransactionStatusEnum'
import generateUUID from '../../utils/UUID'
import moment from 'moment'
import { CategorySplit } from './CategorySplit'
import { BankTransaction as RealBlankTransaction } from '../../bank-transactions/aggregate/BankTransaction'

export class Transaction implements ITransactionDTO, IAggregrate {
  public static create(boardId: number, accountGuid: string) {
    const tran = new Transaction()
    tran.TransactionGuid = generateUUID()
    tran.BoardId = boardId
    tran.AccountGuid = accountGuid
    tran.TransactionStatusId = TransactionStatusEnum.Pending
    tran.TransactionTypeId = TransactionTypeEnum.Expense
    tran.TransactionDate = moment().utc().toISOString()
    tran.addCategorySplit()
    return tran
  }

  public static createFromBankTransaction(bankTrans: RealBlankTransaction) {
    const tran = new Transaction()
    tran.TransactionGuid = bankTrans.BankTransactionGuid
    tran.BoardId = bankTrans.BoardId
    tran.AccountGuid = bankTrans.AccountGuid
    tran.Amount = bankTrans.Amount
    tran.TransactionStatusId = TransactionStatusEnum.Inbox
    tran.TransactionTypeId = bankTrans.isPositive ? TransactionTypeEnum.Income : TransactionTypeEnum.Expense
    tran.TransactionDate = bankTrans.TransactionDate
    tran.PayeeName = bankTrans.PayeeName
    tran.addCategorySplit()
    return tran
  }

  constructor() {
    makeObservable(this)
  }

  @serializable @observable public TransactionGuid: string = ''
  @serializable @observable public AccountGuid: string = ''
  @serializable @observable public BoardId: number = 0
  @serializable @observable public Color: string = ''
  @serializable @observable public IsDeleted: boolean = false
  @serializable @observable public ScheduleItemId: number = 0
  @serializable @observable public ReminderGuid: string = undefined
  @serializable @observable public TransactionStatusId: TransactionStatusEnum = undefined
  @serializable @observable public EnvelopeId: number = 0
  @serializable @observable public CategoryGuid: string = undefined
  @serializable @observable public TransactionTypeId: number = 0
  @serializable @observable public TransactionDate: string = undefined
  @serializable @observable public PayeeName: string = ''
  @serializable @observable public Amount: number = 0
  @serializable @observable public Balance: number = 0
  @serializable @observable public Notes: string = ''
  @serializable @observable public LastUpdatedDateTime: string = undefined
  @serializable @observable public LastUpdatedByUserId: number = 0
  @serializable(list(object(CategorySplit))) @observable public CategorySplits: CategorySplit[] = []
  @serializable @observable public TransferAccountGuid: string = undefined
  @serializable @observable public TransferTransactionGuid: string = undefined
  @serializable @observable public BankTransactionGuid: string = undefined
  @serializable @observable public TransactionNumber: number = undefined

  @action
  public setPayeeName(val: string) {
    this.PayeeName = val
  }

  @action
  public setNotes(val: string) {
    this.Notes = val
  }

  @action
  public setBankTransaction(val: string) {
    this.BankTransactionGuid = val
  }

  @action
  public setColor(val: string) {
    this.Color = val
  }

  @action
  public setCategory(val: string) {
    this.CategoryGuid = val
  }

  @action
  public setAccount(val: string) {
    this.AccountGuid = val
  }

  @action
  public clearTransferAccount() {
    this.TransferAccountGuid = undefined
  }

  @action
  public setTransferAccount(val: string) {
    this.TransferAccountGuid = val
  }

  @action
  public setAmount(val: number) {
    this.Amount = Number(val)
  }

  public clone(): Transaction {
    return deserialize(Transaction, serialize(this))
  }

  public toDTO(): ITransactionDTO {
    return serialize(this)
  }

  @computed
  public get TypeName(): string {
    return TransactionTypeEnumName.fromId(this.TransactionTypeId)
  }

  public get StatusName(): string {
    return TransactionStatusEnumName.fromId(this.TransactionStatusId)
  }

  @action
  public setTransactionDate(val: moment.Moment) {
    // if (!val.isUTC) val.utc()
    this.TransactionDate = val.toISOString()
  }

  @action
  public setTransactionStatus(val: number) {
    this.TransactionStatusId = val
  }

  @action
  public markAsSkipped() {
    this.TransactionStatusId = TransactionStatusEnum.Skipped
  }

  @action
  public markAsCleared() {
    this.TransactionStatusId = TransactionStatusEnum.Cleared
  }

  @action
  public markAsPending() {
    this.TransactionStatusId = TransactionStatusEnum.Pending
  }

  @computed
  public get skippable(): boolean {
    return (this.isForReminder && this.isUpcoming) || this.isInInbox
  }

  @computed
  public get payable(): boolean {
    return this.isUpcoming
  }

  @computed
  public get clearable(): boolean {
    return this.isPending
  }

  @computed
  public get importable(): boolean {
    return this.isInInbox
  }

  @computed
  public get isUpcoming(): boolean {
    return this.TransactionStatusId === TransactionStatusEnum.Upcoming
  }

  @computed
  public get isPending(): boolean {
    return this.TransactionStatusId === TransactionStatusEnum.Pending
  }

  @computed
  public get isInInbox(): boolean {
    return this.TransactionStatusId === TransactionStatusEnum.Inbox
  }

  @computed
  public get isForReminder(): boolean {
    return Boolean(this.ReminderGuid)
  }

  @action
  public setTransactionType(val: number) {
    this.TransactionTypeId = val
  }

  @computed
  public get isPositive() {
    return Boolean(this.Amount >= 0)
  }

  @action
  public addCategorySplit() {
    this.CategorySplits.push(CategorySplit.create())
  }

  @computed
  public get transactionDateAsDate(): Date {
    return new Date(this.TransactionDate)
  }

  @computed
  public get hasBankTransaction(): boolean {
    return Boolean(this.BankTransactionGuid)
  }
}
