import { observable, action, computed, makeObservable, reaction } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { Reminder } from '../aggregate/Reminder'
import { ReminderEditVM } from '../view-models/edit/ReminderEditVM'
import { RemindersListVM } from '../view-models/list/RemindersListVM'
import { IReminderDTO } from '../dtos/IReminderDTO'
import { DataStore } from '@elexient/elexiapp.bits.shared'
import { makePersistable } from 'mobx-persist-store'
import AgentV2 from '../../AgentV2'
import { deserialize } from 'serializr'
import { ReminderTypeEnum } from '../enum/ReminderTypeEnum'
import { ToDoWidgetVM } from '../../dashboard/view-models/todo-widget/ToDoWidgetVM'

export class RemindersStore extends DataStore<RootStore, Reminder, IReminderDTO> {
  protected worker: any

  constructor(rootStore: RootStore) {
    super(rootStore, Reminder, AgentV2, 'Reminders', 'Reminder')
    super.setRecords(this.persistedRecords)
    makeObservable(this)
    makePersistable(this, { name: 'RemindersStore', properties: ['persistedRecords'] }).then(
      action((st) => this.onHydrationCompleted(st?.isHydrated))
    )
  }

  @action
  public onHydrationCompleted(isHydrated?: boolean) {
    this.persistedRecords = observable.array(this.persistedRecords.map((e) => makeObservable(deserialize(Reminder, e))))
    super.setRecords(this.persistedRecords)
    this.isHydrated = Boolean(isHydrated)
    if (process.env.NODE_ENV === 'test') this.isHydrated = true
    this.listVM = new RemindersListVM(this.rootStore as RootStore)
    this.todoWidgetVM = new ToDoWidgetVM(this.rootStore as RootStore)
  }

  @observable.shallow public persistedRecords: Array<Reminder> = []
  @observable public editVM: ReminderEditVM = null
  @observable public listVM: RemindersListVM = null
  @observable public todoWidgetVM: ToDoWidgetVM = null
  @observable public isHydrated: boolean = false

  public onLoadDataCompleted(): void {
    if (!this.listVM) return
    this.listVM.reloadRows()
  }

  @action
  public lazyLoadEditVM(reminderGuid: string, newType: ReminderTypeEnum = undefined, attempts: number = 0) {
    if (attempts === 10) return
    if (!this.rootStore.boardsStore.isHydrated) {
      setTimeout(() => this.lazyLoadEditVM(reminderGuid, newType, attempts++), 500)
      return
    }
    if (this.editVM) this.editVM.dispose()
    if (reminderGuid === 'new') {
      this.editVM = new ReminderEditVM(
        this.rootStore,
        Reminder.create(this.rootStore.boardsStore.currentBoardId),
        newType
      )
    } else if (reminderGuid === 'empty') {
      this.editVM = null
    } else {
      const reminder = this.get(reminderGuid)
      if (!reminder) {
        setTimeout(() => this.lazyLoadEditVM(reminderGuid, newType, attempts++), 500)
        return
      }
      this.editVM = new ReminderEditVM(this.rootStore, reminder.clone())
    }
  }

  @computed
  public get currentBoardRecords() {
    if (!this.isHydrated) return []
    return this.persistedRecords.filter((e) => e.BoardId === this.rootStore.boardsStore.currentBoardId)
  }

  public onAfterUpdate(rem: Reminder): void {
    this.refreshEditVMFromReminder(rem)
  }

  private refreshEditVMFromReminder(rem: Reminder) {
    if (!this.editVM) return
    if (this.editVM.reminderGuid !== rem.ReminderGuid) return
    this.editVM.loadReminder(rem)
  }
}
