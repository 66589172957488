import React from 'react'
import { inject, observer } from 'mobx-react'
import { AppStore } from '../../../stores/AppStore'
import {
  IonItem,
  IonLabel,
  IonInput,
  IonSelect,
  IonSelectOption,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonIcon,
} from '@ionic/react'
import { TransactionsStore } from '../../store/TransactionsStore'
import { DatePicker } from '@material-ui/pickers'
import { ExpenseEditVM } from '../../view-models/ExpenseEditVM'
import { gitBranchSharp as splitIcon } from 'ionicons/icons'
import { trashSharp as trashIcon } from 'ionicons/icons'
import { CategorySplitRowVM } from '../../view-models/CategorySplitRowVM'

interface Props {
  transactionsStore?: TransactionsStore
  appStore?: AppStore
}

const ExpenseEditForm: React.FC<Props> = ({ transactionsStore, appStore }) => {
  const editVM = transactionsStore.editVM as ExpenseEditVM
  if (!editVM) return null
  if (appStore.bodyHeight === 0) return null

  const renderPayeeName = () => {
    return (
      <IonItem lines={'inset'}>
        <IonLabel position='floating'>Payee</IonLabel>
        <IonInput type='text' value={editVM.payeeName} onIonChange={(e: any) => editVM.setPayeeName(e.target.value)} />
      </IonItem>
    )
  }

  const renderNotes = () => {
    return (
      <IonItem lines={'inset'}>
        <IonLabel position='floating'>Notes</IonLabel>
        <IonInput type='text' value={editVM.notes} onIonChange={(e: any) => editVM.setNotes(e.target.value)} />
      </IonItem>
    )
  }

  const renderEnvelope = () => {
    return (
      <IonItem lines={'inset'}>
        <IonLabel position='floating'>Envelope</IonLabel>
        <IonSelect
          interface='popover'
          value={editVM.categoryGuid}
          onIonChange={(e: any) => editVM.setCategory(e.target.value)}
        >
          {editVM.categories.map((e) => (
            <IonSelectOption key={e.CategoryGuid} value={e.CategoryGuid}>
              {e.Name}
            </IonSelectOption>
          ))}
        </IonSelect>
      </IonItem>
    )
  }

  const renderStatus = () => {
    return (
      <IonItem lines={'inset'}>
        <IonLabel position='floating'>Status</IonLabel>
        <IonSelect
          interface='popover'
          value={editVM.transactionStatusId}
          onIonChange={(e: any) => editVM.setStatus(e.target.value)}
        >
          {editVM.statuses.map((e) => (
            <IonSelectOption key={e.id} value={e.id}>
              {e.name}
            </IonSelectOption>
          ))}
        </IonSelect>
      </IonItem>
    )
  }

  const renderSingleCategorySplitRow = () => {
    if (editVM.categorySplits.length > 1) return
    return editVM.categorySplits.map((row) => (
      <IonRow key={'sc' + row.categoryGuid}>
        <IonCol sizeXs='10'>{renderCategory(row)}</IonCol>
        <IonCol sizeXs='2'>{renderAddCategorySplitButton(row)}</IonCol>
      </IonRow>
    ))
  }

  const renderMultipleCategorySplitRows = () => {
    if (editVM.categorySplits.length === 1) return
    return editVM.categorySplits.map((row, idx) => (
      <IonRow key={'mc' + idx}>
        <IonCol sizeXs='7'>{renderCategory(row)}</IonCol>
        <IonCol sizeXs='3'>{renderCategorySplitAmount(row)}</IonCol>
        <IonCol sizeXs='2'>{renderDeleteCategorySplitButton(row)}</IonCol>
      </IonRow>
    ))
  }

  const renderDeleteCategorySplitButton = (row) => {
    if (editVM.categorySplits.length === 1) return
    return (
      <IonButton size='small' color='light' className='split-btn' onClick={() => row.delete()}>
        <IonIcon icon={trashIcon} />
      </IonButton>
    )
  }

  const renderAddCategorySplitButton = (row) => {
    if (editVM.categorySplits.length > 1) return
    return (
      <IonButton size='small' color='light' className='split-btn' onClick={() => editVM.addCategorySplit()}>
        <IonIcon icon={splitIcon} />
      </IonButton>
    )
  }

  const renderAddCategorySplitRow = () => {
    if (editVM.categorySplits.length === 1) return
    return (
      <IonRow>
        <IonCol sizeXs='12'>
          <IonButton size='small' color='light' className='split-btn' onClick={() => editVM.addCategorySplit()}>
            <IonIcon icon={splitIcon} />
          </IonButton>
        </IonCol>
      </IonRow>
    )
  }

  const renderCategorySplitAmount = (row: CategorySplitRowVM) => {
    return (
      <IonItem lines={'inset'}>
        <IonLabel position='floating'>Amt</IonLabel>
        <IonInput
          type='number'
          value={row.amountAsString}
          color={editVM.categorySplitsTooHigh ? 'warning' : undefined}
          onIonChange={(e: any) => row.setAmount(e.target.value)}
        />
      </IonItem>
    )
  }

  const renderCategory = (row: CategorySplitRowVM) => {
    return (
      <IonItem lines={'inset'}>
        <IonLabel position='floating'>Category</IonLabel>
        <IonSelect
          interface='popover'
          value={row.categoryGuid}
          onIonChange={(e: any) => row.setCategory(e.target.value)}
        >
          {editVM.categories.map((e) => (
            <IonSelectOption key={e.CategoryGuid} value={e.CategoryGuid}>
              {e.Name}
            </IonSelectOption>
          ))}
        </IonSelect>
      </IonItem>
    )
  }

  const renderAmount = () => {
    return (
      <IonItem lines={'inset'}>
        <IonLabel position='floating'>Amount</IonLabel>
        <IonInput
          type='number'
          value={editVM.amountAsString}
          onKeyDown={() => editVM.setAmountTouched()}
          onIonChange={(e: any) => editVM.setAmount(e.target.value)}
        />
      </IonItem>
    )
  }

  const renderDate = () => {
    return (
      <IonItem lines={'inset'} unselectable='on'>
        <IonLabel position='floating' className='forced-floating'>
          Date
        </IonLabel>
        <DatePicker
          unselectable='on'
          value={editVM.transactionDate}
          onChange={(e) => editVM.setTransactionDate(e)}
          format='M/D/YYYY'
        />
      </IonItem>
    )
  }

  return (
    <form
      id='DetailsForm'
      onSubmit={(e) => {
        e.preventDefault()
        editVM.save()
      }}
    >
      <IonGrid>
        <IonRow>
          <IonCol sizeXs='12'>{renderPayeeName()}</IonCol>
        </IonRow>
        <IonRow>
          <IonCol sizeMd='6' sizeXs='6'>
            {renderDate()}
          </IonCol>
          <IonCol sizeMd='6' sizeXs='6'>
            {renderAmount()}
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol sizeXs='12'>{renderStatus()}</IonCol>
        </IonRow>
        {renderSingleCategorySplitRow()}
        {renderMultipleCategorySplitRows()}
        {renderAddCategorySplitRow()}
        <IonRow>
          <IonCol sizeXs='10'>{renderEnvelope()}</IonCol>
          <IonCol sizeXs='2'>
            <IonButton size='small' color='light' className='split-btn' onClick={() => editVM.enterSplitMode()}>
              <IonIcon icon={splitIcon} />
            </IonButton>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol sizeXs='12'>{renderNotes()}</IonCol>
        </IonRow>
      </IonGrid>
    </form>
  )
}

export default inject('transactionsStore', 'appStore')(observer(ExpenseEditForm))
