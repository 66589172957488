import { RootStore } from '../../stores/RootStore'
import { computed, observable, action, makeObservable } from 'mobx'
import { BoardInvitationRowVM } from './BoardInvitationRowVM'
import { Board } from '../aggregate/Board'

export class BoardsMenuVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    makeObservable(this)
    this.rootStore = rootStore
  }

  @observable public isMenuOpen: boolean = false
  public event: any

  @computed
  public get boards(): Board[] {
    return this.rootStore.boardsStore.persistedRecords
      .slice()
      .sort((a, b) => (a.Name.toLowerCase() < b.Name.toLowerCase() ? -1 : 0))
  }

  @computed
  public get pendingBoardInvitations(): number {
    return this.rootStore.boardInvitationsStore.persistedRecords.length
  }

  @computed
  public get isIos(): boolean {
    return this.rootStore.appStore.isIos
  }

  @computed
  public get currentBoardName(): string {
    if (!this.rootStore.boardsStore.currentBoard) return ''
    return this.rootStore.boardsStore.currentBoard.Name
  }

  @action
  public open(ev) {
    this.isMenuOpen = true
    this.event = ev
  }

  @action
  public close() {
    this.isMenuOpen = false
  }

  @action
  public startNewBoard() {
    this.close()
    this.rootStore.boardsStore.newVM.open()
  }

  @action
  public openJoinBoardModal() {
    this.close()
    this.rootStore.boardsStore.joinVM.toggle()
  }

  @computed
  public get boardInvitations() {
    return this.rootStore.boardInvitationsStore.persistedRecords.map((e) => new BoardInvitationRowVM(this.rootStore, e))
  }
}
