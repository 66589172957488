import { observable, action, computed, makeObservable } from 'mobx'
import { IAccountDTO } from '../dtos/IAccountDTO'
import { serialize, deserialize, serializable } from 'serializr'
import { IAggregrate } from '../../shared/IAggregate'
import generateUUID from '../../utils/UUID'

export class Account implements IAccountDTO, IAggregrate {
  public static create(boardId, accountGuid: string = null, name: string = null) {
    const cat = new Account()
    cat.AccountGuid = accountGuid
    if (!accountGuid) cat.AccountGuid = generateUUID()
    if (name) cat.Name = name
    cat.BoardId = boardId
    return cat
  }

  constructor() {
    makeObservable(this)
  }

  @serializable @observable public AccountGuid: string = ''
  @serializable @observable public Name: string = ''
  @serializable @observable public BoardId: number = 0
  @serializable @observable public TypeId: number = 0
  @serializable @observable public DateRangeId: number = 0
  @serializable @observable public Color: string = ''
  @serializable @observable public IsDeleted: boolean = false
  @serializable @observable public Balance: number = 0
  @serializable @observable public ClearedBalance: number = 0
  @serializable @observable public PendingBalance: number = 0
  @serializable @observable public Notes: string = ''
  @serializable @observable public PlaidItemGuid: string = undefined
  @serializable @observable public PlaidItemAccountGuid: string = undefined
  @serializable @observable public ClearedTransactionsCount: string = undefined

  @action
  public setName(val: string) {
    this.Name = val
  }

  @action
  public setNotes(val: string) {
    this.Notes = val
  }

  @action
  public setColor(val: string) {
    this.Color = val
  }

  @action
  public setClearedBalance(val: number) {
    this.ClearedBalance = Number(val)
    this.Balance = this.ClearedBalance
  }

  @action
  public setType(val: number) {
    this.TypeId = val
  }

  @action
  public setDateRange(val: number) {
    this.DateRangeId = val
  }

  public clone(): Account {
    return deserialize(Account, serialize(this))
  }

  public toDTO(): IAccountDTO {
    return serialize(this)
  }

  @action
  public setPlaidItem(val: string) {
    this.PlaidItemGuid = val
  }

  @action
  public setPlaidItemAccount(val: string) {
    this.PlaidItemAccountGuid = val
  }

  @action
  public clearPlaidItemAccount() {
    this.PlaidItemGuid = undefined
    this.PlaidItemAccountGuid = undefined
  }

  @computed
  public get TypeName(): string {
    if (this.TypeId === 1) return 'Cash'
    if (this.TypeId === 2) return 'Checking'
    if (this.TypeId === 3) return 'Savings'
    if (this.TypeId === 4) return 'Credit Card'
    if (this.TypeId === 5) return 'Loan'
    return '(Not Set)'
  }
}
