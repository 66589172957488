import React from 'react'
import { inject, observer, useLocalObservable } from 'mobx-react'
import { RemindersStore } from '../../../reminders/store/RemindersStore'
import { AppStore } from '../../../stores/AppStore'
import {
  IonList,
  IonItem,
  IonButton,
  IonIcon,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonButtons,
  IonToolbar,
} from '@ionic/react'
import { cogSharp } from 'ionicons/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-regular-svg-icons'
import { RootStore } from '../../../stores/RootStore'
import { ToDoWidgetVM } from '../../view-models/todo-widget/ToDoWidgetVM'

interface Props {
  rootStore?: RootStore
}

const ToDoWidget: React.FC<Props> = ({ rootStore }) => {
  const vm = useLocalObservable(() => new ToDoWidgetVM(rootStore))
  if (vm.bodyHeight === 0) return null

  const renderToDos = () => {
    return vm.rows.map((e) => (
      <IonItem lines='inset' onClick={() => e.click()} key={e.key}>
        <FontAwesomeIcon icon={faCircle} style={{ fontSize: '1.0rem' }} />
        &nbsp; {e.name}
      </IonItem>
    ))
  }

  return (
    <IonCard color='light' id='ToDoWidget' className='widget'>
      <IonCardHeader color='light'>
        <IonToolbar color='light'>
          <IonCardTitle>To Do</IonCardTitle>
          <IonButtons slot='end'>
            <IonButton>
              <IonIcon icon={cogSharp} color='medium' />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonCardHeader>
      <IonCardContent>
        <IonList>{renderToDos()}</IonList>
      </IonCardContent>
    </IonCard>
  )
}

export default inject('rootStore')(observer(ToDoWidget))
