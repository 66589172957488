import { action, observable, computed, makeObservable } from 'mobx'
import { ColorVariable, COLOR_VARS } from './color-variables'
import { generateColor, updateCssText } from './parse-css'
import moment from 'moment'
import { SYSTEM_THEMES } from './aggregate/SystemThemes'
import { RootStore } from '../stores/RootStore'
import { Capacitor } from '@capacitor/core'
import { makePersistable } from 'mobx-persist-store'
import { Color } from './color'
import { LightenDarkenColor } from '../shared/ligten-color'

export class ThemeGenVM {
  private to: NodeJS.Timer
  private rootStore: RootStore
  private justCalled: boolean
  private forceJustCalled: boolean
  private listenerRegistered: boolean

  constructor() {
    makeObservable(this)
    makePersistable(this, {
      name: 'ThemeGenVM',
      properties: [
        'primaryColor',
        'secondaryColor',
        'tertiaryColor',
        'darkColor',
        'mediumColor',
        'lightColor',
        'boardColor',
        'currentTheme',
      ],
    }).then((st) => {
      let isHydrated = false
      if (process.env.NODE_ENV === 'test') isHydrated = true
      if (st?.isHydrated) isHydrated = true
      if (isHydrated) this.onHydrationCompleted()
    })
    this.cssText = this.DEFAULT_CSS_TEXT
    this.colors = COLOR_VARS
  }

  @observable public colors: ColorVariable[] = []
  @observable public cssText: string = null
  @observable public primaryColor: string = '#0ba8b9'
  @observable public secondaryColor: string = '#0391b4'
  @observable public tertiaryColor: string = '#d94672'
  @observable public darkColor: string = '#222428'
  @observable public mediumColor: string = '#ace0ea'
  @observable public lightColor: string = '#f4f5f8'
  @observable public boardColor: string = '#333333'
  @observable public currentTheme: string = 'default'
  @observable public isInFocus: any
  @observable public focusedColor: string = ''
  @observable public pickerShown: boolean = false
  public lastToken: string = ''

  @computed
  public get lighterColor(): string {
    const value = this.rootStore.appStore.themeGenVM.lightColor
    const color = new Color(value)
    const contrast = color.contrast()
    let val = 230
    if (contrast.hex.includes('fff')) val = -200
    const final = LightenDarkenColor(contrast.hex, val)
    return final
  }

  public setRootStore(root) {
    this.rootStore = root
  }

  public listenToSystemThemeChange(force: boolean = false) {
    if (process.env.NODE_ENV === 'test') return
    if (this.listenerRegistered && !force) return
    const x = window.matchMedia('(prefers-color-scheme: dark)')
    if (this.currentTheme === 'system') this.handleSystemThemeChange(x)
    if (!x) return
    x.addEventListener('change', (x) => {
      if (this.currentTheme !== 'system') return
      this.handleSystemThemeChange(x)
      if (x.matches) {
        this.loadTheme('dark', false)
      } else {
        this.loadTheme('default', false)
      }
    })
    this.listenerRegistered = true
  }

  private handleSystemThemeChange(x: MediaQueryList | MediaQueryListEvent) {
    if (Capacitor.getPlatform() === 'android') {
      this.handleAndroidForcedSystemThemeChange()
      return
    }
    if (this.currentTheme !== 'system') return
    if (this.justCalled) return
    this.justCalled = true
    setTimeout(() => (this.justCalled = false), 1000)
    if (x.matches) {
      this.loadTheme('dark', false)
      return
    }
    this.loadTheme('default', false)
  }

  public handleAndroidForcedSystemThemeChange() {
    if (Capacitor.getPlatform() !== 'android') return
    if (this.currentTheme !== 'system') return
    if (document.body.classList.contains('forceddark')) {
      this.loadTheme('dark', false)
      return
    }
    this.loadTheme('default', false)
  }

  @action
  public loadTheme(val, remember: boolean = true) {
    if (process.env.NODE_ENV === 'test') return
    if (remember) this.currentTheme = val
    if (val === 'custom') return
    if (val === 'system') {
      this.listenToSystemThemeChange(true)
      this.handleAndroidForcedSystemThemeChange()
      return
    }
    const foundTheme = SYSTEM_THEMES.find((e) => e.name === val)
    if (!foundTheme) return
    this.primaryColor = foundTheme.primaryColor
    this.secondaryColor = foundTheme.secondaryColor
    this.tertiaryColor = foundTheme.tertiaryColor
    this.darkColor = foundTheme.darkColor
    this.mediumColor = foundTheme.mediumColor
    this.lightColor = foundTheme.lightColor
    this.boardColor = foundTheme.boardColor
    this.apply()
    console.log('theme applied')
  }

  @action
  public setColor(name, val) {
    this[name + 'Color'] = val
  }

  @computed
  public get isCustom(): boolean {
    return this.currentTheme === 'custom'
  }

  public getColor(name) {
    return this[name + 'Color']
  }

  @action
  public apply(db: boolean = false) {
    if (!db) {
      this.doApply()
      this.pickerShown = false
      return
    }
    if (this.to) clearTimeout(this.to)
    this.to = setTimeout(() => this.doApply(), 100)
    this.rootStore.appStore.checkNativeStatusBarColor()
    setTimeout(() => this.rootStore.appStore.checkNativeStatusBarColor(), 1000)
    setTimeout(() => this.rootStore.appStore.checkNativeStatusBarColor(), 2000)
  }

  private doApply() {
    this.updateCss('primary', this.primaryColor)
    this.updateCss('secondary', this.secondaryColor)
    this.updateCss('tertiary', this.tertiaryColor)
    this.updateCss('dark', this.darkColor)
    this.updateCss('medium', this.mediumColor)
    this.updateCss('light', this.lightColor)
    this.updateCss('board', this.boardColor)
    this.clearLastStyle()
    const token = moment().format('phmmssSSSp')
    const style = document.createElement('style')
    style.id = token
    style.innerHTML = this.cssText.replace(':root', '.' + token)
    document.head.appendChild(style)
    document.body.classList.forEach((e) => {
      if (e === 'forceddark') return
      document.body.classList.remove(e)
    })
    document.body.classList.add(token)
    document.body.style.backgroundColor = ''
    const metaThemeTag = document.querySelector('meta[name=theme-color]')
    metaThemeTag.setAttribute('content', this.primaryColor)
    if (this.lastToken !== '') {
      this.clearLastStyle()
      this.lastToken = token
    }
  }

  private clearLastStyle() {
    if (this.lastToken === '') return
    const el = document.querySelector('style[id=' + this.lastToken + ']')
    if (el) el.remove()
  }

  @action
  private updateCss(name, val) {
    const colorProperty: string = '--ion-color-' + name
    const colorValue: string = val
    const colorIndex = this.colors.findIndex((o) => o.property === colorProperty)
    const color = this.colors[colorIndex]
    const genColor = generateColor(color.name, colorProperty, colorValue)

    this.colors[colorIndex] = genColor
    this.colors = [...this.colors]

    const attrMap = {
      value: '',
      valueRgb: '-rgb',
      contrast: '-contrast',
      contrastRgb: '-contrast-rgb',
      shade: '-shade',
      tint: '-tint',
    }
    Object.keys(attrMap).forEach((key) => {
      this.cssText = updateCssText(colorProperty + attrMap[key], this.cssText, genColor[key])
    })
  }

  @action
  public onHydrationCompleted() {
    this.listenToSystemThemeChange()
    if (this.currentTheme === 'system') return
    if (this.currentTheme === 'custom') {
      this.apply()
      return
    }
    this.loadTheme(this.currentTheme)
  }

  @action
  public hideColorPicker() {
    this.pickerShown = false
  }

  public setFocused(color, val: boolean) {
    if (val) {
      this.pickerShown = true
      this.focusedColor = color
    } else {
      this.pickerShown = false
    }
  }

  public inFocus(color) {
    return this.isInFocus[color]
  }

  DEFAULT_CSS_TEXT = `
:root {
  --ion-color-primary: #3880ff;
  --ion-color-primary-rgb: 56,128,255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  --ion-color-secondary: #0cd1e8;
  --ion-color-secondary-rgb: 12,209,232;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255,255,255;
  --ion-color-secondary-shade: #0bb8cc;
  --ion-color-secondary-tint: #24d6ea;

  --ion-color-tertiary: #7044ff;
  --ion-color-tertiary-rgb: 112,68,255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #633ce0;
  --ion-color-tertiary-tint: #7e57ff;

	--ion-color-success: #308853;
	--ion-color-success-rgb: 48,136,83;
	--ion-color-success-contrast: #ffffff;
	--ion-color-success-contrast-rgb: 255,255,255;
	--ion-color-success-shade: #2a7849;
	--ion-color-success-tint: #459464;

  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255,206,0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255,255,255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245,61,61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;

  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34,34,34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255,255,255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152,154,162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255,255,255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244,244,244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-text-color: var(--ion-color-light-contrast);
  --ion-background-color: var(--ion-color-light);

  --ion-color-board: #f4f5f8;
  --ion-color-board-rgb: 244,244,244;
  --ion-color-board-contrast: #000000;
  --ion-color-board-contrast-rgb: 0,0,0;
  --ion-color-board-shade: #d7d8da;
  --ion-color-board-tint: #f5f6f9;

}

.ion-color-board {
  --ion-color-base: var(--ion-color-board);
  --ion-color-base-rgb: var(--ion-color-board-rgb);
  --ion-color-contrast: var(--ion-color-board-contrast);
  --ion-color-contrast-rgb: var(--ion-color-board-contrast-rgb);
  --ion-color-shade: var(--ion-color-board-shade);
  --ion-color-tint: var(--ion-color-board-tint);
}

`.trim()
}
