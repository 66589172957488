import { GroupRowVM } from '@elexient/elexiapp.bits.shared'
import { computed } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { CategoriesListVM } from './CategoriesListVM'

export class CategoryGroupRowVM extends GroupRowVM<RootStore, CategoriesListVM> {
  @computed
  public get displayName(): string {
    // if (!this.isInvView) return undefined
    return this.name
  }
  public get color(): string {
    return '#c00000'
  }
}
