import React, { useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import { AppStore } from '../../../stores/AppStore'
import { IonButton, IonItem, IonLabel, IonSelect, IonSelectOption } from '@ionic/react'
import { AccountsStore } from '../../store/AccountsStore'
import { usePlaidLink, PlaidLinkOnSuccess } from 'react-plaid-link'

interface Props {
  accountsStore?: AccountsStore
  appStore?: AppStore
}

const ConnectToBank: React.FC<Props> = ({ accountsStore, appStore }) => {
  const { editVM } = accountsStore
  if (!editVM) return null

  const { open, ready } = usePlaidLink({
    token: editVM.plaidLinkToken,
    onSuccess: useCallback<PlaidLinkOnSuccess>((publicToken, metadata) => {
      console.log(publicToken, metadata)
      editVM.onLinkSuccess(publicToken)
    }, []),
    // onEvent
    // onExit
  })

  const renderLinkButton = () => {
    if (editVM.hasLinkedPlaidAccount) return
    if (editVM.selectedPlaidItem) return
    return (
      <IonButton color='tertiary' onClick={() => open()} disabled={!ready} type='button'>
        Connect Another Bank
      </IonButton>
    )
  }

  const renderUpdateButton = () => {
    if (!editVM.plaidItemNeedsUpdating) return
    return (
      <IonButton color='tertiary' onClick={() => open()} disabled={!ready} type='button'>
        Update Connection
      </IonButton>
    )
  }

  const renderClearButton = () => {
    if (!editVM.hasLinkedPlaidAccount) return
    return (
      <IonButton color='tertiary' onClick={() => editVM.clearPlaidItemAccount()} type='button'>
        Disconnect
      </IonButton>
    )
  }

  const renderAccounts = () => {
    if (!editVM.selectedPlaidItem) return
    return (
      <IonItem lines={'inset'}>
        <IonLabel position='floating'>Connected Account</IonLabel>
        <IonSelect
          interface='popover'
          value={editVM.plaidItemAccountGuid}
          onIonChange={(e: any) => editVM.setPlaidItemAccount(e.target.value)}
        >
          {editVM.bankAccounts.map((e) => (
            <IonSelectOption key={e.AccountGuid} value={e.AccountGuid}>
              {e.Mask} - {e.Name}
            </IonSelectOption>
          ))}
        </IonSelect>
      </IonItem>
    )
  }

  const renderInstitutions = () => {
    return (
      <IonItem lines={'inset'}>
        <IonLabel position='floating'>Connected Bank</IonLabel>
        <IonSelect
          interface='popover'
          value={editVM.plaidItemGuid}
          onIonChange={(e: any) => editVM.setPlaidItem(e.target.value)}
        >
          {editVM.plaidItems.map((e) => (
            <IonSelectOption key={e.PlaidItemGuid} value={e.PlaidItemGuid}>
              {e.InstitutionName}
            </IonSelectOption>
          ))}
        </IonSelect>
      </IonItem>
    )
  }

  return (
    <>
      <br />
      <br />
      {renderInstitutions()}
      {renderAccounts()}
      <br />
      {renderClearButton()}
      {renderLinkButton()}
      {renderUpdateButton()}
    </>
  )
}

export default inject('accountsStore', 'appStore')(observer(ConnectToBank))
