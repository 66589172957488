import { observable, action, computed, makeObservable } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { Transaction } from '../aggregate/Transaction'
import { TransactionEditVM } from '../view-models/TransactionEditVM'
import { TransactionsListVM } from '../view-models/TransactionsListVM'
import { ITransactionDTO } from '../dtos/ITransactionDTO'
import { DataStore } from '@elexient/elexiapp.bits.shared'
import AgentV2 from '../../AgentV2'
import { makePersistable } from 'mobx-persist-store'
import { deserialize } from 'serializr'
import { ExpenseEditVM } from '../view-models/ExpenseEditVM'
import { TransactionsRouteService } from '../services/TransactionsRouteService'
import { TransferEditVM } from '../view-models/TransferEditVM'
import { CCPaymentEditVM } from '../view-models/CCPaymentEditVM'
import { BalanceAdjustmentEditVM } from '../view-models/BalanceAdjustmentEditVM'
import { TransactionsService } from '../services/TransactionsService'

export class TransactionsStore extends DataStore<RootStore, Transaction, ITransactionDTO> {
  protected worker: any

  constructor(rootStore: RootStore) {
    super(rootStore, Transaction, AgentV2, 'Transactions', 'Transaction')
    makeObservable(this)
    makePersistable(this, { name: 'TransactionsStore', properties: ['persistedRecords'] }).then(
      action((st) => this.onHydrationCompleted(st?.isHydrated))
    )
  }

  @action
  public onHydrationCompleted(isHydrated?: boolean) {
    this.persistedRecords = observable.array(
      this.persistedRecords.map((e) => makeObservable(deserialize(Transaction, e)))
    )
    this.setRecords(this.persistedRecords)
    this.isHydrated = Boolean(isHydrated)
    if (process.env.NODE_ENV === 'test') this.isHydrated = true
  }

  @observable.shallow public persistedRecords: Array<Transaction> = []
  @observable public editVM:
    | TransactionEditVM
    | ExpenseEditVM
    | TransferEditVM
    | CCPaymentEditVM
    | BalanceAdjustmentEditVM = null
  @observable public listVM: TransactionsListVM = null
  @observable public isHydrated: boolean = false

  @action
  public lazyLoadEditVM(
    accountGuid: string,
    transactionGuid: string,
    newType: number = undefined,
    bankTransactionGuid: string = undefined,
    attempts: number = 0
  ) {
    if (attempts === 10) return
    if (transactionGuid === 'empty') {
      this.editVM = null
      return
    }
    if (!this.rootStore.boardsStore.isHydrated) {
      setTimeout(() => this.lazyLoadEditVM(accountGuid, transactionGuid, newType, bankTransactionGuid, attempts++), 500)
      return
    }
    if (transactionGuid === 'new') {
      const svc = new TransactionsRouteService(this.rootStore)
      const vm = svc.processNewRoute(accountGuid, newType)
      if (vm) this.editVM = vm
      return
    }
    if (transactionGuid === 'fromBankTrans') {
      const bankTrans = this.rootStore.bankTransactionsStore.get(bankTransactionGuid)
      console.log(bankTransactionGuid)
      if (!bankTrans) {
        setTimeout(
          () => this.lazyLoadEditVM(accountGuid, transactionGuid, newType, bankTransactionGuid, attempts++),
          500
        )
        return
      }
      this.editVM = new ExpenseEditVM(
        this.rootStore,
        Transaction.create(this.rootStore.boardsStore.currentBoardId, accountGuid),
        true,
        bankTrans
      )
      return
    }
    const svc = new TransactionsRouteService(this.rootStore)
    const vm = svc.processExistingRoute(transactionGuid)
    if (vm) this.editVM = vm
    if (!vm) {
      setTimeout(() => this.lazyLoadEditVM(accountGuid, transactionGuid, newType, bankTransactionGuid, attempts++), 500)
      return
    }
  }

  @action
  public loadListVM(accountGuid: string) {
    if (!this.rootStore.accountsStore.isHydrated) {
      setTimeout(() => this.loadListVM(accountGuid), 100)
      return
    }
    const account = this.rootStore.accountsStore.get(accountGuid)
    if (!account) {
      setTimeout(() => this.loadListVM(accountGuid), 100)
      return
    }
    if (this.listVM?.accountGuid === accountGuid) {
      this.listVM.reloadRows()
      return
    }
    this.listVM = new TransactionsListVM(this.rootStore, accountGuid)
  }

  public onLoadDataCompleted(): void {
    if (!this.listVM) return
    this.listVM.reloadRows()
  }

  @computed
  public get currentBoardRecords() {
    if (!this.isHydrated) return []
    return this.persistedRecords.filter((e) => e.BoardId === this.rootStore.boardsStore.currentBoardId)
  }

  public hasTransactionsForAccount(accountGuid: string): boolean {
    return Boolean(this.persistedRecords.find((e) => e.AccountGuid === accountGuid))
  }

  public getTransactionsForAccount(accountGuid: string): Transaction[] {
    return this.persistedRecords.filter((e) => e.AccountGuid === accountGuid)
  }

  public onAfterUpdate(item: Transaction): void {
    // this.loadListVM(this.listVM.accountGuid)
    // this.listVM.reloadRows()
  }

  public getTransactionsWithInboxTransactions() {
    return observable.array(
      this.currentBoardRecords.slice().concat(
        this.rootStore.bankTransactionsStore.currentBoardRecords
          .filter((e) => !e.hasLinkedTransaction && !e.IsSkipped)
          .slice()
          .map((e) => e.toTransaction())
      )
    )
  }
}
