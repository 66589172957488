import { observable, action, computed, makeObservable, runInAction } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { BankTransaction } from '../aggregate/BankTransaction'
import { BankTransactionsListVM } from '../view-models/list/BankTransactionsListVM'
import { IBankTransactionDTO } from '../dtos/IBankTransactionDTO'
import { DataStore } from '@elexient/elexiapp.bits.shared'
import AgentV2 from '../../AgentV2'
import { makePersistable } from 'mobx-persist-store'
import { deserialize } from 'serializr'
import { BankTransactionEditVM } from '../view-models/edit/BankTransactionEditVM'

export class BankTransactionsStore extends DataStore<RootStore, BankTransaction, IBankTransactionDTO> {
  protected worker: any

  constructor(rootStore: RootStore) {
    super(rootStore, BankTransaction, AgentV2, 'BankTransactions', 'BankTransaction')
    makeObservable(this)
    makePersistable(this, { name: 'BankTransactionsStore', properties: ['persistedRecords'] }).then(
      action((st) => this.onHydrationCompleted(st?.isHydrated))
    )
  }

  @action
  public onHydrationCompleted(isHydrated?: boolean) {
    this.persistedRecords = observable.array(
      this.persistedRecords.map((e) => makeObservable(deserialize(BankTransaction, e)))
    )
    this.setRecords(this.persistedRecords)
    this.isHydrated = Boolean(isHydrated)
    if (process.env.NODE_ENV === 'test') this.isHydrated = true
    // this.listVM = new BankTransactionsListVM(this.rootStore as RootStore)
  }

  @observable.shallow public persistedRecords: Array<BankTransaction> = []
  @observable public listVM: BankTransactionsListVM = undefined
  @observable public isHydrated: boolean = false
  @observable public editVM: BankTransactionEditVM = undefined

  public onLoadDataCompleted(): void {
    if (!this.listVM) return
    this.listVM.reloadRows()
  }

  @action
  public lazyLoadEditVM(guid: string, attempts: number = 0) {
    if (attempts === 10) return
    if (guid === 'empty') {
      this.editVM = null
    } else {
      const found = this.get(guid)
      if (!found) setTimeout(() => this.lazyLoadEditVM(guid, attempts++), 500)
      else this.editVM = new BankTransactionEditVM(this.rootStore, found.clone())
    }
  }

  @computed
  public get currentBoardRecords() {
    if (!this.isHydrated) return []
    return this.persistedRecords.filter((e) => e.BoardId === this.rootStore.boardsStore.currentBoardId)
  }
}
