import superagentPromise from 'superagent-promise'
import _superagent from 'superagent'

const superagent = superagentPromise(_superagent, global.Promise)

const API_ROOT = process.env.REACT_APP_API_URL + '/api'

const handleErrors = err => err
const responseBody = res => res.body

const tokenPlugin = req => {
  if (window.token) {
    req.set('Authorization', `Bearer ${window.token}`)
  }
}

const requests = {
  del: url =>
    superagent
      .del(`${API_ROOT}${url}`)
      .use(tokenPlugin)
      .end(handleErrors)
      .then(responseBody),

  get: url =>
    superagent
      .get(`${API_ROOT}${url}`)
      .use(tokenPlugin)
      .end(handleErrors)
      .then(responseBody),

  put: (url, body) =>
    superagent
      .put(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      .end(handleErrors)
      .then(responseBody),

  post: (url, body = {}, token = null) =>
    superagent
      .post(`${API_ROOT}${url}`, body)
      .use(req => {
        let tk = null
        if (token) tk = token
        else if (window && window.token) tk = window.token
        req.set('Authorization', `Bearer ${tk}`)
      })
      .end(handleErrors)
      .then(responseBody),
}

const Auth = {
  current: () => requests.get('/user'),
  login: (email, password) => requests.post('/login/tryloginV2', { email, password }),
  tryGoogleLogin: id => requests.post('/login/trygooglelogin', { googleUserId: id }),
  tryAppleLogin: (token, userName) => requests.post('/login/tryapplelogin', { appleUserToken: token, userName: userName }),
  tryGuestRegister: frm => requests.post('/login/tryguestregister', { ...frm }),
  register: (username, email, password) => requests.post('/users', { user: { username, email, password } }),
  save: user => requests.put('/user', { user }),
}

const ListItems = {
  getAll: () => requests.get('/listitems/all'),
  save: form => requests.post('/listitems/save', { ...form }),
}

const Boards = {
  getAll: () => requests.get('/boards/all'),
  save: form => requests.post('/boards/save', { ...form }),
  delete: form => requests.post('/boards/save', { ...form }),
}

const BoardInvitations = {
  getAll: () => requests.get('/boardinvitations/all'),
  getPending: () => requests.get('/boardinvitations/pending'),
  get: id => requests.get('/boardinvitations/get?id=' + id),
  save: form => requests.post('/boardinvitations/save', { ...form }),
  saveNew: form => requests.post('/boardinvitations/saveNew', { ...form }),
  accept: form => requests.post('/boardinvitations/accept', { ...form }),
  decline: form => requests.post('/boardinvitations/decline', { ...form }),
}

const Users = {
  getInfo: () => requests.get('/appusers/info'),
  save: form => requests.post('/appusers/save', { ...form }),
  addGoogleLogin: form => requests.post('/appusers/addGoogleLogin', { ...form }),
  addAppleLogin: form => requests.post('/appusers/addAppleLogin', { ...form }),
  tryRegister: form => requests.post('/login/tryregister', { ...form }),
  forgotPassword: form => requests.post('/appusers/forgotpassword', { ...form }),
  resetPassword: form => requests.post('/appusers/resetpassword', { ...form }),
}

const Items = {
  getAll: () => requests.get('/items/all'),
  save: form => requests.post('/items/save', { ...form }),
}

const Categories = {
  getAll: () => requests.get('/categories/all'),
  save: form => requests.post('/categories/save', { ...form }),
}

const Recipes = {
  getAll: () => requests.get('/recipes/all'),
  save: form => requests.post('/recipes/save', { ...form }),
}

const Reminders = {
  getAll: () => requests.get('/reminders/all'),
  save: form => requests.post('/reminders/save', { ...form }),
}

const MealCategories = {
  getAll: () => requests.get('/mealcategories/all'),
  saveNew: form => requests.post('/mealcategories/saveNew', { ...form }),
  save: form => requests.post('/mealcategories/save', { ...form }),
  delete: form => requests.post('/mealcategories/delete', { ...form }),
}

const RecipeCategories = {
  getAll: () => requests.get('/recipecategories/all'),
  save: form => requests.post('/recipecategories/save', { ...form }),
  delete: form => requests.post('/recipecategories/delete', { ...form }),
}

const Meals = {
  getAll: () => requests.get('/meals/all'),
  save: form => requests.post('/meals/save', { ...form }),
  delete: form => requests.post('/meals/delete', { ...form }),
}

const Accounts = {
  getAll: () => requests.get('/accounts/all'),
  save: form => requests.post('/accounts/save', { ...form }),
  delete: form => requests.post('/accounts/delete', { ...form }),
}

export default {
  Auth,
  ListItems,
  Items,
  Categories,
  Accounts,
  Boards,
  Users,
  BoardInvitations,
  Recipes,
  RecipeCategories,
  MealCategories,
  Meals,
  Reminders
}
