import { observable, action, makeObservable } from 'mobx'
import { serialize, deserialize, serializable } from 'serializr'
import { ICategorySplitDTO } from '../dtos/ICategorySplitDTO'
import generateUUID from '../../utils/UUID'

export class CategorySplit implements ICategorySplitDTO {
  public static create() {
    const split = new CategorySplit()
    split.CategorySplitGuid = generateUUID()
    return split
  }

  constructor() {
    makeObservable(this)
  }

  @serializable @observable public CategorySplitGuid: string = ''
  @serializable @observable public CategoryGuid: string = undefined
  @serializable @observable public Amount: number = 0
  @serializable @observable public IsDefault: boolean = false
  @serializable @observable public IsDeleted: boolean = false

  @action
  public setCategory(val: string) {
    this.CategoryGuid = val
  }

  @action
  public setAmount(val: number) {
    this.Amount = Number(val)
  }

  @action
  public markAsDeleted() {
    this.IsDeleted = true
  }

  public clone(): CategorySplit {
    return deserialize(CategorySplit, serialize(this))
  }

  public toDTO(): ICategorySplitDTO {
    return serialize(this)
  }
}
