import agent from '../../AgentV2'
import { RootStore } from '../../stores/RootStore'
import { ITransactionDTO } from '../dtos/ITransactionDTO'
import { ActionTypeEnum } from '../../actions/enum/ActionTypeEnum'

export class TransactionsService {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  public save(dto: ITransactionDTO) {
    this.rootStore.transactionsStore.updateFromServer(dto)
    this.rootStore.actionsStore.addAction(ActionTypeEnum.TRANSACTION_SAVE, dto)
  }

  public async saveOnServer(dto: ITransactionDTO) {
    const savedDTO = await agent.Transactions.save(dto)
    this.rootStore.transactionsStore.updateFromServer(savedDTO)
  }

  public delete(dto: ITransactionDTO) {
    dto.IsDeleted = true
    this.save(dto)
  }

  public async getFirstPageForAccount(accountGuid: string, pageSize: number): Promise<ITransactionDTO[]> {
    return await agent.Transactions.getFirstPageForAccount({ accountGuid, pageSize })
  }

  public async getMoreClearedTransactions(
    accountGuid: string,
    pageSize: number,
    pageNumber: number
  ): Promise<ITransactionDTO[]> {
    return await agent.Transactions.getMoreCleared({ accountGuid, pageSize, pageNumber })
  }
}
