import React from 'react'
import {
  IonList,
  IonItem,
  IonButton,
  IonModal,
  IonLabel,
  IonInput,
  IonCard,
  IonCardHeader,
  IonTitle,
  IonCardContent,
  IonToolbar,
} from '@ionic/react'
import { observer, inject } from 'mobx-react'
import { BoardsStore } from '../store/BoardsStore'
import './../styles/BoardNewModal.scss'

interface Props {
  boardsStore?: BoardsStore
}

const BoardNewModal: React.FC<Props> = ({ boardsStore }) => {
  const { newVM: vm } = boardsStore
  if (!vm) return null
  if (!vm.isOpen) return null

  const handleSave = (e) => {
    e.preventDefault()
    vm.save()
  }

  return (
    <IonModal id='BoardNewModal' isOpen={vm.isOpen} showBackdrop={true} onDidDismiss={() => vm.close()}>
      <IonCard>
        <IonCardHeader>
          <IonTitle>New Board</IonTitle>
        </IonCardHeader>
        <IonCardContent>
          <form onSubmit={(e) => handleSave(e)}>
            <IonList>
              <IonItem lines={'inset'}>
                <IonLabel position='floating'>Name</IonLabel>
                <IonInput
                  type='text'
                  ref={(e) => vm.setNameInputRef(e)}
                  value={vm.name}
                  onIonChange={(e: any) => vm.setName(e.target.value)}
                  clearOnEdit={true}
                />
              </IonItem>

              <div id='buttons'>
                <IonButton type='button' size='small' color='secondary' onClick={() => vm.close()}>
                  Cancel
                </IonButton>
                <IonButton type='submit' size='small' color='tertiary' disabled={!vm.isValid}>
                  Create
                </IonButton>
              </div>
            </IonList>
          </form>
        </IonCardContent>
      </IonCard>
    </IonModal>
  )
}

export default inject('boardsStore')(observer(BoardNewModal))
