import { computed, action, makeObservable, observable } from 'mobx'
import { RowVM } from '@elexient/elexiapp.bits.shared'
import { TransactionsListVM } from './TransactionsListVM'
import Sugar from 'sugar'
import moment, { ISO_8601 } from 'moment'
import { RootStore } from '../../../stores/RootStore'
import { Transaction } from '../../aggregate/Transaction'
import { TransactionStatusEnumName } from '../../../transactions/enum/TransactionStatusEnumName'
import { TransactionTypeEnum } from '../../../transactions/enum/TransactionTypeEnum'
import { RemindersService } from '../../services/RemindersService'
import { TransactionsService } from '../../../transactions/services/TransactionsService'
import { ITransactionDTO } from '../../../transactions/dtos/ITransactionDTO'
import { TransactionStatusEnum } from '../../../transactions/enum/TransactionStatusEnum'

export class TransactionRowVM extends RowVM<RootStore, TransactionsListVM, Transaction> {
  constructor(rootStore: RootStore, listVM: TransactionsListVM, reminder: Transaction, self: any) {
    super(rootStore, listVM, reminder, self)
  }

  @observable public runningTotal: number = 0

  public get height(): number {
    if (this.isHidden) return 0
    return 50
  }

  public get isEven(): boolean {
    return (this.normalRowIndex + 1) % 2 === 0
  }

  @computed
  public get keyword(): string {
    return this.payeeName
  }

  @computed
  public get payeeName(): string {
    return this.record.PayeeName
  }

  @computed
  public get key(): string {
    return this.record.TransactionGuid
  }

  @action
  public click() {
    const isSlidOpen = document.getElementsByClassName('item-sliding-active-slide').length > 0
    if (isSlidOpen) return
    let url = '/transactionedit'
    if (this.isExpense) url = '/expenseedit'
    url = url + '/' + this.record.AccountGuid + '/' + this.record.TransactionGuid
    this.rootStore.appStore.navigateTo(url)
  }

  @computed
  public get hasNotes(): boolean {
    return Boolean(this.record.Notes)
  }

  @computed
  public get notes(): string {
    if (!this.hasNotes) return 'No Notes'
    return this.record.Notes
  }

  public get period(): string {
    let abbr1 = Sugar.Date(new Date()).getFullYear().raw.toString()
    abbr1 = abbr1 + Sugar.Date(new Date()).getMonth().raw.toString()
    let abbr2 = Sugar.Date(this.record.TransactionDate).getFullYear().raw.toString()
    abbr2 = abbr2 + Sugar.Date(this.record.TransactionDate).getMonth().raw.toString()
    if (abbr1 === abbr2) return '1This Month'
    if (moment(this.record.TransactionDate, ISO_8601).isAfter(new Date())) return '0Upcoming'
    return '2Past'
  }

  public get statusName(): string {
    return TransactionStatusEnumName.fromId(this.record.TransactionStatusId)
  }

  @computed
  public get day(): string {
    if (!this.record.TransactionDate) return ''
    const day = this.transactionDate.clone().format('D')
    return day
  }

  @computed
  public get monthAbbr(): string {
    let str = this.transactionDate.format('MMM')
    return str
  }

  @computed
  public get isPositive(): boolean {
    return this.record.isPositive
  }

  @computed
  public get amount(): number {
    return this.record.Amount
  }

  private get isExpense(): boolean {
    return this.record.TransactionTypeId === TransactionTypeEnum.Expense
  }

  @computed
  public get amountFormatted(): string {
    let str = Sugar.Number(this.record.Amount).format(2).raw
    if (this.record.isPositive) str = '+' + str
    return str
  }

  @action
  public setRunningTotal(val: number) {
    this.runningTotal = val
  }

  @computed
  public get runningTotalIsNegative(): boolean {
    return Boolean(this.runningTotal < 0)
  }

  @computed
  public get runningTotalFormatted(): string {
    let str = Sugar.Number(this.runningTotal).format(2).raw
    return str
  }

  @computed
  public get transactionDate(): moment.Moment {
    return moment(this.record.TransactionDate, moment.ISO_8601).add(1, 'hour')
  }

  public skip() {
    this.record.markAsSkipped()
    const svc = new TransactionsService(this.rootStore)
    svc.save(this.record.toDTO() as ITransactionDTO)
  }

  public unskip() {
    this.record.markAsUpcoming()
    const svc = new TransactionsService(this.rootStore)
    svc.save(this.record.toDTO() as ITransactionDTO)
  }

  public paid() {
    if (this.record.isUpcoming) this.record.markAsPending()
    else this.record.markAsCleared()
    const svc = new TransactionsService(this.rootStore)
    svc.save(this.record.toDTO() as ITransactionDTO)
  }

  public cleared() {
    this.record.markAsCleared()
    const svc = new TransactionsService(this.rootStore)
    svc.save(this.record.toDTO() as ITransactionDTO)
  }

  @computed
  public get unskippable() {
    return this.record.TransactionStatusId === TransactionStatusEnum.Skipped
  }

  @computed
  public get skippable() {
    return this.record.skippable
  }

  @computed
  public get payable() {
    return this.record.payable
  }

  @computed
  public get clearable() {
    return this.record.clearable
  }
}
