import React from 'react'
import {
  IonList,
  IonItem,
  IonButton,
  IonModal,
  IonLabel,
  IonInput,
  IonSpinner,
  IonToolbar,
  IonTitle,
  IonCardHeader,
  IonCardContent,
  IonCard,
} from '@ionic/react'
import { observer, inject } from 'mobx-react'
import { BoardInvitationsStore } from './../store/BoardInvitationsStore'
import './../styles/BoardInvitationNewModal.scss'

interface Props {
  boardInvitationsStore?: BoardInvitationsStore
}

const BoardInvitationNewModal: React.FC<Props> = ({ boardInvitationsStore }) => {
  const { newVM: vm } = boardInvitationsStore
  if (!vm) return null

  const handleSaveClick = e => {
    e.preventDefault()
    vm.save()
  }

  const handleCancelClick = e => {
    e.preventDefault()
    vm.toggle()
  }

  const handleDismiss = () => {
    if (!vm.isOpen) return
    vm.toggle()
  }

  const renderSpinner = () => {
    if (!vm.isProcessing) return
    if (vm.isIos) return <IonSpinner />
    return <IonSpinner name='crescent' />
  }

  const renderButtons = () => {
    if (vm.isProcessing) return
    return (
      <div id='buttons'>
        <IonButton type='button' size='small' color='secondary' onClick={handleCancelClick}>
          Cancel
        </IonButton>
        <IonButton type='submit' size='small' color='tertiary' disabled={!vm.isValid}>
          Invite
        </IonButton>
      </div>
    )
  }

  return (
    <IonModal id='BoardInvitationNewModal' isOpen={vm.isOpen} showBackdrop={true} onDidDismiss={handleDismiss}>
      <IonCard>
        <IonCardHeader>
          <IonTitle>Invite to Board</IonTitle>
        </IonCardHeader>
        <IonCardContent>
          <form onSubmit={handleSaveClick}>
            <IonList>
              <IonItem lines={'inset'}>
                <IonLabel position='floating'>Email</IonLabel>
                <IonInput
                  type='email'
                  className='ion-input-md'
                  value={vm.toEmailAddress}
                  onIonChange={(e: any) => vm.setToEmailAddress(e.target.value)}
                  ref={e => vm.setEmailInput(e)}
                />
              </IonItem>
              {renderButtons()}
              {renderSpinner()}
            </IonList>
          </form>
        </IonCardContent>
      </IonCard>
    </IonModal>
  )
}

export default inject('boardInvitationsStore')(observer(BoardInvitationNewModal))
