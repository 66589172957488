import React from 'react'
import { IonButton, IonList, IonItemDivider, IonItem, IonText } from '@ionic/react'
import { inject, observer } from 'mobx-react'
import { UserStore } from '../store/UserStore'

interface Props {
  userStore?: UserStore
}

const AppleLogin: React.FC<Props> = ({ userStore }) => {
  const { userEditVM: vm } = userStore
  if (!vm) return

  const renderRemoveAppleLogin = () => {
    if (!vm.hasAppleLogin) return
    return (
      <>
        {/* <IonText>{vm.appleEmail}</IonText>
        &nbsp; */}
        <IonButton>Remove</IonButton>
      </>
    )
  }

  const renderConnectAppleLogin = () => {
    if (vm.hasAppleLogin) return
    return (
      <>
        <IonButton onClick={() => vm.connectApple()}>Connect</IonButton>
      </>
    )
  }

  return (
    <IonList mode='md'>
      <IonItemDivider color='medium' mode='md'>
        Connected Apple Login
      </IonItemDivider>
      <IonItem lines={'inset'}>
        {renderRemoveAppleLogin()}
        {renderConnectAppleLogin()}
      </IonItem>
    </IonList>
  )
}

export default inject('userStore')(observer(AppleLogin))
