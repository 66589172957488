import { observable, action, computed, makeObservable } from 'mobx'
import { RootStore } from 'src/stores/RootStore'
import { Reminder } from '../../aggregate/Reminder'
import { RemindersService } from '../../services/RemindersService'
import { ReminderTypeEnumName } from '../../enum/ReminderTypeEnumName'
import { SelectableColors } from '../../../shared/SelectableColors'
import { Account } from '../../../accounts/aggregate/Account'
import { ReminderTypeEnum } from '../../enum/ReminderTypeEnum'
import { TransactionsListVM } from './TransactionsListVM'
import { Category } from '../../../categories/aggregate/Category'

export class ReminderEditVM {
  private rootStore: RootStore
  private contentRef: HTMLIonContentElement

  constructor(rootStore: RootStore, reminder: Reminder, newType: ReminderTypeEnum = undefined) {
    makeObservable(this)
    this.rootStore = rootStore
    this.loadReminder(reminder, newType)
  }

  @observable public deleteConfirmShown: boolean = false
  @observable public dayOfMonth: number = 1
  public isNew: boolean = false
  @observable public currentTabIndex: string = '0'
  @observable public transListVM: TransactionsListVM
  public reminder: Reminder

  public get reminderGuid(): string {
    return this.reminder.ReminderGuid
  }

  @action
  public loadReminder(reminder: Reminder, newType: ReminderTypeEnum = undefined) {
    this.reminder = reminder.clone()
    this.isNew = Boolean(newType)
    this.dayOfMonth = this.reminder.Schedule.day
    if (this.isNew) this.setType(newType)
    if (this.transListVM) this.transListVM.dispose()
    this.transListVM = new TransactionsListVM(this.rootStore, this.reminder)
  }

  @action
  public setName(val) {
    this.reminder.setName(val)
  }

  @computed
  public get name(): string {
    return this.reminder.Name
  }

  @action
  public setNotes(val) {
    this.reminder.setNotes(val)
  }

  @computed
  public get notes(): string {
    return this.reminder.Notes
  }

  @computed
  public get isValid(): boolean {
    if (!this.name || this.name === '') return false
    if (!this.amountAsString) return false
    return true
  }

  @computed
  public get pageTitle(): string {
    if (this.isNew) {
      let str = 'New '
      if (this.typeId) str += this.typeName + ' '
      str += 'Reminder'
      return str
    }
    return 'Edit Reminder'
  }

  @action
  public save() {
    const svc = new RemindersService(this.rootStore)
    svc.save(this.reminder.toDTO())
    this.rootStore.appStore.history.push('/reminderslist')
  }

  @action
  public showDeleteConfirm() {
    this.deleteConfirmShown = true
  }

  @action
  public hideDeleteConfirm() {
    this.deleteConfirmShown = false
  }

  @computed
  public get color(): string {
    if (!this.reminder.Color) return ''
    return this.reminder.Color
  }

  @action
  public setColor(hex: string) {
    this.reminder.setColor(hex)
  }

  public delete() {
    const svc = new RemindersService(this.rootStore)
    svc.delete(this.reminder.toDTO())
    this.rootStore.appStore.handleGoBack()
  }

  @computed
  public get typeName(): string {
    return ReminderTypeEnumName.fromId(this.reminder.TypeId)
  }

  @computed
  public get types() {
    return ReminderTypeEnumName.list
  }

  @action
  public setType(val: number) {
    this.reminder.setType(val)
  }

  @computed
  public get typeId(): number {
    return this.reminder.TypeId
  }

  @action
  public goBack() {
    this.rootStore.appStore.handleGoBack()
  }

  public get colors() {
    return SelectableColors.colors
  }

  @computed
  public get accountGuid(): string {
    return this.reminder.AccountGuid
  }

  @computed
  public get accounts(): Account[] {
    return this.rootStore.accountsStore.currentBoardRecords.sort((a, b) =>
      a.Name.toLowerCase() < b.Name.toLowerCase() ? -1 : 0
    )
  }

  @action
  public setAccount(val: string) {
    this.reminder.setAccount(val)
  }

  @computed
  public get amountAsString(): string {
    if (this.reminder.Amount === 0) return ''
    return this.reminder.Amount.toString()
  }

  @action
  public setAmount(val: string) {
    this.reminder.setAmount(Number(val))
  }

  @action
  public setDay(val: number) {
    this.dayOfMonth = val
    this.reminder.Schedule.setDay(val)
  }

  public setContentRef(e: HTMLIonContentElement) {
    this.contentRef = e
  }

  @action
  public setCurrentTab(val: string) {
    if (this.currentTabIndex === val) return
    this.currentTabIndex = val
    this.contentRef.scrollToTop()
  }

  @computed
  public get autoPaid(): boolean {
    return this.reminder.AutoPaid
  }

  @action
  public toggleAutoPaid(): void {
    this.reminder.toggleAutoPaid()
  }

  @computed
  public get sendPastDueNotification(): boolean {
    return this.reminder.SendPastDueNotification
  }

  @action
  public toggleSendPastDueNotification(): void {
    this.reminder.toggleSendPastDueNotification()
  }

  @computed
  public get categoryGuid(): string {
    return this.reminder.CategoryGuid
  }

  @computed
  public get categories(): Category[] {
    return this.rootStore.categoriesStore.currentBoardRecords.sort((a, b) => (a.Name < b.Name ? -1 : 0))
  }

  @action
  public setCategory(val: string) {
    this.reminder.setCategory(val)
  }

  public dispose() {
    this.transListVM.dispose()
  }
}
