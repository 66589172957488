import { observable, computed, makeObservable, action } from 'mobx'
import { IBankTransactionDTO } from '../dtos/IBankTransactionDTO'
import { serialize, deserialize, serializable } from 'serializr'
import { IAggregrate } from '../../shared/IAggregate'
import { Transaction } from '../../transactions/aggregate/Transaction'

export class BankTransaction implements IBankTransactionDTO, IAggregrate {
  constructor() {
    makeObservable(this)
  }

  @serializable @observable public BankTransactionGuid: string
  @serializable @observable public BoardId: number
  @serializable @observable public ExternalTransId: string
  @serializable @observable public OtherExternalTransId: string
  @serializable @observable public PayeeName: string
  @serializable @observable public LongPayeeName: string
  @serializable @observable public Amount: number
  @serializable @observable public TransactionDate: string
  @serializable @observable public IsPending: boolean
  @serializable @observable public AccountGuid: string
  @serializable @observable public IsDeleted: boolean
  @serializable @observable public TransactionGuid: string
  @serializable @observable public IsSkipped: boolean

  @action
  public setTransaction(val: string) {
    this.TransactionGuid = val
  }

  public clone(): BankTransaction {
    return deserialize(BankTransaction, serialize(this))
  }

  public toDTO(): IBankTransactionDTO {
    return serialize(this)
  }

  @computed
  public get isPositive() {
    return Boolean(this.Amount >= 0)
  }

  @computed
  public get hasLinkedTransaction(): boolean {
    return Boolean(this.TransactionGuid)
  }

  @action
  public setSkipped() {
    this.IsSkipped = true
  }

  @computed
  public get isInInbox(): boolean {
    return Boolean(!this.IsDeleted && !this.hasLinkedTransaction && !this.IsSkipped)
  }

  public toTransaction(): Transaction {
    return Transaction.createFromBankTransaction(this)
  }
}
