import React from 'react'
import { observer, inject } from 'mobx-react'
import { AppStore } from '../../../stores/AppStore'
import { RootStore } from '../../../stores/RootStore'
import { match } from 'react-router'
import { RemindersStore } from 'src/reminders/store/RemindersStore'
import ReminderEditArea from './ReminderEditArea'

interface Props {
  remindersStore: RemindersStore
  appStore: AppStore
  rootStore: RootStore
  history: any
  match: match
}

@inject('remindersStore')
@inject('appStore')
@observer
export default class ReminderEditPage extends React.Component<Props> {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.props.appStore.setCurrentRoute(this.props.match.url)
    this.props.remindersStore.lazyLoadEditVM(this.props.match.params['id'], this.props.match.params['newType'])
  }

  componentWillUnmount() {
    this.props.remindersStore.lazyLoadEditVM('empty')
  }

  render() {
    return <ReminderEditArea />
  }
}
