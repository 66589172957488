import React from 'react'
import {
  IonContent,
  IonRefresher,
  IonRefresherContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonCard,
  IonCardHeader,
  IonCardTitle,
} from '@ionic/react'
import { observer, inject } from 'mobx-react'
import { AppStore } from '../../stores/AppStore'
import ToDoWidget from './todo-widget/ToDoWidget'
import NetWorthWidget from '../../accounts/views/net-worth-widget/NetWorthWidget'
import UpcomingBillsWidget from '../../upcoming-bills/views/UpcomingBillsWidget'

interface Props {
  appStore: AppStore
}

@inject('appStore')
@observer
export default class DashboardPage extends React.Component<Props, any> {
  componentDidMount() {
    this.props.appStore.setCurrentRoute('/')
  }

  handleRefresh(event) {
    // this.props.listItemsStore.loadData().then(() => {
    setTimeout(() => {
      if (event) event.target.complete()
    }, 200)
    // })
  }

  renderRefresher() {
    return (
      <IonRefresher slot='fixed' onIonRefresh={(e) => this.handleRefresh(e)}>
        <IonRefresherContent />
      </IonRefresher>
    )
  }

  render() {
    return (
      <>
        <IonHeader>
          <IonToolbar />
        </IonHeader>

        <IonHeader id='DashboardPage_Header'>
          <IonToolbar color='secondary'>
            <IonTitle>Dashboard</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent id='DasboardPage_Content' style={{ height: this.props.appStore.listHeightPx }} color='medium'>
          {this.renderRefresher()}
          <ToDoWidget />
          <NetWorthWidget />
          <UpcomingBillsWidget />
        </IonContent>
      </>
    )
  }
}
