import { computed } from 'mobx'
import { Account } from '../../accounts/aggregate/Account'
import { Category } from '../../categories/aggregate/Category'
import { RootStore } from '../../stores/RootStore'

export class CategoryBadgeVM {
  constructor(
    private rootStore: RootStore,
    private type: 'category' | 'other' | 'transfer',
    private categoryGuid?: string,
    private badgeName?: string,
    private accountGuid?: string
  ) {}

  @computed
  public get name(): string {
    if (this.type === 'other') return this.badgeName
    if (this.type === 'transfer') return this.accountName
    return this.categoryName
  }

  @computed
  public get color(): string {
    if (!this.category) return undefined
    return this.category.Color
  }

  @computed
  private get accountName(): string {
    if (!this.accountGuid) return undefined
    return '[' + this.account.Name + ']'
  }

  @computed
  private get categoryName(): string {
    if (!this.category) return undefined
    return this.category.Name
  }

  @computed
  private get category(): Category {
    if (!this.categoryGuid) return undefined
    return this.rootStore.categoriesStore.get(this.categoryGuid)
  }

  @computed
  private get account(): Account {
    if (!this.accountGuid) return undefined
    return this.rootStore.accountsStore.get(this.accountGuid)
  }
}
