import { observable, action, computed, makeObservable } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { Transaction } from '../aggregate/Transaction'
import { TransactionsService } from '../services/TransactionsService'
import { NewTransactionTypeEnum } from '../enum/NewTransactionTypeEnum'
import { TransactionTypeEnum } from '../enum/TransactionTypeEnum'
import moment from 'moment'
import { TransactionStatusEnum } from '../enum/TransactionStatusEnum'
import { Category } from '../../categories/aggregate/Category'
import { AccountTypeEnum } from '../../accounts/enum/AccountTypeEnum'

export class BalanceAdjustmentEditVM {
  private rootStore: RootStore
  private transaction: Transaction

  constructor(rootStore: RootStore, transaction: Transaction, isNew: boolean = false) {
    makeObservable(this)
    this.rootStore = rootStore
    this.transaction = transaction.clone()
    this.isNew = isNew
    this.transaction.setTransactionType(TransactionTypeEnum.Balance_Adjustment)
    if (isNew) this.transaction.setTransactionStatus(TransactionStatusEnum.Cleared)
  }

  @observable public deleteConfirmShown: boolean = false
  public isNew: boolean = false

  @computed
  public get payeeName(): string {
    return this.transaction.PayeeName
  }

  @computed
  public get pageTitle(): string {
    if (!this.isNew) return this.transaction.PayeeName
    return 'Balance Adjustment'
  }

  @computed
  public get amount(): number {
    return this.transaction.Amount
  }

  @computed
  public get amountAsString(): string {
    if (this.amount === 0) return ''
    return this.amount.toString()
  }

  @computed
  public get isValid(): boolean {
    if (!this.accountGuid) return false
    if (!Boolean(this.amount)) return false
    return true
  }

  @computed
  public get notes(): string {
    return this.transaction.Notes
  }

  @action
  public setNotes(val: string) {
    this.transaction.setNotes(val)
  }

  @action
  public setAmount(val: number) {
    this.transaction.setAmount(val)
  }

  @action
  public save() {
    const svc = new TransactionsService(this.rootStore)
    svc.save(this.transaction.toDTO())
    this.rootStore.appStore.navigateTo('/transactionslist/' + this.transaction.AccountGuid)
  }

  @action
  public showDeleteConfirm() {
    this.deleteConfirmShown = true
  }

  @action
  public hideDeleteConfirm() {
    this.deleteConfirmShown = false
  }

  @action
  public goBack() {
    this.rootStore.appStore.navigateTo('/transactionslist/' + this.accountGuid)
  }

  public get accountGuid(): string {
    return this.transaction.AccountGuid
  }

  @computed
  public get color(): string {
    if (!this.transaction.Color) return ''
    return this.transaction.Color
  }

  @action
  public setColor(hex: string) {
    this.transaction.setColor(hex)
  }

  public delete() {
    const svc = new TransactionsService(this.rootStore)
    svc.delete(this.transaction.toDTO())
    this.rootStore.appStore.navigateTo('/transactionslist/' + this.accountGuid)
  }

  @computed
  public get transactionDate(): moment.Moment {
    return moment(this.transaction.TransactionDate).clone()
  }

  @action
  public setStatus(val: number) {
    this.transaction.setTransactionStatus(val)
  }

  @computed
  public get transactionStatusId(): number {
    return this.transaction.TransactionStatusId
  }

  @action
  public setTransactionDate(val: moment.Moment) {
    this.transaction.setTransactionDate(val)
  }

  @computed
  public get categories(): Category[] {
    return this.rootStore.categoriesStore.currentBoardRecords.sort((a, b) => (a.Name < b.Name ? -1 : 0))
  }

  @computed
  public get accounts() {
    return this.rootStore.accountsStore.currentBoardRecords.sort((a, b) => (a.Name < b.Name ? -1 : 0))
  }
}
