import { observable, action, makeObservable, computed } from 'mobx'
import { IReminderDTO } from '../dtos/IReminderDTO'
import { serialize, deserialize, serializable } from 'serializr'
import { IAggregrate } from '../../shared/IAggregate'
import { ITransactionDTO } from '../dtos/ITransactionDTO'
import { TransactionStatusEnum } from '../../transactions/enum/TransactionStatusEnum'

export class Transaction implements ITransactionDTO, IAggregrate {
  constructor() {
    makeObservable(this)
  }

  @serializable @observable public TransactionGuid: string = ''
  @serializable @observable public PayeeName: string = ''
  @serializable @observable public Notes: string = ''
  @serializable @observable public AccountGuid: string = ''
  @serializable @observable public CategoryGuid: string = ''
  @serializable @observable public Amount: number = 0
  @serializable @observable public BoardId: number = 0
  @serializable @observable public TransactionStatusId: TransactionStatusEnum = undefined
  @serializable @observable public TransactionDate: string = undefined
  @serializable @observable public TransactionTypeId: number = 0
  @serializable @observable public IsDeleted: boolean = false

  @action
  public markAsUpcoming() {
    this.TransactionStatusId = TransactionStatusEnum.Upcoming
  }

  @action
  public markAsSkipped() {
    this.TransactionStatusId = TransactionStatusEnum.Skipped
  }

  @action
  public markAsCleared() {
    this.TransactionStatusId = TransactionStatusEnum.Cleared
  }

  @action
  public markAsPending() {
    this.TransactionStatusId = TransactionStatusEnum.Pending
  }

  @computed
  public get skippable(): boolean {
    return this.isUpcoming
  }

  @computed
  public get payable(): boolean {
    return this.isUpcoming
  }

  @computed
  public get clearable(): boolean {
    return this.isPending
  }

  @computed
  public get isUpcoming(): boolean {
    return this.TransactionStatusId === TransactionStatusEnum.Upcoming
  }

  @computed
  public get isPending(): boolean {
    return this.TransactionStatusId === TransactionStatusEnum.Pending
  }

  @computed
  public get isPositive() {
    return Boolean(this.Amount >= 0)
  }
  
  @computed
  public get transactionDateAsDate(): Date {
    return new Date(this.TransactionDate)
  }

  public clone(): Transaction {
    return deserialize(Transaction, serialize(this))
  }

  public toDTO(): ITransactionDTO {
    return serialize(this)
  }
}
