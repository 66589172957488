import { observable, action, computed, makeObservable } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { Transaction } from '../aggregate/Transaction'
import { TransactionsService } from '../services/TransactionsService'
import { SelectableColors } from '../../shared/SelectableColors'
import { Category } from '../../categories/aggregate/Category'
import { TransactionStatusEnumName } from '../enum/TransactionStatusEnumName'
import { TransactionTypeEnumName } from '../enum/TransactionTypeEnumName'
import { NewTransactionTypeEnum } from '../enum/NewTransactionTypeEnum'
import { NewTransactionTypeEnumName } from '../enum/NewTransactionTypeEnumName'
import { TransactionTypeEnum } from '../enum/TransactionTypeEnum'
import moment from 'moment'

export class TransactionEditVM {
  private rootStore: RootStore
  private transaction: Transaction
  private newTypeId: NewTransactionTypeEnum

  constructor(rootStore: RootStore, transaction: Transaction, newTypeId: NewTransactionTypeEnum = undefined) {
    makeObservable(this)
    this.rootStore = rootStore
    this.transaction = transaction.clone()
    this.isNew = Boolean(newTypeId)
    this.newTypeId = newTypeId
    this.computeNewTransactionType()
  }

  @observable public deleteConfirmShown: boolean = false
  public isNew: boolean = false

  @action
  public computeNewTransactionType() {
    if (!this.isNew) return
    if (this.newTypeId === NewTransactionTypeEnum.Income) this.setTransactionType(TransactionTypeEnum.Income)
    if (this.newTypeId === NewTransactionTypeEnum.Expense) this.setTransactionType(TransactionTypeEnum.Expense)
    if (this.newTypeId === NewTransactionTypeEnum.Transfer) this.setTransactionType(TransactionTypeEnum.Transfer_Out)
    if (this.newTypeId === NewTransactionTypeEnum.Credit_Card_Payment)
      this.setTransactionType(TransactionTypeEnum.Credit_Card_Payment)
    if (this.newTypeId === NewTransactionTypeEnum.Balance_Adjustment)
      this.setTransactionType(TransactionTypeEnum.Balance_Adjustment)
  }

  @action
  public setPayeeName(val) {
    this.transaction.setPayeeName(val)
  }

  @computed
  public get payeeName(): string {
    return this.transaction.PayeeName
  }

  @computed
  public get pageTitle(): string {
    if (!this.isNew) return this.transaction.PayeeName
    return 'New ' + NewTransactionTypeEnumName.fromId(this.newTypeId)
  }

  @computed
  public get amount(): number {
    if (this.transaction.Amount === 0) return undefined
    return this.transaction.Amount
  }

  @computed
  public get isValid(): boolean {
    if (!this.payeeName || this.payeeName === '') return false
    return true
  }

  @computed
  public get notes(): string {
    return this.transaction.Notes
  }

  @action
  public setNotes(val: string) {
    this.transaction.setNotes(val)
  }

  @action
  public setAmount(val: number) {
    this.transaction.setAmount(val)
  }

  @action
  public save() {
    const svc = new TransactionsService(this.rootStore)
    svc.save(this.transaction.toDTO())
    this.rootStore.appStore.navigateTo('/transactionslist/' + this.transaction.AccountGuid)
  }

  @action
  public showDeleteConfirm() {
    this.deleteConfirmShown = true
  }

  @action
  public hideDeleteConfirm() {
    this.deleteConfirmShown = false
  }

  @action
  public goBack() {
    this.rootStore.appStore.navigateTo('/transactionslist/' + this.accountGuid)
  }

  public get accountGuid(): string {
    return this.transaction.AccountGuid
  }

  @computed
  public get color(): string {
    if (!this.transaction.Color) return ''
    return this.transaction.Color
  }

  @action
  public setColor(hex: string) {
    this.transaction.setColor(hex)
  }

  public delete() {
    const svc = new TransactionsService(this.rootStore)
    svc.delete(this.transaction.toDTO())
    this.rootStore.appStore.navigateTo('/transactionslist/' + this.accountGuid)
  }

  @computed
  public get transactionDate(): moment.Moment {
    return moment(this.transaction.TransactionDate).clone()
  }

  @action
  public setStatus(val: number) {
    this.transaction.setTransactionStatus(val)
  }

  @action
  public markAsSkipped() {
    this.transaction.markAsSkipped()
  }

  @computed
  public get transactionStatusId(): number {
    return this.transaction.TransactionStatusId
  }

  @action
  public setTransactionType(val: number) {
    this.transaction.setTransactionType(val)
  }

  @computed
  public get transactionTypeId(): number {
    return this.transaction.TransactionTypeId
  }

  @computed
  public get transactionTypeName(): string {
    return TransactionTypeEnumName.fromId(this.transactionTypeId)
  }

  public get boardId(): number {
    return this.transaction.BoardId
  }

  public get transactionTypes() {
    return TransactionTypeEnumName.list
  }

  public get statuses() {
    return TransactionStatusEnumName.list
  }

  @action
  public setTransactionDate(val: moment.Moment) {
    this.transaction.setTransactionDate(val)
  }

  public get colors() {
    return SelectableColors.colors
  }

  @computed
  public get categoryGuid(): string {
    return this.transaction.CategoryGuid
  }

  @action
  public setCategory(val: string) {
    this.transaction.setCategory(val)
  }

  @computed
  public get categories(): Category[] {
    return this.rootStore.categoriesStore.currentBoardRecords.sort((a, b) => (a.Name < b.Name ? -1 : 0))
  }
}
