import { observable, action, computed, makeAutoObservable, makeObservable } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { Category } from '../aggregate/Category'
import { CategoryEditVM } from '../view-models/CategoryEditVM'
import { CategoriesListVM } from '../view-models/CategoriesListVM'
import { ICategoryDTO } from '../dtos/ICategoryDTO'
import { DataStore } from '@elexient/elexiapp.bits.shared'
import AgentV2 from '../../AgentV2'
import { makePersistable } from 'mobx-persist-store'
import { deserialize } from 'serializr'

export class CategoriesStore extends DataStore<RootStore, Category, ICategoryDTO> {
  protected worker: any

  constructor(rootStore: RootStore) {
    super(rootStore, Category, AgentV2, 'Categories', 'Category')
    super.setRecords(this.persistedRecords)
    makeObservable(this)
    makePersistable(this, { name: 'CategoriesStore', properties: ['persistedRecords'] }).then(
      action((st) => this.onHydrationCompleted(st?.isHydrated))
    )
  }

  @action
  public onHydrationCompleted(isHydrated?: boolean) {
    this.persistedRecords = observable.array(this.persistedRecords.map((e) => makeObservable(deserialize(Category, e))))
    super.setRecords(this.persistedRecords)
    this.isHydrated = Boolean(isHydrated)
    if (process.env.NODE_ENV === 'test') this.isHydrated = true
    this.listVM = new CategoriesListVM(this.rootStore as RootStore)
  }

  @observable public persistedRecords: Array<Category> = []
  @observable public editVM: CategoryEditVM = null
  @observable public listVM: CategoriesListVM = null
  @observable public isHydrated: boolean = false

  public async loadData() {
    await super.loadData()
  }

  public onLoadDataCompleted(): void {
    if (!this.listVM) return
    this.listVM.reloadRows()
  }

  @action
  public lazyLoadEditVM(categoryGuid: string, newType: number = undefined, attempts: number = 0) {
    if (attempts === 10) return
    if (categoryGuid === 'new') {
      this.editVM = new CategoryEditVM(
        this.rootStore,
        Category.create(this.rootStore.boardsStore.currentBoardId),
        newType
      )
    } else if (categoryGuid === 'empty') {
      this.editVM = null
    } else {
      const foundCat = this.get(categoryGuid)
      if (!foundCat) setTimeout(() => this.lazyLoadEditVM(categoryGuid, newType, attempts++), 500)
      else this.editVM = new CategoryEditVM(this.rootStore, foundCat.clone())
    }
  }

  @computed
  public get currentBoardRecords() {
    if (!this.isHydrated) return []
    return this.persistedRecords.filter((e) => e.BoardId === this.rootStore.boardsStore.currentBoardId)
  }
}
