import { injectable } from 'tsyringe'
import { RootStore } from '../../stores/RootStore'
import { Transaction } from '../aggregate/Transaction'

@injectable()
export class TransactionsQueryService {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  public getEarliestClearedTransaction(accountGuid: string): Transaction {
    const thisAccountTrans = this.rootStore.transactionsStore.persistedRecords
      .filter((e) => e.AccountGuid === accountGuid)
      .sort((a, b) => (a.TransactionDate < b.TransactionDate ? -1 : 0))
    if (thisAccountTrans.length) return thisAccountTrans[0]
  }

  public getLastUpdatedTransaction(accountGuid: string): Transaction {
    const thisAccountTrans = this.rootStore.transactionsStore.persistedRecords
      .filter((e) => e.AccountGuid === accountGuid)
      .sort((a, b) => (a.LastUpdatedDateTime > b.LastUpdatedDateTime ? -1 : 0))
    if (thisAccountTrans.length) return thisAccountTrans[0]
  }
}
