import { action, computed, makeObservable, observable } from 'mobx'
import { RootStore } from 'src/stores/RootStore'
import { Board } from '../aggregate/Board'
import { BoardsService } from '../service/BoardsService'
import { Plugins } from '@capacitor/core'

export class BoardEditVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    makeObservable(this)
    this.rootStore = rootStore
  }

  @computed
  public get board(): Board {
    return this.rootStore.boardsStore.currentBoard
  }

  @observable public deleteConfirmShown: boolean = false

  @computed
  public get name(): string {
    if (!this.board) return ''
    return this.board.Name
  }

  @computed
  public get isValid(): boolean {
    if (!this.name || this.name === '') return false
    return true
  }

  @computed
  public get hasInvitationCode(): boolean {
    return Boolean(this.board.InvitationCode)
  }

  @computed
  public get invitationCode(): string {
    return this.board.InvitationCode
  }

  @action
  public openJoinBoardModal() {
    this.rootStore.boardsStore.joinVM.toggle()
  }

  @computed
  public get boardUsers() {
    const arr = this.board.BoardUsers
    // arr.push({ Name: 'Suzie Johnson' })
    // arr.push({ Name: 'James Doe' })
    // arr.push({ Name: 'Timothy Dalton' })
    // arr.push({ Name: 'James Bond' })
    // arr.push({ Name: 'Rina Hectoro' })
    // arr.push({ Name: 'Greg Goad' })
    // arr.push({ Name: 'Amy DeLaughter' })
    // arr.push({ Name: 'Zebra Laughter' })
    return arr
  }

  @action
  public async copyInvitationCode() {
    const { Clipboard, Toast } = Plugins
    await Clipboard.write({
      string: this.invitationCode.toString(),
    })
    await Toast.show({
      text: 'Copied to clipboard',
    })
  }

  @action
  public async generateInvitationCode() {
    if (window.Offline.state === 'down') {
      alert('Internet connection not found. Please try again later')
      return
    }
    const svc = new BoardsService(this.rootStore)
    await svc.generateInvitationCodeOnServer(this.board.toDTO())
  }

  @computed
  public get pendingBoardInvitations() {
    return this.board.BoardInvitations.filter(e => e.StatusId === 1)
  }

  @action
  public openInviteModal() {
    this.rootStore.boardInvitationsStore.loadNewVM()
  }

  @action
  public setName(val) {
    this.board.setName(val)
  }

  @action
  public save() {
    const svc = new BoardsService(this.rootStore)
    svc.saveBoard(this.board.toDTO())
  }

  @action
  public showDeleteConfirm() {
    this.deleteConfirmShown = true
  }

  @action
  public hideDeleteConfirm() {
    this.deleteConfirmShown = false
  }

  @computed
  public get canDeleteBoard() {
    return this.rootStore.boardsStore.persistedRecords.length > 1
  }

  @action
  public delete() {
    const svc = new BoardsService(this.rootStore)
    this.board.markAsDeleted()
    svc.saveBoard(this.board.toDTO())
    const str = this.rootStore.boardsStore
    str.setCurrentBoard(str.persistedRecords.find(e => e.BoardId !== this.rootStore.boardsStore.currentBoardId).BoardId)
    this.rootStore.appStore.handleGoBack()
  }
}
