import React from 'react'
import {
  IonContent,
  IonIcon,
  IonButton,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonFab,
  IonFabButton,
  IonFabList,
} from '@ionic/react'
import { observer, inject } from 'mobx-react'
import { AppStore } from 'src/stores/AppStore'
import { CategoriesStore } from 'src/categories/store/CategoriesStore'
import { add, enterOutline, exitOutline } from 'ionicons/icons'
import { RootStore } from 'src/stores/RootStore'
import CategoriesList from './CategoriesList'
import BackIcon from 'src/shared/BackIcon'
import { CategoryGroupEnum } from '../../enums/CategoryGroupEnum'

interface Props {
  categoriesStore: CategoriesStore
  appStore: AppStore
  rootStore: RootStore
  history: any
}

interface State {
  isNewModalOpen: boolean
  isEditModalOpen: boolean
  editCategoryGuid: string
  isDeleteConfirmShown: boolean
  deleteCategoryGuid: string
  deleteCategoryName: string
}

@inject('categoriesStore')
@inject('appStore')
@observer
export default class CategoriesListPage extends React.Component<Props, State> {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.props.appStore.setCurrentRoute('/categorieslist')
  }

  handleGoBack() {
    this.props.appStore.handleGoBack(this.props.history)
  }

  renderNoItemsMessage() {
    if (this.props.categoriesStore.currentBoardRecords.length >= 1) return null
    return (
      <div className='no-items'>
        You have no categories set up!
        <br />
        Add some categories to start your Grocery Board journey...
        <br />
        <br />
        <IonButton slot='icon-only' type='button' onClick={() => this.props.appStore.navigateTo('/categoryedit/new')}>
          <IonIcon icon={add} />
        </IonButton>
      </div>
    )
  }

  addCategory(newType: CategoryGroupEnum) {
    const listVM = this.props.categoriesStore.listVM
    if (!listVM) return
    listVM.addCategory(newType)
  }

  renderFab() {
    return (
      <IonFab vertical='bottom' horizontal='end'>
        <IonFabButton color='tertiary' className='reminder-fab-button'>
          <IonIcon icon={add} />
        </IonFabButton>
        <IonFabList side='top'>
          <IonFabButton
            color='tertiary'
            className='category-small-fab-button small'
            onClick={() => this.addCategory(CategoryGroupEnum.Personal_Income)}
          >
            <IonIcon icon={exitOutline} />
            &nbsp; Personal Income
          </IonFabButton>
          <IonFabButton
            color='tertiary'
            className='category-small-fab-button small'
            onClick={() => this.addCategory(CategoryGroupEnum.Personal_Expense)}
          >
            <IonIcon icon={enterOutline} />
            &nbsp; Personal Expense
          </IonFabButton>
          <IonFabButton
            color='tertiary'
            className='category-small-fab-button small'
            onClick={() => this.addCategory(CategoryGroupEnum.Business_Income)}
          >
            <IonIcon icon={enterOutline} />
            &nbsp; Business Income
          </IonFabButton>
          <IonFabButton
            color='tertiary'
            className='category-small-fab-button small'
            onClick={() => this.addCategory(CategoryGroupEnum.Business_Expense)}
          >
            <IonIcon icon={exitOutline} />
            &nbsp; Business Expense
          </IonFabButton>
        </IonFabList>
      </IonFab>
    )
  }

  render() {
    return (
      <>
        {this.props.appStore.showAppHeader && (
          <IonHeader>
            <IonToolbar />
          </IonHeader>
        )}

        <IonHeader id='CategoriesListPage_Header'>
          <IonToolbar color='secondary'>
            <IonButtons slot='start'>
              <IonButton onClick={() => this.handleGoBack()}>
                <BackIcon />
              </IonButton>
            </IonButtons>
            <IonTitle>Categories</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent
          id='CategoriesListPage_Content'
          scrollY={false}
          ref={(e) => this.props.appStore.applyScrollStyles(e)}
          style={{ height: this.props.appStore.listHeightPx }}
        >
          {this.renderNoItemsMessage()}
          <CategoriesList />
          {this.renderFab()}
        </IonContent>
      </>
    )
  }
}
