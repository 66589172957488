import React from 'react'
import { inject, Observer, observer } from 'mobx-react'
import { TransactionsStore } from '../../store/TransactionsStore'
import {
  IonBadge,
  IonButton,
  IonIcon,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonSpinner,
  IonText,
} from '@ionic/react'
import { List } from '@elexient/elexiapp.bits.shared'
import { TransactionGroupRowVM } from '../../view-models/TransactionGroupRowVM'
import { chevronDown, chevronForward, refresh } from 'ionicons/icons'
import { TransactionRowVM } from '../../view-models/TransactionRowVM'
import { TransactionLastRowVM } from '../../view-models/TransactionLastRowVM'
import { AppStore } from '../../../stores/AppStore'

interface Props {
  transactionsStore?: TransactionsStore
  appStore?: AppStore
}

const TransactionsList: React.FC<Props> = ({ transactionsStore, appStore }) => {
  const listVM = transactionsStore.listVM
  if (!listVM) return null

  const renderSkipOption = (row: TransactionRowVM) => {
    if (!row.skippable) return
    return (
      <IonItemOption
        onClick={(e) => {
          e.stopPropagation()
          row.skip()
        }}
      >
        <IonText>Skip</IonText>
        &nbsp;
      </IonItemOption>
    )
  }

  const renderPaidOption = (row: TransactionRowVM) => {
    if (!row.payable) return
    return (
      <IonItemOption
        color='success'
        onClick={(e) => {
          e.stopPropagation()
          row.paid()
        }}
      >
        <IonText>Paid</IonText>
        &nbsp;
      </IonItemOption>
    )
  }

  const renderClearedOption = (row: TransactionRowVM) => {
    if (!row.clearable) return
    return (
      <IonItemOption
        color='success'
        onClick={(e) => {
          e.stopPropagation()
          row.cleared()
        }}
      >
        <IonText>Cleared</IonText>
        &nbsp;
      </IonItemOption>
    )
  }

  const renderImportOption = (row: TransactionRowVM) => {
    if (!row.importable) return
    return (
      <IonItemOption
        color='success'
        onClick={(e) => {
          e.stopPropagation()
          row.import()
        }}
      >
        <IonText>Import</IonText>
        &nbsp;
      </IonItemOption>
    )
  }

  const renderCategories = (row: TransactionRowVM) => {
    return row.categoryBadges.map((e) => (
      <IonBadge color='dark' style={{ backgroundColor: e.color }}>
        {e.name}
      </IonBadge>
    ))
  }

  const renderAutoPaid = (row: TransactionRowVM) => {
    if (!row.autoPaid) return
    return (
      <div className='auto-paid'>
        <IonIcon icon={refresh} size='small' />
      </div>
    )
  }

  const renderNormalRow = (row: TransactionRowVM, index, style) => {
    return (
      <Observer key={row.key + String(index) + row.rowType + row.amountFormatted}>
        {() => (
          <IonItemSliding style={style} onClick={() => row.click()} key={row.key}>
            <IonItem lines='none' className={row.isEven ? 'normal-row even' : 'normal-row'}>
              <div className='date'>
                <div>
                  <div>{row.monthAbbr}</div>
                  <div>{row.day}</div>
                </div>
              </div>
              <div className='payee'>
                {row.payeeName}
                <div className={row.hasNotes ? 'tran-notes' : 'tran-notes tran-no-notes'}>{row.notes}</div>
              </div>
              <div className='categories'>{renderCategories(row)}</div>
              {renderAutoPaid(row)}
              <IonText className='numbers'>
                <div className={row.isPositive ? 'positive' : 'negative'}>{row.amountFormatted}</div>
              </IonText>
            </IonItem>
            <IonItemOptions side='end'>
              {renderSkipOption(row)}
              {renderPaidOption(row)}
              {renderClearedOption(row)}
              {renderImportOption(row)}
            </IonItemOptions>
          </IonItemSliding>
        )}
      </Observer>
    )
  }

  const renderRightIcon = (row: TransactionGroupRowVM) => {
    if (!row.isCollapsed) return
    return <IonIcon icon={chevronForward} />
  }

  const renderDownIcon = (row: TransactionGroupRowVM) => {
    if (row.isCollapsed) return
    return <IonIcon icon={chevronDown} />
  }

  const renderBalance = (row: TransactionGroupRowVM) => {
    if (row.displayName === 'Inbox') return
    return (
      <IonText className={row.isPositive ? 'right-text positive' : 'right-text negative'}>
        {row.balanceFormatted}
      </IonText>
    )
  }

  const renderGroupRow = (row: TransactionGroupRowVM, index, style) => {
    return (
      <Observer key={row.key + String(index) + row.rowType + row.isCollapsed + row.isInvView}>
        {() => (
          <div style={style} className={row.rowType} onClick={() => row.click()}>
            <div className='main-cell'>
              {row.displayName}
              {renderBalance(row)}
            </div>
            {renderRightIcon(row)}
            {renderDownIcon(row)}
          </div>
        )}
      </Observer>
    )
  }

  const renderLastRow = (row: TransactionLastRowVM, index, style) => {
    return (
      <Observer key={row.key + String(index) + row.rowType + row.name + row.hasMorePages + listVM.isProcessing}>
        {() => {
          if (!row.hasMorePages && !listVM.isProcessing) return null
          return (
            <div style={style} className='last-row' onClick={() => row.loadNextPage()}>
              {renderLoadMoreButton(row)}
              {renderSpinner(row)}
            </div>
          )
        }}
      </Observer>
    )
  }

  const renderLoadMoreButton = (row) => {
    if (!row.hasMorePages) return null
    if (listVM.isProcessing) return null
    return <IonText>Load More</IonText>
  }

  const renderSpinner = (row: TransactionLastRowVM) => {
    if (!listVM.isProcessing) return
    return <div id='spinner-container'>{renderActualSpinner(row)}</div>
  }

  const renderActualSpinner = (row: TransactionLastRowVM) => {
    if (row.isIos) return <IonSpinner />
    return <IonSpinner name='crescent' />
  }

  return (
    <List
      key={listVM.renderVersion}
      listVM={listVM}
      className='list'
      normalRow={(row, index, style) => renderNormalRow(row, index, style)}
      groupRow={(row, index, style) => renderGroupRow(row, index, style)}
      newRow={(row, index, style) => renderLastRow(row, index, style)}
      onRef={(e) => appStore.applyScrollStyles(e)}
    />
  )
}

export default inject('transactionsStore', 'appStore')(observer(TransactionsList))
