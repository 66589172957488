import React, { useState } from 'react'
import { inject, Observer, observer } from 'mobx-react'
import { AccountsStore } from '../../store/AccountsStore'
import { IonBadge, IonIcon, IonList, IonText } from '@ionic/react'
import { List } from '@elexient/elexiapp.bits.shared'
import { AccountGroupRowVM } from '../../view-models/AccountGroupRowVM'
import { personCircleOutline, chevronDown, chevronForward, warning } from 'ionicons/icons'
import { AccountRowVM } from '../../view-models/AccountRowVM'
import { AppStore } from '../../../stores/AppStore'

interface Props {
  accountsStore?: AccountsStore
  appStore?: AppStore
}

const AccountsList: React.FC<Props> = ({ accountsStore, appStore }) => {
  const listVM = accountsStore.listVM
  if (!listVM) return null

  const renderNormalRow = (row: AccountRowVM, index, style) => {
    const style2 = {
      borderLeftColor: row.color,
      ...style,
    }

    const renderNotes = () => {
      return <div className={row.hasNotes ? 'notes' : 'notes no-notes'}>{row.notes}</div>
    }

    const renderInboxCount = () => {
      if (!row.hasInboxTransactions) return
      return <IonBadge color='dark'>{row.inboxTransactions}</IonBadge>
    }

    const renderPlaidItemNeedsUpdating = () => {
      if (!row.plaidItemNeedsUpdating) return
      return <IonIcon color='warning' icon={warning} size='small' />
    }

    return (
      <Observer key={row.key + String(index) + row.rowType}>
        {() => (
          <div style={style2} key={row.key} className='normal-row' onClick={() => row.click()}>
            <div className={row.hasInboxTransactions ? 'main-cell unread' : 'main-cell'}>
              {row.name}
              {renderInboxCount()}
              {renderPlaidItemNeedsUpdating()}
              {renderNotes()}
            </div>
            <IonText className={row.isPositive ? 'positive' : 'negative'} color='light'>
              {row.balance}
            </IonText>
          </div>
        )}
      </Observer>
    )
  }

  const renderRightIcon = (row: AccountGroupRowVM) => {
    if (!row.isCollapsed) return
    return <IonIcon icon={chevronForward} />
  }

  const renderDownIcon = (row: AccountGroupRowVM) => {
    if (row.isCollapsed) return
    return <IonIcon icon={chevronDown} />
  }

  const renderBalance = (row: AccountGroupRowVM) => {
    return (
      <IonText className={row.isPositive ? 'right-text positive' : 'right-text negative'}>{row.balanceString}</IonText>
    )
  }

  const renderGroupRow = (row: AccountGroupRowVM, index, style) => {
    return (
      <Observer key={row.key + String(index) + row.rowType + row.isCollapsed + row.isInvView}>
        {() => (
          <div style={style} className={row.rowType} onClick={() => row.click()}>
            <div className='main-cell'>
              {row.displayName}
              {renderBalance(row)}
            </div>
            {renderRightIcon(row)}
            {renderDownIcon(row)}
          </div>
        )}
      </Observer>
    )
  }

  return (
    <List
      listVM={listVM}
      className='list'
      normalRow={(row, index, style) => renderNormalRow(row, index, style)}
      groupRow={(row, index, style) => renderGroupRow(row, index, style)}
      onRef={(e) => appStore.applyScrollStyles(e)}
    />
  )
}

export default inject('accountsStore', 'appStore')(observer(AccountsList))
