import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { RootStore } from '../../../stores/RootStore'
import { BankTransaction } from '../../aggregate/BankTransaction'
import { BankTransactionsService } from '../../services/BankTransactionsService'
import moment from 'moment'
import AgentV2 from '../../../AgentV2'
import { OptionRowVM } from './OptionRowVM'
import Sugar from 'sugar'

export class BankTransactionEditVM {
  private rootStore: RootStore
  private bankTransaction: BankTransaction

  constructor(rootStore: RootStore, bankTransaction: BankTransaction) {
    makeObservable(this)
    this.rootStore = rootStore
    this.bankTransaction = bankTransaction.clone()
    this.addNewTransOption()
    this.addIgnoreOption()
    this.loadMatches()
  }

  @observable public options: OptionRowVM[] = []

  @action
  private addNewTransOption() {
    this.options.push(new OptionRowVM(this.rootStore, this, 'add-new'))
  }

  @action
  private addIgnoreOption() {
    this.options.push(new OptionRowVM(this.rootStore, this, 'skip'))
  }

  @action
  public async loadMatches() {
    const response = await AgentV2.BankTransactions.matches(this.bankTransaction.BankTransactionGuid)
    runInAction(() =>
      response.Matches.forEach((e) => this.options.push(new OptionRowVM(this.rootStore, this, 'trans', e)))
    )
  }

  @computed
  public get pageTitle(): string {
    return this.bankTransaction.PayeeName
  }

  @computed
  public get amountFormatted(): string {
    if (this.bankTransaction.Amount === 0) return undefined
    let str = Sugar.Number(Math.abs(this.bankTransaction.Amount)).format(2).raw
    if (this.bankTransaction.isPositive) str = '+' + str
    return str
  }

  @action
  public save(openEdit: boolean = false) {
    if (this.selectedOption.type === 'add-new') {
      this.goToNewTransaction()
      return
    }
    if (this.selectedOption.type === 'skip') {
      this.skipTrans()
      return
    }
    this.saveTrans()
    if (openEdit) {
      this.rootStore.appStore.navigateTo('/expenseedit/' + this.accountGuid + '/' + this.selectedOption.transactionGuid)
      return
    }
    this.goBack()
  }

  private skipTrans() {
    this.bankTransaction.setSkipped()
    const svc = new BankTransactionsService(this.rootStore)
    svc.save(this.bankTransaction.toDTO())
    this.goBack()
  }

  private saveTrans() {
    this.bankTransaction.setTransaction(this.selectedOption.transactionGuid)
    const svc = new BankTransactionsService(this.rootStore)
    svc.save(this.bankTransaction.toDTO())
  }

  private goToNewTransaction() {
    if (this.bankTransaction.isPositive) {
      alert('not implemented')
      return
    }
    this.rootStore.appStore.navigateTo(
      '/expenseedit/' + this.accountGuid + '/fromBankTrans/' + this.bankTransaction.BankTransactionGuid
    )
  }

  @action
  public goBack() {
    this.rootStore.appStore.navigateTo('/transactionslist/' + this.accountGuid)
  }

  public get accountGuid(): string {
    return this.bankTransaction.AccountGuid
  }

  @computed
  public get transactionDate(): moment.Moment {
    return moment(this.bankTransaction.TransactionDate).clone()
  }

  public get payeeName(): string {
    return this.bankTransaction.PayeeName
  }

  @computed
  public get isValid(): boolean {
    return Boolean(this.selectedOption)
  }

  @action
  public clearAllChecked() {
    this.options.filter((e) => e.checked).forEach((e) => e.clearChecked())
  }

  @computed
  public get selectedOption(): OptionRowVM {
    return this.options.find((e) => e.checked)
  }

  public get dateFormatted(): string {
    let str = Sugar.Date(this.bankTransaction.TransactionDate).medium().raw
    return str
  }

  public get statusName(): string {
    if (this.bankTransaction.IsPending) return 'Pending'
    return 'Cleared'
  }

  public get longPayeeName(): string {
    return this.bankTransaction.LongPayeeName
  }

  @computed
  public get icon(): string {
    if (!this.selectedOption) return 'done'
    if (this.selectedOption.type === 'trans') return 'link'
    if (this.selectedOption.type === 'add-new') return 'next'
    if (this.selectedOption.type === 'skip') return 'done'
    return 'done'
  }

  @computed
  public get hasSelectedOption(): boolean {
    return Boolean(this.selectedOption)
  }

  @computed
  public get showEditButton(): boolean {
    if (!this.hasSelectedOption) return false
    if (this.selectedOption.type === 'trans') return true
    return false
  }
}
