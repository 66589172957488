import { IAggregate } from '@elexient/elexiapp.bits.shared/dist/data/IAggregate'
import { observable, makeObservable } from 'mobx'
import { serializable } from 'serializr'
import { IAccountDTO } from '../dtos/IAccountDTO'

export class Account implements IAccountDTO, IAggregate {
  constructor() {
    makeObservable(this)
  }

  @serializable @observable public AccountGuid: string
  @serializable @observable public AccountId: string
  @serializable @observable public Name: string
  @serializable @observable public Mask: string
  @serializable @observable public OfficialName: string
  @serializable @observable public Type: string
  @serializable @observable public SubType: string
  @serializable @observable public AvailableBalance: number
  @serializable @observable public CurrentBalance: number
  @serializable @observable public IsDeleted: boolean = false

  public toDTO(): IAccountDTO {
    return undefined
  }
}
