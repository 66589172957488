import { RootStore } from '../../stores/RootStore'
import { computed, action, observable } from 'mobx'
import { RowVM } from '@elexient/elexiapp.bits.shared'
import { TransactionsListVM } from './TransactionsListVM'
import { Transaction } from '../aggregate/Transaction'
import { TransactionStatusEnumName } from '../enum/TransactionStatusEnumName'
import Sugar from 'sugar'
import moment from 'moment'
import { TransactionsService } from '../services/TransactionsService'
import { TransactionTypeEnum } from '../enum/TransactionTypeEnum'
import { CategoryBadgeVM } from './CategoryBadgeVM'
import { TransactionStatusEnum } from '../enum/TransactionStatusEnum'
import { BankTransactionsService } from '../../bank-transactions/services/BankTransactionsService'

export class TransactionRowVM extends RowVM<RootStore, TransactionsListVM, Transaction> {
  constructor(rootStore: RootStore, listVM: TransactionsListVM, reminder: Transaction, self: any) {
    super(rootStore, listVM, reminder, self)
  }

  // @observable public runningTotal: number = 0

  public get height(): number {
    if (this.isHidden) return 0
    return 40
  }

  public get isEven(): boolean {
    return (this.normalRowIndex + 1) % 2 === 0
  }

  @computed
  public get keyword(): string {
    return this.payeeName
  }

  @computed
  public get payeeName(): string {
    return this.record.PayeeName
  }

  @computed
  public get key(): string {
    return this.record.TransactionGuid + String(this.record.Amount)
  }

  @computed
  public get color(): string {
    return this.record.Color
  }

  @action
  public click() {
    const isSlidOpen = document.getElementsByClassName('item-sliding-active-slide').length > 0
    if (isSlidOpen) return
    let url = '/transactionedit'
    if (this.isExpense) url = '/expenseedit'
    url = url + '/' + this.record.AccountGuid + '/' + this.record.TransactionGuid
    if (this.isInInbox) url = '/banktransactionedit/' + this.record.TransactionGuid
    console.log(url)
    this.rootStore.appStore.navigateTo(url)
  }

  @computed
  public get isInInbox() {
    return this.statusName === 'Inbox'
  }

  @computed
  public get hasNotes(): boolean {
    return Boolean(this.record.Notes)
  }

  @computed
  public get notes(): string {
    if (!this.hasNotes) return 'No Notes'
    return this.record.Notes
  }

  public get statusRank(): string {
    if (this.record.TransactionStatusId === TransactionStatusEnum.Inbox) return '0Inbox'
    return this.record.TransactionStatusId + TransactionStatusEnumName.fromId(this.record.TransactionStatusId)
  }

  public get statusName(): string {
    return TransactionStatusEnumName.fromId(this.record.TransactionStatusId)
  }

  @computed
  public get day(): string {
    if (!this.record.TransactionDate) return ''
    const day = this.transactionDate.clone().format('D')
    return day
  }

  @computed
  public get monthAbbr(): string {
    let str = Sugar.Date(this.transactionDate.toDate()).format('%b').raw
    return str
  }

  @computed
  public get isPositive(): boolean {
    return this.record.isPositive
  }

  @computed
  public get amount(): number {
    return this.record.Amount
  }

  private get isExpense(): boolean {
    return this.record.TransactionTypeId === TransactionTypeEnum.Expense
  }

  @computed
  public get amountFormatted(): string {
    let str = Sugar.Number(this.record.Amount).format(2).raw
    if (this.record.isPositive) str = '+' + str
    return str
  }

  // @action
  // public setRunningTotal(val: number) {
  //   this.runningTotal = val
  // }

  // @computed
  // public get runningTotalIsNegative(): boolean {
  //   return Boolean(this.runningTotal < 0)
  // }

  // @computed
  // public get runningTotalFormatted(): string {
  //   let str = Sugar.Number(this.runningTotal).format(2).raw
  //   return str
  // }

  @computed
  public get transactionDate(): moment.Moment {
    return moment(this.record.TransactionDate, moment.ISO_8601)
  }

  public skip() {
    if (this.isInInbox) {
      const bankTrans = this.rootStore.bankTransactionsStore.get(this.record.TransactionGuid)
      bankTrans.setSkipped()
      const svc = new BankTransactionsService(this.rootStore)
      svc.save(bankTrans.toDTO())
      return
    }
    this.record.markAsSkipped()
    const svc = new TransactionsService(this.rootStore)
    svc.save(this.record.toDTO())
  }

  public import() {
    this.rootStore.appStore.navigateTo(
      '/expenseedit/' + this.record.AccountGuid + '/fromBankTrans/' + this.record.TransactionGuid
    )
  }

  public paid() {
    if (this.record.isUpcoming) this.record.markAsPending()
    else this.record.markAsCleared()
    const svc = new TransactionsService(this.rootStore)
    svc.save(this.record.toDTO())
  }

  public cleared() {
    this.record.markAsCleared()
    const svc = new TransactionsService(this.rootStore)
    svc.save(this.record.toDTO())
  }

  @computed
  public get skippable() {
    return this.record.skippable
  }

  @computed
  public get payable() {
    return this.record.payable
  }

  @computed
  public get autoPaid(): boolean {
    const remGuid = this.record.ReminderGuid
    if (!this.record.ReminderGuid) return false
    const reminder = this.rootStore.remindersStore.get(remGuid)
    if (!reminder) return false
    return reminder.AutoPaid
  }

  @computed
  public get clearable() {
    return this.record.clearable
  }

  @computed
  public get importable() {
    return this.record.importable
  }

  @computed
  public get categoryBadges(): CategoryBadgeVM[] {
    if (this.record.TransactionTypeId === TransactionTypeEnum.Balance_Adjustment) {
      return [new CategoryBadgeVM(this.rootStore, 'other', undefined, 'Balance Adjustment')]
    }
    if (this.record.TransactionTypeId === TransactionTypeEnum.Transfer_In) {
      return [new CategoryBadgeVM(this.rootStore, 'transfer', undefined, undefined, this.record.TransferAccountGuid)]
    }
    if (this.record.TransactionTypeId === TransactionTypeEnum.Transfer_Out) {
      return [new CategoryBadgeVM(this.rootStore, 'transfer', undefined, undefined, this.record.TransferAccountGuid)]
    }
    return this.record.CategorySplits.map(
      (e) => new CategoryBadgeVM(this.rootStore, 'category', e.CategoryGuid)
    ).filter((e) => Boolean(e.name))
  }

  @computed
  public get transactionStatusId(): TransactionStatusEnum {
    return this.record.TransactionStatusId
  }
}
