import React from 'react'
import { inject, observer } from 'mobx-react'
import { AppStore } from '../../../stores/AppStore'
import {
  IonItem,
  IonLabel,
  IonInput,
  IonSelect,
  IonSelectOption,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonIcon,
} from '@ionic/react'
import { TransactionsStore } from '../../store/TransactionsStore'
import { DatePicker } from '@material-ui/pickers'
import { CCPaymentEditVM } from '../../view-models/CCPaymentEditVM'

interface Props {
  transactionsStore?: TransactionsStore
  appStore?: AppStore
}

const CCPaymentEditForm: React.FC<Props> = ({ transactionsStore, appStore }) => {
  const editVM = transactionsStore.editVM as CCPaymentEditVM
  if (!editVM) return null
  if (!editVM.accounts) return null

  const renderNotes = () => {
    return (
      <IonItem lines={'inset'}>
        <IonLabel position='floating'>Notes</IonLabel>
        <IonInput type='text' value={editVM.notes} onIonChange={(e: any) => editVM.setNotes(e.target.value)} />
      </IonItem>
    )
  }

  const renderAmount = () => {
    return (
      <IonItem lines={'inset'}>
        <IonLabel position='floating'>Amount</IonLabel>
        <IonInput
          type='number'
          value={editVM.amountAsString}
          onIonChange={(e: any) => editVM.setAmount(e.target.value)}
        />
      </IonItem>
    )
  }

  const renderFromAccount = () => {
    return (
      <IonItem lines={'inset'}>
        <IonLabel position='floating'>From Account</IonLabel>
        <IonSelect
          interface='popover'
          value={editVM.accountGuid}
          onIonChange={(e: any) => editVM.setAccount(e.target.value)}
        >
          {editVM.accounts.map((e) => (
            <IonSelectOption key={e.AccountGuid} value={e.AccountGuid}>
              {e.Name}
            </IonSelectOption>
          ))}
        </IonSelect>
      </IonItem>
    )
  }

  const renderCCAccount = () => {
    return (
      <IonItem lines={'inset'}>
        <IonLabel position='floating'>Credit Card</IonLabel>
        <IonSelect
          interface='popover'
          value={editVM.ccPaymentAccountGuid}
          onIonChange={(e: any) => editVM.setCCPaymentAccount(e.target.value)}
        >
          {editVM.ccAccounts.map((e) => (
            <IonSelectOption key={e.AccountGuid} value={e.AccountGuid}>
              {e.Name}
            </IonSelectOption>
          ))}
        </IonSelect>
      </IonItem>
    )
  }

  const renderDate = () => {
    return (
      <IonItem lines={'inset'} unselectable='on'>
        <IonLabel position='floating' className='forced-floating'>
          Date
        </IonLabel>
        <DatePicker
          unselectable='on'
          value={editVM.transactionDate}
          onChange={(e) => editVM.setTransactionDate(e)}
          format='M/D/YYYY'
        />
      </IonItem>
    )
  }

  return (
    <form
      id='DetailsForm'
      onSubmit={(e) => {
        e.preventDefault()
        editVM.save()
      }}
    >
      <IonGrid>
        <IonRow>
          <IonCol sizeXs='12'>{renderCCAccount()}</IonCol>
        </IonRow>
        <IonRow>
          <IonCol sizeXs='12'>{renderFromAccount()}</IonCol>
        </IonRow>
        <IonRow>
          <IonCol sizeMd='6' sizeXs='6'>
            {renderDate()}
          </IonCol>
          <IonCol sizeMd='6' sizeXs='6'>
            {renderAmount()}
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol sizeXs='12'>{renderNotes()}</IonCol>
        </IonRow>
      </IonGrid>
    </form>
  )
}

export default inject('transactionsStore', 'appStore')(observer(CCPaymentEditForm))
