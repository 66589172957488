import { DateRangeEnum } from './DateRangeEnum'

export class DateRangeEnumName {
  public static fromId(id: number): string {
    if (!id) return '(Not Set)'
    if (id === 0) return '(Not Set)'
    return this.list.find((e) => e.id == id).name
  }

  public static get list(): { id: number; name: string }[] {
    return Object.values(DateRangeEnum)
      .map((e, idx) => {
        return { id: idx + 1, name: String(e).replace(/_/g, ' ') }
      })
      .splice(0, Object.values(DateRangeEnum).length / 2)
  }
}
