import React from 'react'
import { observer, inject } from 'mobx-react'
import { AppStore } from '../../../stores/AppStore'
import { RootStore } from '../../../stores/RootStore'
import { match } from 'react-router'
import { TransactionsStore } from '../../store/TransactionsStore'
import ExpenseEditArea from './ExpenseEditArea'
import { NewTransactionTypeEnum } from '../../enum/NewTransactionTypeEnum'

interface Props {
  transactionsStore: TransactionsStore
  appStore: AppStore
  rootStore: RootStore
  history: any
  match: match
}

@inject('transactionsStore')
@inject('appStore')
@observer
export default class ExpenseEditPage extends React.Component<Props> {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    const accountGuid = this.props.match.params['accountGuid']
    const transactionGuid = this.props.match.params['transactionGuid']
    const bankTransGuid = this.props.match.params['bankTransGuid']
    this.props.appStore.setCurrentRoute(this.props.match.url)
    this.props.transactionsStore.lazyLoadEditVM(
      accountGuid,
      transactionGuid,
      NewTransactionTypeEnum.Expense,
      bankTransGuid
    )
  }

  componentWillUnmount() {
    this.props.transactionsStore.lazyLoadEditVM('empty', 'empty')
  }

  render() {
    return <ExpenseEditArea />
  }
}
