import { observable, action, computed, makeObservable } from 'mobx'
import { RootStore } from 'src/stores/RootStore'
import agent from '../../Agent'
import { User } from '../aggregate/User'
import { Plugins, Capacitor, registerWebPlugin } from '@capacitor/core'
import { SignInWithApple, SignInWithAppleOptions, SignInWithAppleResponse } from '@capacitor-community/apple-sign-in'
import { FirebaseService } from '../../firebase/FirebaseService'

export class UserEditVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore, user: User) {
    makeObservable(this)
    this.rootStore = rootStore
    this.Name = user.Name
    this.EmailAddress = user.EmailAddress
    this.WebNotificationsEnabled = user.WebNotificationsEnabled
  }

  @observable public Name: string = ''
  @observable public EmailAddress: string = ''
  @observable public WebNotificationsEnabled: boolean = false

  @computed
  public get googleEmail() {
    return this.rootStore.userStore.user.GoogleEmailAddress
  }

  @computed
  public get appleEmail() {
    return this.rootStore.userStore.user.AppleEmailAddress
  }

  @computed
  public get hasGoogleLogin() {
    if (!this.rootStore.userStore.user) return
    return this.rootStore.userStore.user.HasGoogleLogin
  }

  @computed
  public get hasAppleLogin() {
    if (!this.rootStore.userStore.user) return
    return this.rootStore.userStore.user.HasAppleLogin
  }

  @computed
  public get isValid(): boolean {
    if (this.Name === '') return false
    if (this.EmailAddress === '') return false
    return true
  }

  @action
  public toggleWebNotifications() {
    this.WebNotificationsEnabled = !this.WebNotificationsEnabled
  }

  @action
  public async save() {
    const form = {
      Name: this.Name,
      EmailAddress: this.EmailAddress,
      WebNotificationsEnabled: this.WebNotificationsEnabled,
      FirebaseMessagingToken: undefined,
    }
    if (this.WebNotificationsEnabled) {
      try {
        const svc = new FirebaseService(this.rootStore)
        const token = await svc.getMessagingToken()
        if (token) form.FirebaseMessagingToken = token
        else {
          alert('Unable to enable notifications. Please try again.')
          form.WebNotificationsEnabled = false
        }
      } catch (e) {
        console.error(e)
        throw e
      }
    }
    this.rootStore.userStore.user.setName(this.Name)
    this.rootStore.userStore.user.setEmailAddress(this.EmailAddress)
    this.rootStore.userStore.user.setWebNotificationsEnabled(this.WebNotificationsEnabled)
    await this.saveOnServer(form)
    this.rootStore.appStore.navigateTo('/')
  }

  public async saveOnServer(form, isRetry = false) {
    try {
      await agent.Users.save(form)
    } catch (e) {
      if (isRetry) return false
      this.rootStore.actionsStore.addAction(0, form)
    }
    return true
  }

  @action
  public async connectGoogle() {
    const result = await Plugins.GoogleAuth.signIn()
    if (result.email) {
      var apiResult = await agent.Users.addGoogleLogin(result)
      alert(apiResult)
      await this.rootStore.userStore.loadUser()
    }
  }

  @computed
  public get isWebApp(): boolean {
    return this.rootStore.appStore.isWeb
  }

  @action
  public async connectApple() {
    registerWebPlugin(SignInWithApple as any)
    let options: SignInWithAppleOptions = {
      clientId: 'io.budgetboard.web',
      redirectURI: 'https://app.budgetboard.io/login',
      scopes: 'email name',
      state: '12345',
      nonce: 'nonce',
    }
    const result: SignInWithAppleResponse = await Plugins.SignInWithApple.authorize(options)
    if (result.response && result.response.identityToken) {
      var apiResult = await agent.Users.addAppleLogin(result.response)
      alert(apiResult)
      await this.rootStore.userStore.loadUser()
    }
  }
}
