import { observable, action, computed, makeObservable, runInAction } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { UpcomingBill } from '../aggregate/UpcomingBill'
import { IUpcomingBillDTO } from '../dtos/IUpcomingBillDTO'
import { DataStore } from '@elexient/elexiapp.bits.shared'
import AgentV2 from '../../AgentV2'
import { makePersistable } from 'mobx-persist-store'
import { deserialize } from 'serializr'

export class UpcomingBillsStore extends DataStore<RootStore, UpcomingBill, IUpcomingBillDTO> {
  protected worker: any

  constructor(rootStore: RootStore) {
    super(rootStore, UpcomingBill, AgentV2, 'UpcomingBills', 'UpcomingBill')
    makeObservable(this)
    makePersistable(this, { name: 'UpcomingBillsStore', properties: ['persistedRecords'] }).then(
      action((st) => this.onHydrationCompleted(st?.isHydrated))
    )
  }

  @action
  public onHydrationCompleted(isHydrated?: boolean) {
    this.persistedRecords = observable.array(
      this.persistedRecords.map((e) => makeObservable(deserialize(UpcomingBill, e)))
    )
    this.setRecords(this.persistedRecords)
    this.isHydrated = Boolean(isHydrated)
    if (process.env.NODE_ENV === 'test') this.isHydrated = true
  }

  @observable.shallow public persistedRecords: Array<UpcomingBill> = []
  @observable public isHydrated: boolean = false

  public onLoadDataCompleted(): void {}

  @computed
  public get currentBoardRecords() {
    if (!this.isHydrated) return []
    return this.persistedRecords.filter((e) => e.BoardId === this.rootStore.boardsStore.currentBoardId)
  }
}
