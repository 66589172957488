import { observable, action, computed, makeObservable } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { Transaction } from '../aggregate/Transaction'
import { TransactionsService } from '../services/TransactionsService'
import { SelectableColors } from '../../shared/SelectableColors'
import { Category } from '../../categories/aggregate/Category'
import { TransactionStatusEnumName } from '../enum/TransactionStatusEnumName'
import { TransactionTypeEnumName } from '../enum/TransactionTypeEnumName'
import { TransactionTypeEnum } from '../enum/TransactionTypeEnum'
import moment, { ISO_8601 } from 'moment'
import { TransactionStatusEnum } from '../enum/TransactionStatusEnum'
import { CategorySplitRowVM } from './CategorySplitRowVM'
import { BankTransaction } from '../../bank-transactions/aggregate/BankTransaction'

export class ExpenseEditVM {
  private rootStore: RootStore
  private transaction: Transaction

  constructor(
    rootStore: RootStore,
    transaction: Transaction,
    isNew: boolean = false,
    fromBankTrans: BankTransaction = undefined
  ) {
    makeObservable(this)
    this.rootStore = rootStore
    this.transaction = transaction.clone()
    this.isNew = isNew
    this.transaction.setTransactionType(TransactionTypeEnum.Expense)
    if (isNew) {
      this.transaction.setTransactionStatus(TransactionStatusEnum.Pending)
      this.processFromBankTrans(fromBankTrans)
    }
  }

  @observable public deleteConfirmShown: boolean = false
  @observable public splitMode: boolean = true
  public isNew: boolean = false
  public amountTouched: boolean

  @action
  public setPayeeName(val) {
    this.transaction.setPayeeName(val)
  }

  @computed
  public get payeeName(): string {
    return this.transaction.PayeeName
  }

  @computed
  public get pageTitle(): string {
    if (!this.isNew) return this.transaction.PayeeName
    return 'New Expense'
  }

  @computed
  public get amount(): number {
    return Math.abs(this.transaction.Amount)
  }

  @computed
  public get amountAsString(): string {
    if (this.amount === 0) return ''
    return this.amount.toString()
  }

  @computed
  public get categorySplitsTotal() {
    let total = 0
    this.transaction.CategorySplits.forEach((e) => (total += e.Amount))
    return total
  }

  @action
  public setAmountToCategorySplitsTotal() {
    this.setAmount(this.categorySplitsTotal)
  }

  @computed
  public get isValid(): boolean {
    if (!this.payeeName || this.payeeName === '') return false
    return true
  }

  @computed
  public get notes(): string {
    return this.transaction.Notes
  }

  @action
  public setNotes(val: string) {
    this.transaction.setNotes(val)
  }

  @action
  public setAmount(val: number) {
    this.transaction.setAmount(-Math.abs(val))
  }

  @action
  public setAmountTouched() {
    this.amountTouched = true
  }

  @computed
  public get categorySplitsTooHigh() {
    return this.categorySplitsTotal > this.amount
  }

  @action
  public save() {
    const svc = new TransactionsService(this.rootStore)
    svc.save(this.transaction.toDTO())
    // if (this.transaction.hasBankTransaction) {
    //   this.rootStore.appStore.navigateTo('/banktransactionslist')
    //   return
    // }
    this.rootStore.appStore.navigateTo('/transactionslist/' + this.transaction.AccountGuid)
  }

  @action
  public showDeleteConfirm() {
    this.deleteConfirmShown = true
  }

  @action
  public hideDeleteConfirm() {
    this.deleteConfirmShown = false
  }

  @action
  public goBack() {
    this.rootStore.appStore.navigateTo('/transactionslist/' + this.accountGuid)
  }

  public get accountGuid(): string {
    return this.transaction.AccountGuid
  }

  @computed
  public get color(): string {
    if (!this.transaction.Color) return ''
    return this.transaction.Color
  }

  @action
  public setColor(hex: string) {
    this.transaction.setColor(hex)
  }

  public delete() {
    const svc = new TransactionsService(this.rootStore)
    svc.delete(this.transaction.toDTO())
    this.rootStore.appStore.navigateTo('/transactionslist/' + this.accountGuid)
  }

  @computed
  public get transactionDate(): moment.Moment {
    return moment(this.transaction.TransactionDate).clone()
  }

  @action
  public setStatus(val: number) {
    this.transaction.setTransactionStatus(val)
  }

  @computed
  public get transactionStatusId(): number {
    return this.transaction.TransactionStatusId
  }

  @action
  public setTransactionType(val: number) {}

  @computed
  public get transactionTypeId(): number {
    return this.transaction.TransactionTypeId
  }

  @computed
  public get transactionTypeName(): string {
    return TransactionTypeEnumName.fromId(this.transactionTypeId)
  }

  public get boardId(): number {
    return this.transaction.BoardId
  }

  public get transactionTypes() {
    return TransactionTypeEnumName.list
  }

  public get statuses() {
    let list = TransactionStatusEnumName.list
    if (this.transactionStatusId !== TransactionStatusEnum.Skipped) {
      list = list.filter((e) => e.id !== TransactionStatusEnum.Skipped)
    }
    return list
  }

  @action
  public setTransactionDate(val: moment.Moment) {
    this.transaction.setTransactionDate(val)
  }

  @action
  public setBankTransaction(val: string) {
    this.transaction.setBankTransaction(val)
  }

  @action
  public enterSplitMode() {
    this.transaction.addCategorySplit()
  }

  public get colors() {
    return SelectableColors.colors
  }

  @computed
  public get categoryGuid(): string {
    return this.transaction.CategoryGuid
  }

  @action
  public setCategory(val: string) {
    this.transaction.setCategory(val)
  }

  @computed
  public get categories(): Category[] {
    return this.rootStore.categoriesStore.currentBoardRecords.sort((a, b) => (a.Name < b.Name ? -1 : 0))
  }

  @computed
  public get categorySplits(): CategorySplitRowVM[] {
    return this.transaction.CategorySplits.filter((e) => !e.IsDeleted).map(
      (e) => new CategorySplitRowVM(this.rootStore, this, e)
    )
  }

  @action
  public addCategorySplit() {
    this.transaction.addCategorySplit()
  }

  @action
  public processFromBankTrans(fromBankTrans: BankTransaction) {
    if (!fromBankTrans) return
    this.setPayeeName(fromBankTrans.PayeeName)
    this.setAmount(fromBankTrans.Amount)
    this.setTransactionDate(moment(fromBankTrans.TransactionDate, ISO_8601))
    if (!fromBankTrans.IsPending) this.setStatus(TransactionStatusEnum.Cleared)
    this.setBankTransaction(fromBankTrans.BankTransactionGuid)
  }
}
