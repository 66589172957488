import React from 'react'
import {
  IonContent,
  IonList,
  IonItem,
  IonInput,
  IonLabel,
  IonButton,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonIcon,
  IonTitle,
  IonItemDivider,
  IonAvatar,
  IonAlert,
} from '@ionic/react'
import { observer, inject } from 'mobx-react'
import { AppStore } from '../../stores/AppStore'
import { close, checkmarkSharp, trashOutline } from 'ionicons/icons'
import { BoardsStore } from '../../boards/store/BoardsStore'
import UserAvatar from 'react-user-avatar'
import './../styles/BoardSettingsPage.scss'
import BackIcon from '../../shared/BackIcon'
import AvatarColors from '../../theme/AvatarColors'
import BoardInvitationNewModal from '../../board-invitations/views/BoardInvitationNewModal'
import InvitationCodeRow from './InvitationCodeRow'
import JoinBoardRow from './JoinBoardRow'

interface Props {
  appStore: AppStore
  boardsStore: BoardsStore
  history: any
}

@inject('appStore')
@inject('boardsStore')
@observer
export default class BoardSettingsPage extends React.Component<Props> {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.props.appStore.setCurrentRoute('/boardsettings')
    this.props.boardsStore.lazyLoadEditVM()
  }

  handleGoBack() {
    this.props.appStore.handleGoBack(this.props.history)
  }

  handleSavePress(e) {
    e.preventDefault()
    this.props.boardsStore.editVM.save()
    this.handleGoBack()
  }

  renderNameRow() {
    const { editVM } = this.props.boardsStore
    if (!editVM) return null

    return (
      <form onSubmit={this.handleSavePress.bind(this)}>
        <IonList mode='md'>
          <IonItemDivider mode='md' color='medium'>
            Board Info
          </IonItemDivider>
          <IonItem lines={'inset'}>
            <IonLabel position='floating'>Name</IonLabel>
            <IonInput type='text' value={editVM.name} onIonChange={(e: any) => editVM.setName(e.target.value)} />
          </IonItem>
        </IonList>
      </form>
    )
  }

  renderPendingInviationsHeader() {
    const { editVM } = this.props.boardsStore
    if (!editVM) return null
    if (!editVM.pendingBoardInvitations.length) return
    if (editVM.pendingBoardInvitations.length === 0) return
    return (
      <IonItemDivider color='medium' mode='md'>
        Pending Invitations
      </IonItemDivider>
    )
  }

  renderMembers() {
    const { editVM } = this.props.boardsStore
    if (!editVM) return null

    return (
      <IonList mode='md'>
        <IonItemDivider color='medium' mode='md'>
          Members
        </IonItemDivider>

        {editVM.boardUsers.map((usr, idx) => (
          <IonItem lines={'inset'} key={'usr' + idx}>
            <IonAvatar slot={'start'}>
              <UserAvatar size='36' colors={AvatarColors} name={usr.AppUser && usr.AppUser.Name} />
            </IonAvatar>
            <IonLabel>
              <h2>{usr.AppUser && usr.AppUser.Name}</h2>
            </IonLabel>
          </IonItem>
        ))}
        {this.renderPendingInviationsHeader()}

        {editVM.pendingBoardInvitations.map((usr, idx) => (
          <IonItem lines={'inset'} key={'bi' + idx}>
            <IonLabel>
              <h2>{usr.ToEmailAddress}</h2>
            </IonLabel>
          </IonItem>
        ))}

        <IonItem lines={'inset'} onClick={() => editVM.openInviteModal()}>
          <IonLabel>
            <IonButton color='primary'>Invite</IonButton>
          </IonLabel>
        </IonItem>
      </IonList>
    )
  }

  renderDeleteConfirm() {
    const { editVM } = this.props.boardsStore
    if (!editVM) return null
    return (
      <IonAlert
        isOpen={editVM.deleteConfirmShown}
        onDidDismiss={() => editVM.hideDeleteConfirm()}
        header={'Delete Board'}
        message={'Are you sure you wish to delete this board?'}
        buttons={[
          {
            text: 'No',
            role: 'cancel',
            cssClass: 'primary',
            handler: () => editVM.hideDeleteConfirm(),
          },
          {
            text: 'Yes',
            handler: () => editVM.delete(),
          },
        ]}
      />
    )
  }

  renderDeleteButton() {
    const { editVM } = this.props.boardsStore
    if (!editVM) return
    if (!editVM.canDeleteBoard) return
    return (
      <IonButton onClick={() => editVM.showDeleteConfirm()}>
        <IonIcon icon={trashOutline} />
      </IonButton>
    )
  }

  render() {
    const { editVM } = this.props.boardsStore
    if (!editVM) return null

    return (
      <>
        {this.props.appStore.showAppHeader && (
          <IonHeader>
            <IonToolbar />
          </IonHeader>
        )}

        <IonHeader id='BoardSettingsPage_Header'>
          <IonToolbar color='secondary'>
            <IonButtons slot='start'>
              <IonButton onClick={() => this.handleGoBack()}>
                <BackIcon />
              </IonButton>
            </IonButtons>

            <IonTitle>Board Sharing</IonTitle>

            <IonButtons slot='end'>
              {this.renderDeleteButton()}
              <IonButton onClick={e => this.handleSavePress(e)}>
                <IonIcon icon={checkmarkSharp} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <IonContent id='BoardSettingsPage_Content' style={{ height: this.props.appStore.listHeightPx }}>
          {this.renderNameRow()}
          <InvitationCodeRow />
          {this.renderMembers()}
          <JoinBoardRow />
          {this.renderDeleteConfirm()}
          <BoardInvitationNewModal />
        </IonContent>
      </>
    )
  }
}
