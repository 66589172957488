import React from 'react'
import { inject, observer } from 'mobx-react'
import { AppStore } from '../../../stores/AppStore'
import { IonList, IonItem, IonLabel, IonInput, IonSelect, IonSelectOption } from '@ionic/react'
import { CirclePicker } from 'react-color'
import { AccountsStore } from '../../store/AccountsStore'
import ConnectToBank from './ConnectToBank'

interface Props {
  accountsStore?: AccountsStore
  appStore?: AppStore
}

const DetailsForm: React.FC<Props> = ({ accountsStore, appStore }) => {
  const { editVM } = accountsStore
  if (!editVM) return null
  if (appStore.bodyHeight === 0) return null

  const renderName = () => {
    return (
      <IonItem lines={'inset'}>
        <IonLabel position='floating'>Name</IonLabel>
        <IonInput type='text' value={editVM.name} onIonChange={(e: any) => editVM.setName(e.target.value)} />
      </IonItem>
    )
  }

  const renderNotes = () => {
    return (
      <IonItem lines={'inset'}>
        <IonLabel position='floating'>Notes</IonLabel>
        <IonInput
          type='text'
          value={editVM.notes}
          maxlength={500}
          onIonChange={(e: any) => editVM.setNotes(e.target.value)}
        />
      </IonItem>
    )
  }

  const renderType = () => {
    return (
      <IonItem lines={'inset'}>
        <IonLabel position='floating'>Type</IonLabel>
        <IonSelect interface='popover' value={editVM.typeId} onIonChange={(e: any) => editVM.setType(e.target.value)}>
          {editVM.accountTypes.map((e) => (
            <IonSelectOption key={e.id} value={e.id}>
              {e.name}
            </IonSelectOption>
          ))}
        </IonSelect>
      </IonItem>
    )
  }

  const renderDateRange = () => {
    return (
      <IonItem lines={'inset'}>
        <IonLabel position='floating'>Balance Thru</IonLabel>
        <IonSelect
          interface='popover'
          value={editVM.dateRangeId}
          onIonChange={(e: any) => editVM.setDateRange(e.target.value)}
        >
          {editVM.dateRanges.map((e) => (
            <IonSelectOption key={e.id} value={e.id}>
              {e.name}
            </IonSelectOption>
          ))}
        </IonSelect>
      </IonItem>
    )
  }

  const renderColor = () => {
    return (
      <IonItem lines='inset'>
        <IonLabel position='floating' className='forced-floating'>
          Color
        </IonLabel>
        <CirclePicker
          color={editVM.color}
          circleSize={20}
          width={'100%'}
          colors={editVM.colors}
          onChange={(e) => editVM.setColor(e.hex)}
        />
      </IonItem>
    )
  }

  const renderCurrentBalance = () => {
    if (!editVM.isNew) return
    return (
      <IonItem lines={'inset'}>
        <IonLabel position='floating'>Current Cleared Balance</IonLabel>
        <IonInput
          type='number'
          value={editVM.clearedBalance}
          onIonChange={(e: any) => editVM.setClearedBalance(e.target.value)}
        />
      </IonItem>
    )
  }

  const renderConnectToBank = () => {
    if (editVM.isNew) return
    return <ConnectToBank />
  }

  return (
    <form
      id='DetailsForm'
      onSubmit={(e) => {
        e.preventDefault()
        editVM.save()
      }}
    >
      <IonList>
        {renderName()}
        {renderType()}
        {renderCurrentBalance()}
        {renderDateRange()}
        {renderNotes()}
        {renderColor()}
        {renderConnectToBank()}
      </IonList>
    </form>
  )
}

export default inject('accountsStore', 'appStore')(observer(DetailsForm))
