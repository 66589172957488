import React from 'react'
import {
  IonContent,
  IonButton,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonRefresher,
  IonRefresherContent,
} from '@ionic/react'
import { inject, Observer } from 'mobx-react'
import { AppStore } from '../../../stores/AppStore'
import { BankTransactionsStore } from '../../store/BankTransactionsStore'
import { match } from 'react-router'
import BankTransactionsListPageMoreMenu from './BankTransactionsListPageMoreMenu'
import BackIcon from '../../../shared/BackIcon'
import BankTransactionsList from './BankTransactionsList'

interface Props {
  bankTransactionsStore: BankTransactionsStore
  appStore: AppStore
  match: match
}

@inject('bankTransactionsStore')
@inject('appStore')
export default class TransactionsListPage extends React.Component<Props> {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.props.appStore.setCurrentRoute(this.props.match.url)
  }

  renderNoItemsMessage() {
    if (this.props.bankTransactionsStore.currentBoardRecords.length != 0) return null
    return (
      <div className='no-items'>
        This account has no transactions.
        <br />
        <br />
        Go deposit some money, so you can log it here... :)
      </div>
    )
  }

  renderAppHeader() {
    if (!this.props.appStore.showAppHeader) return
    return (
      <IonHeader>
        <IonToolbar />
      </IonHeader>
    )
  }

  handleGoBack() {
    this.props.appStore.handleGoBack()
  }

  renderList() {
    if (this.props.bankTransactionsStore.currentBoardRecords.length === 0) return null
    return <BankTransactionsList />
  }

  renderRefresher() {
    const listVM = this.props.bankTransactionsStore.listVM
    if (!listVM) return null
    return (
      <Observer>
        {() => {
          return (
            <IonRefresher slot='fixed' onIonRefresh={(e) => listVM.onPullToRefresh(e)} disabled={!listVM.atTop}>
              <IonRefresherContent />
            </IonRefresher>
          )
        }}
      </Observer>
    )
  }

  render() {
    return (
      <Observer>
        {() => {
          return (
            <>
              {this.renderAppHeader()}
              <IonHeader id='BankTransactionsListPage_Header'>
                <IonToolbar color='secondary'>
                  <IonButtons slot='start'>
                    <IonButton onClick={() => this.handleGoBack()}>
                      <BackIcon />
                    </IonButton>
                  </IonButtons>
                  <IonTitle>Inbox</IonTitle>
                  <IonButtons slot='end'>
                    <BankTransactionsListPageMoreMenu />
                  </IonButtons>
                </IonToolbar>
              </IonHeader>
              <IonContent
                id='BankTransactionsListPage_Content'
                ref={(e) => this.props.appStore.applyScrollStyles(e)}
                style={{ height: this.props.appStore.listHeightPx }}
              >
                {this.renderRefresher()}
                {this.renderList()}
                {this.renderNoItemsMessage()}
              </IonContent>
            </>
          )
        }}
      </Observer>
    )
  }
}
