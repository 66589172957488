import React from 'react'
import { IonButton, IonIcon, IonPopover, IonItem } from '@ionic/react'
import { observer, inject } from 'mobx-react'
import { ellipsisVertical, eye } from 'ionicons/icons'
import { MaterialCommunityIcons, EvilIcons } from 'react-web-vector-icons'
import { BankTransactionsStore } from '../../store/BankTransactionsStore'
import { DateRangeEnum } from '../../../accounts/enum/DateRangeEnum'

interface Props {
  bankTransactionsStore?: BankTransactionsStore
}

const BankTransactionsListPageMoreMenu: React.FC<Props> = ({ bankTransactionsStore }) => {
  const vm = bankTransactionsStore.listVM
  if (!vm) return null

  const renderOpenMenu = () => {
    return (
      <IonButton onClick={(e) => vm.openMenu(e)}>
        <IonIcon icon={ellipsisVertical} mode='ios' />
      </IonButton>
    )
  }

  return (
    <div id='BankTransactionsListPageMoreMenu'>
      {renderOpenMenu()}

      <IonPopover
        id='BankTransactionsListPageMoreMenu_Content'
        isOpen={vm.menuShown}
        event={vm.event}
        onDidDismiss={() => vm.closeMenu()}
      >
        <IonItem lines='full' onClick={() => alert('not implemented')}>
          <IonIcon icon={eye} size='small' />
          &nbsp; Import More Bank Transactions
        </IonItem>
      </IonPopover>
    </div>
  )
}

export default inject('bankTransactionsStore')(observer(BankTransactionsListPageMoreMenu))
