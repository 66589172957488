import { GroupRowVM } from '@elexient/elexiapp.bits.shared'
import { computed } from 'mobx'
import { RootStore as ThisRootStore } from '../../stores/RootStore'
import { TransactionsListVM } from './TransactionsListVM'
import Sugar from 'sugar'
import { TransactionRowVM } from './TransactionRowVM'

export class TransactionGroupRowVM extends GroupRowVM<ThisRootStore, TransactionsListVM> {
  @computed
  public get displayName(): string {
    return this.name.substring(1)
  }

  public get color(): string {
    throw new Error('Method not implemented.')
  }

  @computed
  public get isPositive(): boolean {
    return Boolean(this.balance >= 0)
  }

  @computed
  public get balance(): number {
    // let sum = 0
    // const rows = this.listVM.allRows.filter((e) => {
    //   if (e.rowType !== 'normal') return false
    //   e = e as TransactionRowVM
    //   if (this.displayName === 'Inbox') return false
    //   if (this.displayName === e.statusName) return true
    //   if (this.displayName === 'Pending' && e.statusName === 'Cleared') return true
    //   if (this.displayName === 'Upcoming' && e.statusName === 'Pending') return true
    //   if (this.displayName === 'Upcoming' && e.statusName === 'Cleared') return true
    //   return false
    // })
    // rows.forEach((e: TransactionRowVM) => (sum += e.amount))
    // return sum
    if (this.displayName === 'Pending') return this.listVM.account.PendingBalance
    if (this.displayName === 'Cleared') return this.listVM.account.ClearedBalance
    if (this.displayName === 'Upcoming') return this.listVM.account.Balance
  }

  @computed
  public get balanceFormatted(): string {
    if (isNaN(this.balance)) return '...'
    return Sugar.Number(this.balance).format(2).raw
  }
}
