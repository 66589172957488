import { RootStore } from '../../stores/RootStore'
import { computed, action, makeObservable } from 'mobx'
import { RowVM } from '@elexient/elexiapp.bits.shared'
import { AccountsListVM } from './AccountsListVM'
import { Account } from '../aggregate/Account'
import Sugar from 'sugar'

export class AccountRowVM extends RowVM<RootStore, AccountsListVM, Account> {
  constructor(rootStore: RootStore, listVM: AccountsListVM, reminder: Account, self: any) {
    super(rootStore, listVM, reminder, self)
  }

  @computed
  public get keyword(): string {
    return this.name
  }

  @computed
  public get name(): string {
    return this.record.Name
  }

  @computed
  public get key(): string {
    return this.record.AccountGuid
  }

  public get height(): number {
    if (this.isHidden) return 0
    return 53
  }

  @computed
  public get color(): string {
    return this.record.Color
  }

  @computed
  public get balance(): string {
    let str = Sugar.Number(this.record.Balance).format(2).raw
    return str
  }

  @action
  public click() {
    this.rootStore.appStore.history.push('/transactionslist/' + this.record.AccountGuid)
  }

  @computed
  public get hasNotes(): boolean {
    return Boolean(this.notes)
  }

  @computed
  public get notes(): string {
    return this.record.Notes
  }

  @computed
  public get type(): string {
    return this.record.TypeName
  }

  @computed
  public get isPositive(): boolean {
    return Boolean(this.record.Balance >= 0)
  }

  @computed
  public get inboxTransactions() {
    return this.rootStore.bankTransactionsStore.currentBoardRecords.filter(
      (e) => e.AccountGuid === this.record.AccountGuid && e.isInInbox
    ).length
  }

  @computed
  public get hasInboxTransactions() {
    return this.inboxTransactions > 1
  }

  @computed
  public get plaidItemNeedsUpdating(): boolean {
    if (!this.rootStore.plaidItemsStore?.isHydrated) return false
    const plaidItem = this.rootStore.plaidItemsStore.get(this.record.PlaidItemGuid)
    if (!plaidItem) return false
    return plaidItem.NeedsUpdating
  }
}
