import React from 'react'
import { inject, Observer, observer } from 'mobx-react'
import { IonCard, IonCardContent, IonIcon } from '@ionic/react'
import { List } from '@elexient/elexiapp.bits.shared'
import { chevronDown, chevronForward } from 'ionicons/icons'
import { CategoriesStore } from '../../store/CategoriesStore'
import { CategoryGroupRowVM } from '../../view-models/CategoryGroupRowVM'
import { CategoryRowVM } from '../../view-models/CategoryRowVM'

interface Props {
  categoriesStore?: CategoriesStore
}

const CategoriesList: React.FC<Props> = ({ categoriesStore }) => {
  const listVM = categoriesStore.listVM
  if (!listVM) return null

  const renderNormalRow = (row: CategoryRowVM, index, style) => {
    return (
      <Observer key={row.key + String(index) + row.rowType}>
        {() => (
          <div onClick={() => row.click()} style={style}>
            <IonCard id='CategoryRow' style={{ borderLeftColor: row.color }}>
              <IonCardContent>{row.name}</IonCardContent>
            </IonCard>
          </div>
        )}
      </Observer>
    )
  }

  const renderRightIcon = (row: CategoryGroupRowVM) => {
    if (!row.isCollapsed) return
    return <IonIcon icon={chevronForward} />
  }

  const renderDownIcon = (row: CategoryGroupRowVM) => {
    if (row.isCollapsed) return
    return <IonIcon icon={chevronDown} />
  }

  const renderGroupRow = (row: CategoryGroupRowVM, index, style) => {
    return (
      <Observer key={row.key + String(index) + row.rowType + row.isCollapsed + row.isInvView}>
        {() => (
          <div style={style} className={row.rowType} onClick={() => row.click()}>
            <div className='main-cell'>{row.displayName}</div>
            {renderRightIcon(row)}
            {renderDownIcon(row)}
          </div>
        )}
      </Observer>
    )
  }

  return (
    <List
      listVM={listVM}
      className='list'
      normalRow={(row, index, style) => renderNormalRow(row, index, style)}
      groupRow={(row, index, style) => renderGroupRow(row, index, style)}
    />
  )
}

export default inject('categoriesStore')(observer(CategoriesList))
