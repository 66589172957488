import React from 'react'
import { inject, observer } from 'mobx-react'
import { RemindersStore } from '../../store/RemindersStore'
import { AppStore } from '../../../stores/AppStore'
import { IonList, IonItem, IonLabel, IonInput, IonSelect, IonSelectOption } from '@ionic/react'
import { CirclePicker } from 'react-color'

interface Props {
  remindersStore?: RemindersStore
  appStore?: AppStore
}

const ScheduleForm: React.FC<Props> = ({ remindersStore, appStore }) => {
  const { editVM } = remindersStore
  if (!editVM) return null
  if (appStore.bodyHeight === 0) return null
  if (editVM.currentTabIndex !== '1') return null

  const renderDueOnDay = () => {
    return (
      <IonItem lines={'inset'}>
        <IonLabel position='floating'>Day of Month</IonLabel>
        <IonInput type='number' value={editVM.dayOfMonth} onIonChange={(e: any) => editVM.setDay(e.target.value)} />
      </IonItem>
    )
  }

  return (
    <form
      id='InfoForm'
      onSubmit={(e) => {
        e.preventDefault()
        editVM.save()
      }}
    >
      <IonList>{renderDueOnDay()}</IonList>
    </form>
  )
}

export default inject('remindersStore', 'appStore')(observer(ScheduleForm))
