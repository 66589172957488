import React from 'react'
import { inject, observer, useLocalObservable } from 'mobx-react'
import { AccountsStore } from '../../store/AccountsStore'
import { AppStore } from '../../../stores/AppStore'
import {
  IonButton,
  IonIcon,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonButtons,
  IonToolbar,
  IonText,
} from '@ionic/react'
import { cogSharp } from 'ionicons/icons'
import { RootStore } from '../../../stores/RootStore'
import { NetWorthWidgetVM } from '../../view-models/net-worth-widget/NetWorthWidgetVM'

interface Props {
  rootStore?: RootStore
}

const NetWorthWidget: React.FC<Props> = ({ rootStore }) => {
  const vm = useLocalObservable(() => new NetWorthWidgetVM(rootStore))
  if (vm.bodyHeight === 0) return null

  return (
    <IonCard color='light' id='NetWorthWidget' className='widget'>
      <IonCardHeader color='light'>
        <IonToolbar color='light'>
          <IonCardTitle>Net Worth</IonCardTitle>
          <IonButtons slot='end'>
            <IonButton>
              <IonIcon icon={cogSharp} color='medium' />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonCardHeader>
      <IonCardContent>
        <IonText>{vm.netWorth}</IonText>
      </IonCardContent>
    </IonCard>
  )
}

export default inject('rootStore')(observer(NetWorthWidget))
