// import '@capacitor-community/firebase-analytics'
// import { Plugins } from '@capacitor/core'
import { RootStore } from '../stores/RootStore'
import { initializeApp } from 'firebase/app'
import { getMessaging, getToken } from 'firebase/messaging'

export class FirebaseService {
  private rootStore: RootStore
  private fbInitialized: boolean

  private firebaseConfig = {
    apiKey: 'AIzaSyCp7OhBhcEJQNxFXfndPeqHCkQB_9CUkmw',
    authDomain: 'budget-board.firebaseapp.com',
    databaseURL: 'https://budget-board.firebaseio.com',
    projectId: 'budget-board',
    storageBucket: 'budget-board.appspot.com',
    messagingSenderId: '871814427525',
    appId: '1:871814427525:web:809d3e76b3dd18dfe74aec',
  }

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  // private initFirebaseForWeb() {
  //   if (this.rootStore.appStore.isIos) return
  //   if (this.rootStore.appStore.isAndroid) return
  //   if (process.env.REACT_APP_IS_DEV_MODE === '1') return
  //   const script1 = document.createElement('script')
  //   script1.src = '/__/firebase/8.2.4/firebase-app.js'
  //   const script2 = document.createElement('script')
  //   script2.src = '/__/firebase/8.2.4/firebase-analytics.js'
  //   const script3 = document.createElement('script')
  //   script3.src = '/__/firebase/init.js'
  //   document.body.appendChild(script1)
  //   document.body.appendChild(script2)
  //   document.body.appendChild(script3)
  //   this.logEvent('app_accessed')
  //   console.log(window.navigator.userAgent)
  //   if (!window.firebase) console.log('firebase not initialized')
  // }

  public async getMessagingToken(): Promise<string> {
    let hasSW = false
    if ('serviceWorker' in navigator) hasSW = true
    if (!hasSW) return undefined
    const swReg = await navigator.serviceWorker.getRegistration()
    if (!swReg) return undefined
    console.log('Requesting permission...')
    const permission = await Notification.requestPermission()
    if (permission !== 'granted') {
      console.log('Notification Permission Denied')
      return undefined
    }
    console.log('Notification permission granted. Initializing App...')
    const app = initializeApp(this.firebaseConfig)
    console.log('App initalized.')
    this.fbInitialized = true
    const messaging = getMessaging(app)
    console.log('got messaging')
    const token = await getToken(messaging, {
      vapidKey: 'BCMM401BysdcQSYpKn-OhJaaKNtMgabizswqapiLNzZAnRzVFFLwB_AX1gYSduEMYo46caALjgpIqvZ9mM-6gDE',
      serviceWorkerRegistration: swReg,
    })
    console.log('got token')
    return token
    // need to send token to server. would need to send multiple
  }

  // public setUserInfo(userId, email, name) {
  //   this.fb.setUserId({ userId: userId })
  //   this.fb.setUserProperty({
  //     name: 'name',
  //     value: name,
  //   })
  //   this.fb.setUserProperty({
  //     name: 'email',
  //     value: email,
  //   })
  // }

  // public setUserInfoFromSaved() {
  //   if (!this.rootStore.appStore.isLoggedIn) return
  //   if (!this.rootStore.userStore.user) return
  //   if (!this.rootStore.userStore.user.IdentityId) return
  //   this.setUserInfo(
  //     this.rootStore.userStore.user.IdentityId,
  //     this.rootStore.userStore.user.EmailAddress,
  //     this.rootStore.userStore.user.Name
  //   )
  // }

  // private get fb() {
  //   return firebaseap
  // }

  // public logRoutingEvent(route: string) {
  //   if (route === '/' && !this.fbInitialized) return
  //   if (route === '/') this.logScreenView('shopping_list')
  //   if (route === '/recipeslist') this.logScreenView('recipes_list')
  //   if (route === '/mealslist') this.logScreenView('meals_list')
  // }

  // public logScreenView(name) {
  //   if (this.rootStore.appStore.isWeb) {
  //     // console.log('logged screen view event as WEB: ' + name)
  //     this.logEvent('screen_view', { screen_name: name })
  //     return
  //   }
  //   // if (!this.fbInitialized) return
  //   this.fb.setScreenName({
  //     screenName: name,
  //     nameOverride: name,
  //   })
  //   // console.log('logged screen view event: ' + name)
  // }

  // public logEvent(eventName: string, params?: any) {
  //   if (!this.fbInitialized) return
  //   if (params) this.fb.logEvent({ name: eventName, params: params })
  //   else this.fb.logEvent(eventName)
  // }
}
