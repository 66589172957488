import { observable, action, makeObservable } from 'mobx'
import generateUUID from '../../utils/UUID'
import { ICategoryDTO } from '../dtos/ICategoryDTO'
import { serialize, deserialize } from 'serializr'
import { IAggregrate } from '../../shared/IAggregate'
import { persist } from '../../shared/persist'

export class Category implements ICategoryDTO, IAggregrate {
  public static create(boardId, categoryGuid: string = null, name: string = null) {
    const cat = new Category()
    cat.CategoryGuid = categoryGuid
    if (!categoryGuid) cat.CategoryGuid = generateUUID()
    cat.Name = name
    cat.BoardId = boardId
    return cat
  }

  constructor() {
    makeObservable(this)
  }

  @persist @observable public CategoryGuid: string = ''
  @persist @observable public Name: string = ''
  @persist @observable public BoardId: number = 0
  @persist @observable public CategoryGroupId: number = 0
  @persist @observable public Color: string = ''
  @persist @observable public IsDeleted: boolean = false

  @action
  public setName(val: string) {
    this.Name = val
  }

  @action
  public setGroup(val: number) {
    this.CategoryGroupId = val
  }

  @action
  public setColor(val: string) {
    this.Color = val
  }

  public clone(): Category {
    return deserialize(Category, serialize(this))
  }

  public toDTO(): ICategoryDTO {
    return serialize(this)
  }
}
