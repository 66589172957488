import React from 'react'
import { IonButton, IonPopover, IonList, IonItem, IonIcon } from '@ionic/react'
import { observer, inject } from 'mobx-react'
import { Feather } from 'react-web-vector-icons'
import UserAvatar from 'react-user-avatar'
import { UserStore } from '../store/UserStore'
import AvatarColors from '../../theme/AvatarColors'
import { personCircleOutline, peopleOutline } from 'ionicons/icons'
import { AuthStore } from '../../login/store/AuthStore'
import '../styles/AccountMenu.scss'

interface Props {
  authStore?: AuthStore
  userStore?: UserStore
  history: any
}

@inject('authStore')
@inject('userStore')
@observer
export default class AccountMenu extends React.Component<Props, any> {
  constructor(props) {
    super(props)
    this.state = {
      shown: false,
    }
  }

  handleMenuButtonClick(event) {
    event.persist()
    this.setState({ shown: true, event: event })
  }

  handleSyncClick() {
    this.setState({ shown: false })
  }

  handleSettingsClick() {
    this.setState({ shown: false })
    setTimeout(() => {
      this.props.history.push('/accountsettings')
    }, 500)
  }

  handleBoardSettingsClick() {
    this.setState({ shown: false })
    setTimeout(() => {
      this.props.history.push('/boardsettings')
    }, 500)
  }

  handleLogOutClick() {
    this.setState({ shown: false })
    setTimeout(() => {
      this.props.authStore.logout()
      this.props.history.push('/login')
    }, 1000)
  }

  render() {
    const { user } = this.props.userStore
    return (
      <div id='AccountMenu'>
        <IonButton onClick={this.handleMenuButtonClick.bind(this)}>
          {user && user.Name && <UserAvatar size='36' colors={AvatarColors} name={this.props.userStore.user.Name} />}
        </IonButton>

        <IonPopover
          id='AccountMenu_Content'
          isOpen={this.state.shown}
          event={this.state.event}
          onDidDismiss={() => this.setState(() => ({ shown: false }))}
        >
          <IonList>
            <IonItem lines='full' onClick={this.handleSettingsClick.bind(this)}>
              <IonIcon icon={personCircleOutline} />
              &nbsp; My Account
            </IonItem>

            <IonItem lines='full' onClick={this.handleBoardSettingsClick.bind(this)}>
              <IonIcon icon={peopleOutline} />
              &nbsp; Board Sharing
            </IonItem>

            <IonItem lines='full' onClick={this.handleLogOutClick.bind(this)} className='logout-item'>
              <Feather name='log-out' size={18} />
              &nbsp; &nbsp; Log out
            </IonItem>
          </IonList>
        </IonPopover>
      </div>
    )
  }
}
