import { observable, action, computed, makeObservable, runInAction } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { Account } from '../aggregate/Account'
import { AccountEditVM } from '../view-models/AccountEditVM'
import { AccountsListVM } from '../view-models/AccountsListVM'
import { IAccountDTO } from '../dtos/IAccountDTO'
import { DataStore } from '@elexient/elexiapp.bits.shared'
import AgentV2 from '../../AgentV2'
import { makePersistable } from 'mobx-persist-store'
import { deserialize, list, object, serializable } from 'serializr'
import { NetWorthWidgetVM } from '../view-models/net-worth-widget/NetWorthWidgetVM'

export class AccountsStore extends DataStore<RootStore, Account, IAccountDTO> {
  protected worker: any

  constructor(rootStore: RootStore) {
    super(rootStore, Account, AgentV2, 'Accounts', 'Account')
    super.setRecords(this.persistedRecords)
    makeObservable(this)
    makePersistable(this, { name: 'AccountsStore', properties: ['persistedRecords'] }).then(
      action((st) => this.onHydrationCompleted(st?.isHydrated))
    )
  }

  @action
  public onHydrationCompleted(isHydated?: boolean) {
    this.persistedRecords = observable.array(this.persistedRecords.map((e) => makeObservable(deserialize(Account, e))))
    this.setRecords(this.persistedRecords)
    this.isHydrated = Boolean(isHydated)
    if (process.env.NODE_ENV === 'test') this.isHydrated = true
    this.listVM = new AccountsListVM(this.rootStore as RootStore)
  }

  @observable.shallow public persistedRecords: Array<Account> = []
  @observable public editVM: AccountEditVM = null
  @observable public listVM: AccountsListVM = null
  @observable public netWorthWidgetVM: NetWorthWidgetVM = null
  @observable public isHydrated: boolean = false

  public async loadData() {
    await super.loadData()
  }

  public onLoadDataCompleted(): void {
    if (!this.listVM) return
    this.listVM.reloadRows()
  }

  @action
  public lazyLoadEditVM(guid: string, attempts: number = 0) {
    if (attempts === 10) return
    if (guid === 'new') {
      this.editVM = new AccountEditVM(this.rootStore, Account.create(this.rootStore.boardsStore.currentBoardId), true)
    } else if (guid === 'empty') {
      this.editVM = null
    } else {
      const found = this.get(guid)
      if (!found) setTimeout(() => this.lazyLoadEditVM(guid, attempts++), 500)
      else this.editVM = new AccountEditVM(this.rootStore, found.clone())
    }
  }

  @computed
  public get currentBoardRecords() {
    if (!this.isHydrated) return []
    return this.persistedRecords.filter((e) => e.BoardId === this.rootStore.boardsStore.currentBoardId)
  }
}
