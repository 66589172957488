import { observable, action, makeObservable, computed } from 'mobx'
import { IScheduleDTO } from '../dtos/IScheduleDTO'
import { serialize, deserialize, serializable } from 'serializr'
import { IAggregrate } from '../../shared/IAggregate'
import { MonthlyRepeatByEnum } from '../enum/MonthlyRepeatByEnum'
import { ScheduleTypeEnum } from '../enum/ScheduleTypeEnum'
import { EndModeEnum } from '../enum/EndModeEnum'
import generateUUID from '../../utils/UUID'
import moment, { ISO_8601 } from 'moment'

export class Schedule implements IScheduleDTO, IAggregrate {
  public static create() {
    const sch = new Schedule()
    sch.ScheduleGuid = generateUUID()
    sch.TypeId = ScheduleTypeEnum.Monthly
    sch.StartDate = moment().utc().toISOString()
    sch.EndModeId = EndModeEnum.Never
    sch.MonthlyRepeatById = MonthlyRepeatByEnum.Date
    sch.Interval = 1
    return sch
  }

  constructor() {
    makeObservable(this)
  }

  @serializable @observable public ScheduleGuid: string
  @serializable @observable public StartDate: string
  @serializable @observable public EndDate?: string
  @serializable @observable public Occurrences?: number
  @serializable @observable public EndModeId: EndModeEnum
  @serializable @observable public Monday: boolean
  @serializable @observable public Tuesday: boolean
  @serializable @observable public Wednesday: boolean
  @serializable @observable public Thursday: boolean
  @serializable @observable public Friday: boolean
  @serializable @observable public Saturday: boolean
  @serializable @observable public Sunday: boolean
  @serializable @observable public MonthlyRepeatById?: MonthlyRepeatByEnum
  @serializable @observable public Interval: number
  @serializable @observable public TypeId: ScheduleTypeEnum
  @serializable @observable public IsDeleted: boolean = false

  @action
  public setDay(val: number) {
    if (val.toString() === '') val = 1
    this.StartDate = moment().clone().startOf('month').set('day', Number(val) + 1).toISOString()
  }

  @computed
  public get day(): number {
    return Number(moment(this.StartDate, ISO_8601).format('DD'))
  }

  public clone(): Schedule {
    return deserialize(Schedule, serialize(this))
  }

  public toDTO(): IScheduleDTO {
    return serialize(this)
  }
}
