import { observable, action, makeObservable } from 'mobx'
import { BoardInvitationNewVM } from '../view-models/BoardInvitationNewVM'
import BoardInvitation from '../aggregate/BoardInvitation'
import { BoardInvitationEditVM as BoardInvitationAcceptVM } from '../view-models/BoardInvitationAcceptVM'
import { RootStore } from '../../stores/RootStore'
import { DataStore } from '../../shared/DataStore'
import { IBoardInvitationDTO } from '../dto/IBoardInvitationDTO'
import { makePersistable } from 'mobx-persist-store'
import { deserialize } from 'serializr'

export class BoardInvitationsStore extends DataStore<BoardInvitation, IBoardInvitationDTO> {
  constructor(rootStore: RootStore) {
    super(rootStore, BoardInvitation, 'BoardInvitations', 'BoardInvitation')
    makeObservable(this)
    makePersistable(this, { name: 'BoardInvitationsStore', properties: ['persistedRecords'] }).then(
      action((st) => this.onHydrationCompleted())
    )
  }

  @action
  public onHydrationCompleted() {
    this.persistedRecords = observable.array(this.persistedRecords.map((e) => makeObservable(deserialize(BoardInvitation, e))))
    super.setRecords(this.persistedRecords)
  }

  @observable.shallow public persistedRecords: Array<BoardInvitation> = []
  @observable public newVM: BoardInvitationNewVM = null
  @observable public acceptVM: BoardInvitationAcceptVM = null

  @action
  public loadNewVM() {
    this.newVM = new BoardInvitationNewVM(this.rootStore)
  }

  @action
  public loadAcceptVM(guid) {
    const boardInv = this.get(guid)
    this.acceptVM = new BoardInvitationAcceptVM(this.rootStore, boardInv)
  }
}
