import { RootStore } from 'src/stores/RootStore'
import { computed, action, observable, runInAction, makeObservable } from 'mobx'
import { NewRowVM } from '@elexient/elexiapp.bits.shared'
import { TransactionsListVM } from './TransactionsListVM'
import { TransactionsService } from '../services/TransactionsService'

export class TransactionLastRowVM extends NewRowVM<RootStore, TransactionsListVM> {
  @computed
  public get displayName(): string {
    return 'Loading More'
  }

  public get color(): string {
    return ''
  }

  public get height() {
    return 40
  }

  @action
  public async loadNextPage() {
    if (this.listVM.isProcessing) return
    await this.listVM.loadNextPage()
  }

  @computed
  public get isIos(): boolean {
    return this.rootStore.appStore.isIos
  }

  @computed
  public get hasMorePages() {
    if (!this.listVM.account) return false
    if (!this.listVM.firstPageLoaded) return false
    return this.listVM.hasMorePages
  }

  @computed
  public get pageNumber(): number {
    if (!this.listVM.account) return 1
    return this.listVM.pageNumber
  }
}
