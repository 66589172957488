import { observable, action, makeObservable } from 'mobx'
import { BoardUser } from './BoardUser'
import { BoardInvitation } from './BoardInvitation'
import { IBoardDTO } from '../dtos/IBoardDTO'
import generateUUID from '../../utils/UUID'
import { deserialize, serialize } from 'serializr'
import { persist } from '../../shared/persist'

export class Board implements IBoardDTO {
  static create() {
    const brd = new Board()
    brd.BoardGuid = generateUUID()
    return brd
  }

  constructor() {
    makeObservable(this)
  }

  @persist @observable public BoardId: number = 0
  @persist @observable public BoardGuid: string = ''
  @persist @observable public Name: string = ''
  @persist @observable public InvitationCode: string = null
  @persist('list', BoardUser) @observable public BoardUsers: Array<BoardUser> = []
  @persist('list', BoardInvitation) @observable public BoardInvitations: Array<BoardInvitation> = []
  @persist @observable IsDeleted: boolean = false

  @action
  public setName(val) {
    this.Name = val
  }

  @action
  public markAsDeleted() {
    this.IsDeleted = true
  }

  public clone(): Board {
    return deserialize(Board, serialize(this))
  }

  public toDTO(): IBoardDTO {
    return serialize(this)
  }
}
