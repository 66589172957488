import { RootStore } from '../../stores/RootStore'
import { computed, action } from 'mobx'
import { RowVM } from '@elexient/elexiapp.bits.shared'
import { CategoriesListVM } from './CategoriesListVM'
import { Category } from '../aggregate/Category'
import { CategoryGroupEnumName } from '../enums/CategoryGroupEnumName'

export class CategoryRowVM extends RowVM<RootStore, CategoriesListVM, Category> {
  constructor(rootStore: RootStore, listVM: CategoriesListVM, reminder: Category, self: any) {
    super(rootStore, listVM, reminder, self)
  }

  @computed
  public get keyword(): string {
    return this.record.Name
  }

  public get height(): number {
    if (this.isHidden) return 0
    return 57
  }

  @computed
  public get name(): string {
    return this.record.Name
  }

  @computed
  public get groupName(): string {
    return CategoryGroupEnumName.fromId(this.record.CategoryGroupId)
  }

  @computed
  public get key(): string {
    return this.record.CategoryGuid
  }

  @computed
  public get color(): string {
    return this.record.Color
  }

  @action
  public click() {
    this.rootStore.appStore.history.push('/categoryedit/' + this.key)
  }
}
