import React from 'react'
import {
  IonContent,
  IonItem,
  IonIcon,
  IonButton,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonRefresher,
  IonRefresherContent,
  IonFab,
  IonFabButton,
  IonFabList,
} from '@ionic/react'
import { observer, inject, Observer } from 'mobx-react'
import { AppStore } from '../../../stores/AppStore'
import { RemindersStore } from '../../../reminders/store/RemindersStore'
import { Reminder } from '../../../reminders/aggregate/Reminder'
import {
  trash,
  add,
  arrowBack,
  trashOutline,
  cardOutline,
  enterOutline,
  exitOutline,
  repeatOutline,
  checkmarkSharp,
  checkmarkDoneCircleOutline,
  checkmarkCircleOutline,
} from 'ionicons/icons'
import { RootStore } from '../../../stores/RootStore'
import RemindersList from './RemindersList'
import BackIcon from '../../../shared/BackIcon'
import { NewTransactionTypeEnum } from '../../../transactions/enum/NewTransactionTypeEnum'
import { ReminderTypeEnum } from '../../enum/ReminderTypeEnum'

interface Props {
  remindersStore: RemindersStore
  appStore: AppStore
  rootStore: RootStore
  history: any
}

interface State {
  isNewModalOpen: boolean
  isEditModalOpen: boolean
  editReminderGuid: string
  isDeleteConfirmShown: boolean
  deleteReminderGuid: string
  deleteReminderName: string
}

@inject('remindersStore')
@inject('appStore')
export default class RemindersListPage extends React.Component<Props, State> {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.props.appStore.setCurrentRoute('/reminderslist')
  }

  handleAddButtonClick() {
    this.setState({ isNewModalOpen: true })
  }

  handleEditClick(reminderGuid) {
    this.setState({
      isEditModalOpen: true,
      editReminderGuid: reminderGuid,
    })
  }

  handleGoBack() {
    this.props.appStore.handleGoBack(this.props.history)
  }

  openListItem(listItem) {
    this.props.history.push('/maintabs/listitemedit/' + listItem.ListItemGuid)
  }

  addReminder(newType: ReminderTypeEnum) {
    const listVM = this.props.remindersStore.listVM
    if (!listVM) return
    listVM.addReminder(newType)
  }

  renderNoItemsMessage() {
    if (this.props.remindersStore.currentBoardRecords.length > 0) return null
    return (
      <div className='no-items'>
        You have no reminders set up!
        <br />
        Add some Bills to start your Budget Board journey...
        <br />
        <br />
        <IonButton slot='icon-only' type='button' onClick={() => this.props.appStore.navigateTo('/reminderedit/new')}>
          <IonIcon icon={add} />
        </IonButton>
      </div>
    )
  }

  renderFab() {
    return (
      <IonFab vertical='bottom' horizontal='end'>
        <IonFabButton color='tertiary' className='reminder-fab-button'>
          <IonIcon icon={add} />
        </IonFabButton>
        <IonFabList side='top'>
          <IonFabButton
            color='tertiary'
            className='reminder-small-fab-button smaller'
            onClick={() => this.addReminder(ReminderTypeEnum.Bill)}
          >
            <IonIcon icon={exitOutline} />
            &nbsp; Bill
          </IonFabButton>
          <IonFabButton
            color='tertiary'
            className='reminder-small-fab-button small'
            onClick={() => this.addReminder(ReminderTypeEnum.Income)}
          >
            <IonIcon icon={enterOutline} />
            &nbsp; Income
          </IonFabButton>
          <IonFabButton
            color='tertiary'
            className='reminder-small-fab-button small'
            onClick={() => this.addReminder(ReminderTypeEnum.Transfer)}
          >
            <IonIcon icon={repeatOutline} />
            &nbsp; Transfer
          </IonFabButton>
          <IonFabButton
            color='tertiary'
            className='reminder-small-fab-button small'
            onClick={() => this.addReminder(ReminderTypeEnum.To_Do)}
          >
            <IonIcon icon={checkmarkCircleOutline} />
            &nbsp; To Do
          </IonFabButton>
        </IonFabList>
      </IonFab>
    )
  }

  renderRefresher() {
    const listVM = this.props.remindersStore.listVM
    if (!listVM) return null
    return (
      <Observer>
        {() => {
          return (
            <IonRefresher slot='fixed' onIonRefresh={(e) => listVM.refresh(e)} disabled={!listVM.atTop}>
              <IonRefresherContent />
            </IonRefresher>
          )
        }}
      </Observer>
    )
  }

  render() {
    return (
      <Observer>
        {() => {
          return (
            <>
              {this.props.appStore.showAppHeader && (
                <IonHeader>
                  <IonToolbar />
                </IonHeader>
              )}
              <IonHeader id='RemindersListPage_Header'>
                <IonToolbar color='secondary'>
                  <IonTitle>Reminders</IonTitle>
                </IonToolbar>
              </IonHeader>
              <IonContent
              scrollY={false}
                id='RemindersListPage_Content'
                style={{ height: this.props.appStore.listHeightPx }}
              >
                {this.renderNoItemsMessage()}
                {this.renderRefresher()}
                <RemindersList />
                {this.renderFab()}
              </IonContent>
            </>
          )
        }}
      </Observer>
    )
  }
}
