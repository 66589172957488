import React from 'react'
import { observer, inject } from 'mobx-react'
import { AppStore } from '../../../stores/AppStore'
import { RootStore } from '../../../stores/RootStore'
import { match } from 'react-router'
import { BankTransactionsStore } from '../../store/BankTransactionsStore'
import BankTransactionEditArea from './BankTransactionEditArea'

interface Props {
  bankTransactionsStore: BankTransactionsStore
  appStore: AppStore
  rootStore: RootStore
  history: any
  match: match
}

@inject('bankTransactionsStore')
@inject('appStore')
@observer
export default class BankTransactiontEditPage extends React.Component<Props> {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    const id = this.props.match.params['id']
    this.props.appStore.setCurrentRoute(this.props.match.url)
    this.props.bankTransactionsStore.lazyLoadEditVM(id)
  }

  componentWillUnmount() {
    this.props.bankTransactionsStore.lazyLoadEditVM('empty')
  }

  render() {
    return <BankTransactionEditArea />
  }
}
