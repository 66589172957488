import { observable, computed, makeObservable } from 'mobx'
import { IPlaidItemDTO } from '../dtos/IPlaidItemDTO'
import { serialize, deserialize, serializable, list, object } from 'serializr'
import { IAggregrate } from '../../shared/IAggregate'
import { Account } from './Account'

export class PlaidItem implements IPlaidItemDTO, IAggregrate {
  constructor() {
    makeObservable(this)
  }

  @serializable @observable public PlaidItemGuid: string
  @serializable @observable public BoardId: number
  @serializable @observable public ItemId: string
  @serializable @observable public AccessToken: string
  @serializable @observable public InstitutionId: string
  @serializable @observable public InstitutionName: string
  @serializable(list(object(Account))) @observable public Accounts: Account[] = []
  @serializable @observable public IsDeleted: boolean
  @serializable @observable public NeedsUpdating: boolean

  public toDTO(): IPlaidItemDTO {
    return serialize(this)
  }
}
