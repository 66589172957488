import React from 'react'
import { inject, observer } from 'mobx-react'
import { AppStore } from '../../../stores/AppStore'
import { IonItem, IonLabel, IonInput, IonSelect, IonSelectOption, IonGrid, IonRow, IonCol } from '@ionic/react'
import { TransactionsStore } from '../../store/TransactionsStore'
import { DatePicker } from '@material-ui/pickers'
import { TransactionEditVM } from '../../view-models/TransactionEditVM'

interface Props {
  transactionsStore?: TransactionsStore
  appStore?: AppStore
}

const DetailsForm: React.FC<Props> = ({ transactionsStore, appStore }) => {
  const editVM = transactionsStore.editVM as TransactionEditVM
  if (!editVM) return null
  if (appStore.bodyHeight === 0) return null

  const renderPayeeName = () => {
    return (
      <IonItem lines={'inset'}>
        <IonLabel position='floating'>Payee</IonLabel>
        <IonInput type='text' value={editVM.payeeName} onIonChange={(e: any) => editVM.setPayeeName(e.target.value)} />
      </IonItem>
    )
  }

  const renderNotes = () => {
    return (
      <IonItem lines={'inset'}>
        <IonLabel position='floating'>Notes</IonLabel>
        <IonInput type='text' value={editVM.notes} onIonChange={(e: any) => editVM.setNotes(e.target.value)} />
      </IonItem>
    )
  }

  const renderCategory = () => {
    return (
      <IonItem lines={'inset'}>
        <IonLabel position='floating'>Category</IonLabel>
        <IonSelect
          interface='popover'
          value={editVM.categoryGuid}
          onIonChange={(e: any) => editVM.setCategory(e.target.value)}
        >
          {editVM.categories.map((e) => (
            <IonSelectOption key={e.CategoryGuid} value={e.CategoryGuid}>
              {e.Name}
            </IonSelectOption>
          ))}
        </IonSelect>
      </IonItem>
    )
  }

  const renderStatus = () => {
    return (
      <IonItem lines={'inset'}>
        <IonLabel position='floating'>Status</IonLabel>
        <IonSelect
          interface='popover'
          value={editVM.transactionStatusId}
          onIonChange={(e: any) => editVM.setStatus(e.target.value)}
        >
          {editVM.statuses.map((e) => (
            <IonSelectOption key={e.id} value={e.id}>
              {e.name}
            </IonSelectOption>
          ))}
        </IonSelect>
      </IonItem>
    )
  }

  const renderType = () => {
    return (
      <IonItem lines={'inset'}>
        <IonLabel position='floating'>Type</IonLabel>
        <IonSelect
          interface='popover'
          value={editVM.transactionTypeId}
          onIonChange={(e: any) => editVM.setTransactionType(e.target.value)}
        >
          {editVM.transactionTypes.map((e) => (
            <IonSelectOption key={e.id} value={e.id}>
              {e.name}
            </IonSelectOption>
          ))}
        </IonSelect>
      </IonItem>
    )
  }

  const renderAmount = () => {
    return (
      <IonItem lines={'inset'}>
        <IonLabel position='floating'>Amount</IonLabel>
        <IonInput type='number' value={editVM.amount} onIonChange={(e: any) => editVM.setAmount(e.target.value)} />
      </IonItem>
    )
  }

  const renderDate = () => {
    return (
      <IonItem lines={'inset'} unselectable='on'>
        <IonLabel position='floating' className='forced-floating'>
          Date
        </IonLabel>
        <DatePicker
          unselectable='on'
          value={editVM.transactionDate}
          onChange={(e) => editVM.setTransactionDate(e)}
          format='dddd MMM D'
        />
      </IonItem>
    )
  }

  return (
    <form
      id='DetailsForm'
      onSubmit={(e) => {
        e.preventDefault()
        editVM.save()
      }}
    >
      <IonGrid>
        <IonRow>
          <IonCol sizeXs='12'>{renderPayeeName()}</IonCol>
        </IonRow>
        <IonRow>
          <IonCol sizeMd='6' sizeXs='6'>
            {renderAmount()}
          </IonCol>
          <IonCol sizeMd='6' sizeXs='6'>
            {renderType()}
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol sizeMd='6' sizeXs='6'>
            {renderStatus()}
          </IonCol>
          <IonCol sizeMd='6' sizeXs='6'>
            {renderDate()}
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol sizeXs='12'>{renderCategory()}</IonCol>
        </IonRow>
        <IonRow>
          <IonCol sizeXs='12'>{renderNotes()}</IonCol>
        </IonRow>
      </IonGrid>
    </form>
  )
}

export default inject('transactionsStore', 'appStore')(observer(DetailsForm))
