import * as Sentry from '@sentry/browser'
import { AppStore } from './AppStore'
import { AuthStore } from '../login/store/AuthStore'
import { ActionsStore } from '../actions/store/ActionsStore'
import { CategoriesStore } from '../categories/store/CategoriesStore'
import { BoardsStore } from '../boards/store/BoardsStore'
import { NotificationsStore } from '../notifications/NotificationsStore'
import { BoardInvitationsStore } from '../board-invitations/store/BoardInvitationsStore'
import { UserStore } from '../user/store/UserStore'
import { delay } from '../shared/delay'
import { RootStore as ElexiAppRootStore } from '@elexient/elexiapp.bits.shared'
import { RemindersStore } from '../reminders/store/RemindersStore'
import { AccountsStore } from '../accounts/store/AccountsStore'
import { TransactionsStore } from '../transactions/store/TransactionsStore'
import { BankTransactionsStore } from '../bank-transactions/store/BankTransactionsStore'
import { PlaidItemsStore } from '../plaid-items/store/PlaidItemsStore'
import { UpcomingBillsStore } from '../upcoming-bills/store/UpcomingBillsStore'

export class RootStore extends ElexiAppRootStore {
  constructor() {
    super()
    this.appStore = this.addStore<AppStore>(AppStore)
    this.authStore = this.addStore<AuthStore>(AuthStore)
    this.boardsStore = this.addStore<BoardsStore>(BoardsStore)
    this.remindersStore = this.addStore<RemindersStore>(RemindersStore)
    this.accountsStore = this.addStore<AccountsStore>(AccountsStore)
    this.transactionsStore = this.addStore<TransactionsStore>(TransactionsStore)
    this.actionsStore = this.addStore<ActionsStore>(ActionsStore)
    this.categoriesStore = this.addStore<CategoriesStore>(CategoriesStore)
    this.userStore = this.addStore<UserStore>(UserStore)
    this.boardInvitationsStore = this.addStore<BoardInvitationsStore>(BoardInvitationsStore)
    this.notificationsStore = this.addStore<NotificationsStore>(NotificationsStore)
    this.bankTransactionsStore = this.addStore<BankTransactionsStore>(BankTransactionsStore)
    this.plaidItemsStore = this.addStore<PlaidItemsStore>(PlaidItemsStore)
    this.upcomingBillsStore = this.addStore<UpcomingBillsStore>(UpcomingBillsStore)
  }

  public appStore: AppStore
  public authStore: AuthStore
  public actionsStore: ActionsStore
  public categoriesStore: CategoriesStore
  public accountsStore: AccountsStore
  public boardsStore: BoardsStore
  public userStore: UserStore
  public boardInvitationsStore: BoardInvitationsStore
  public notificationsStore: NotificationsStore
  public transactionsStore: TransactionsStore
  public remindersStore: RemindersStore
  public bankTransactionsStore: BankTransactionsStore
  public plaidItemsStore: PlaidItemsStore
  public upcomingBillsStore: UpcomingBillsStore

  private loading: boolean = false
  private delayMS: number = 0

  public loadData(reason: string, attempts: number = 1) {
    if (this.loading && reason !== 'user logged in') {
      console.log('loading already')
      return
    }
    if (process.env.NODE_ENV !== 'test') console.log('loading data: ' + reason + ' attempt: ' + attempts)
    attempts++
    if (attempts === 7) return delay(1)
    if (!this.appStore.isLoggedIn) {
      return delay(2000).then(() => this.loadData(reason, attempts))
    }
    if (process.env.NODE_ENV === 'test') {
      const proms = [this.boardsStore.loadData(), this.accountsStore.loadData()]
      return Promise.all(proms)
    }
    const proms = [
      delay(this.getMoreTime(1000)).then(() => {
        const name = 'boards'
        console.time(name)
        this.appStore.setLoadingData(name)
        return this.boardsStore.loadData().then(() => console.timeEnd(name))
      }),
      delay(this.getMoreTime(200)).then(() => {
        const name = 'user'
        console.time(name)
        this.appStore.setLoadingData(name)
        return this.userStore.loadUser().then(() => console.timeEnd(name))
      }),
      delay(this.getMoreTime(200)).then(() => {
        const name = 'categories'
        console.time(name)
        this.appStore.setLoadingData(name)
        return this.categoriesStore.loadData().then(() => console.timeEnd(name))
      }),
      delay(this.getMoreTime(200)).then(() => {
        const name = 'accounts'
        console.time(name)
        this.appStore.setLoadingData(name)
        return this.accountsStore.loadData().then(() => console.timeEnd(name))
      }),
      delay(this.getMoreTime(200)).then(() => {
        const name = 'notifications'
        console.time(name)
        this.appStore.setLoadingData(name)
        return this.notificationsStore.processNotifications().then(() => console.timeEnd(name))
      }),
      delay(this.getMoreTime(200)).then(() => {
        const name = 'reminders'
        console.time(name)
        this.appStore.setLoadingData(name)
        return this.remindersStore.loadData().then(() => console.timeEnd(name))
      }),
      delay(this.getMoreTime(200)).then(() => {
        const name = 'boardinvitations'
        console.time(name)
        this.appStore.setLoadingData(name)
        return this.boardInvitationsStore.loadData().then(() => console.timeEnd(name))
      }),
      delay(this.getMoreTime(200)).then(() => {
        const name = 'notifications'
        console.time(name)
        this.appStore.setLoadingData(name)
        return this.notificationsStore.processNotifications().then(() => console.timeEnd(name))
      }),
      delay(this.getMoreTime(200)).then(() => {
        const name = 'plaidItems'
        console.time(name)
        this.appStore.setLoadingData(name)
        return this.plaidItemsStore.loadData().then(() => console.timeEnd(name))
      }),
      delay(this.getMoreTime(200)).then(() => {
        const name = 'upcomingBills'
        console.time(name)
        this.appStore.setLoadingData(name)
        return this.upcomingBillsStore.loadData().then(() => console.timeEnd(name))
      }),
      delay(this.getMoreTime(200)).then(() => {
        const name = 'bankTransactions'
        console.time(name)
        this.appStore.setLoadingData(name)
        return this.bankTransactionsStore.loadData().then(() => console.timeEnd(name))
      }),
    ]
    console.log('loading all data')
    this.loading = true
    return delay(1000)
      .then(() => Promise.all(proms).then(() => (this.loading = false)))
      .catch((e) => {
        Sentry.captureException(e)
        console.log(e)
      })
  }

  private getMoreTime(ms) {
    this.delayMS = this.delayMS + ms
    return this.delayMS
  }
}
