import React, { useEffect } from 'react'
import {
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonText,
  IonBadge,
  IonSpinner,
  IonCard,
  IonCardContent,
  IonCheckbox,
  IonCardHeader,
  IonTitle,
} from '@ionic/react'
import { inject, observer } from 'mobx-react'
import '../styles/RegisterForm.scss'
import GoogleSmallLogo from '../../assets/images/Google_Logo_400.png'
import AppleSmallLogo from '../../assets/images/Apple_Logo_400.png'
import { AuthStore } from '../../login/store/AuthStore'

interface Props {
  authStore?: AuthStore
}

const RegisterForm: React.FC<Props> = ({ authStore }) => {
  const { registerVM: vm } = authStore
  if (!vm) return null

  const renderErrorMessages = () => {
    if (!vm.errorMessages) return
    return vm.errorMessages.map((msg, idx) => (
      <div className='error' key={'msg' + idx}>
        <IonBadge color='danger'>{msg}</IonBadge>
      </div>
    ))
  }

  const renderBoardInvitationInfo = () => {
    if (!vm.boardInvitation) return
    return (
      <IonCard className='board-info'>
        <IonCardContent>
          <strong>{vm.boardInvitation.FromName}</strong> has invited you to join the board &nbsp;
          <strong>{vm.boardInvitation.BoardName}</strong>
          <IonItem lines='none' onClick={() => vm.toggleAcceptBoardInvitation()}>
            <IonCheckbox checked={vm.acceptBoardInvitation} />
            &nbsp;&nbsp;Accept Invitation
          </IonItem>
        </IonCardContent>
      </IonCard>
    )
  }

  const renderSpinner = () => {
    if (!vm.showSpinner) return
    if (vm.isIos) return <IonSpinner />
    return <IonSpinner name='crescent' />
  }

  const renderPassword = () => {
    if (vm.hasExternalAccount) return
    return (
      <IonItem>
        <IonLabel position='floating' className={vm.hasPassword ? 'forced-floating' : ''}>
          Password
        </IonLabel>
        <IonInput
          type='password'
          id='password'
          name='sadfdasf'
          value={vm.password}
          onIonChange={(e: any) => vm.setPassword(e.target.value)}
          ref={() => vm.disableAutoComplete()}
        />
      </IonItem>
    )
  }

  const renderPasswordConfirm = () => {
    if (vm.hasExternalAccount) return
    return (
      <IonItem>
        <IonLabel position='floating' className={vm.hasPasswordConfirm ? 'forced-floating' : ''}>
          Confirm Password
        </IonLabel>
        <IonInput
          type='password'
          id='password'
          name='sadfdasfdasf'
          value={vm.passwordConfirm}
          onIonChange={(e: any) => vm.setPasswordConfirm(e.target.value)}
          ref={() => vm.disableAutoComplete()}
        />
      </IonItem>
    )
  }

  const renderEmail = () => {
    return (
      <IonItem>
        <IonLabel position='floating' className={vm.hasEmail ? 'forced-floating' : ''}>
          Email
        </IonLabel>
        <IonInput
          type='text'
          id='email'
          name='asdfdasf'
          value={vm.email}
          onIonChange={(e: any) => vm.setEmail(e.target.value)}
          ref={() => vm.disableAutoComplete()}
        />
      </IonItem>
    )
  }

  const renderName = () => {
    return (
      <IonItem>
        <IonLabel position='floating' className={vm.hasName ? 'forced-floating' : ''}>
          Name
        </IonLabel>
        <IonInput
          type='text'
          name='asdfdasfdasf'
          value={vm.name}
          onIonChange={(e: any) => vm.setName(e.target.value)}
          ref={() => vm.disableAutoComplete()}
        />
      </IonItem>
    )
  }

  const renderBoardName = () => {
    return (
      <IonItem>
        <IonLabel position='floating' className={vm.hasName ? 'forced-floating' : ''}>
          New Board Name (optional)
        </IonLabel>
        <IonInput
          type='text'
          name='asdfdasfdaasdfsdfsf'
          value={vm.newBoardName}
          onIonChange={(e: any) => vm.setBoardName(e.target.value)}
          ref={() => vm.disableAutoComplete()}
        />
      </IonItem>
    )
  }

  const renderContinueWithGoogle = () => {
    if (vm.hasExternalAccount) return
    return (
      <>
        <IonButton
          className='google-button'
          expand='full'
          type='button'
          color='light'
          onClick={() => vm.continueWithGoogle()}
        >
          <img src={GoogleSmallLogo} alt='Google' />
          &nbsp; Continue with Google
          {/* {renderGoogleSpinner()} */}
        </IonButton>
      </>
    )
  }

  const renderContinueWithApple = () => {
    if (vm.hasExternalAccount) return
    if (!vm.canSignInWithApple) return
    return (
      <>
        <IonButton
          className='apple-button'
          expand='full'
          type='button'
          color='light'
          onClick={() => vm.continueWithApple()}
        >
          <img src={AppleSmallLogo} alt='Apple' />
          &nbsp; Continue with Apple
          {/* {renderGoogleSpinner()} */}
        </IonButton>
      </>
    )
  }

  const renderOr = () => {
    if (vm.hasExternalAccount) return
    return <IonTitle className='or'>OR</IonTitle>
  }

  return (
    <IonCard id='RegisterForm'>
      <IonCardHeader>New Account</IonCardHeader>
      <IonCardContent>
        <form
          autoComplete='off'
          onSubmit={e => {
            e.preventDefault()
            vm.submit()
          }}
        >
          {renderBoardInvitationInfo()}

          <IonList>
            {renderName()}
            {renderEmail()}
            {renderPassword()}
            {renderPasswordConfirm()}
            {/* {renderBoardName()} */}
            {/* {renderInviteAnother()} */}
          </IonList>

          <IonButton className='register-button' expand='full' type='submit' disabled={!vm.isValid}>
            Create Account
            {renderSpinner()}
          </IonButton>
          {renderOr()}
          {renderContinueWithGoogle()}
          {renderContinueWithApple()}
          {renderErrorMessages()}
        </form>
      </IonCardContent>
    </IonCard>
  )
}

export default inject('authStore')(observer(RegisterForm))
