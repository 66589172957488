import agent from '../../Agent'
import { RootStore } from '../../stores/RootStore'
import { ICategoryDTO } from '../dtos/ICategoryDTO'
import { ActionTypeEnum } from '../../actions/enum/ActionTypeEnum'

export class CategoriesService {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  public save(dto: ICategoryDTO) {
    this.rootStore.categoriesStore.updateFromServer(dto)
    this.rootStore.actionsStore.addAction(ActionTypeEnum.CATEGORY_SAVE, dto)
  }

  public async saveOnServer(dto: ICategoryDTO) {
    const savedDTO = await agent.Categories.save(dto)
    this.rootStore.categoriesStore.updateFromServer(savedDTO)
  }

  public delete(dto: ICategoryDTO) {
    dto.IsDeleted = true
    this.save(dto)
  }
}
