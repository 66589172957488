import { computed, has, makeObservable } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { ReminderTypeEnum } from '../../reminders/enum/ReminderTypeEnum'
import { UpcomingBillRowVM } from './UpcomingBillRowVM'

export class UpcomingBillsWidgetVM {
  constructor(private rootStore: RootStore) {
    makeObservable(this)
  }

  @computed
  public get rows(): UpcomingBillRowVM[] {
    return this.rootStore.upcomingBillsStore.currentBoardRecords
      .map((e) => new UpcomingBillRowVM(this.rootStore, e))
      .sort((a, b) => (b.transactionDate.isBefore(a.transactionDate) ? 0 : -1))
  }

  @computed
  public get bodyHeight(): number {
    return this.rootStore.appStore.bodyHeight
  }
}
