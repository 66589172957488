import { GroupRowVM } from '@elexient/elexiapp.bits.shared'
import { computed } from 'mobx'
import { RootStore as ThisRootStore } from '../../stores/RootStore'
import { PlaidItemAccountsListVM } from './PlaidItemAccountsListVM'

export class PlaidItemAccountGroupRowVM extends GroupRowVM<ThisRootStore, PlaidItemAccountsListVM> {
  @computed
  public get displayName(): string {
    return this.name.substring(1)
  }

  public get color(): string {
    throw new Error('Method not implemented.')
  }
}
