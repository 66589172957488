import React from 'react'
import { IonButton, IonModal, IonText, IonTitle, IonIcon, IonSpinner } from '@ionic/react'
import { observer, inject } from 'mobx-react'
import { MaterialCommunityIcons } from 'react-web-vector-icons'
import { checkmark } from 'ionicons/icons'
import { BoardInvitationsStore } from '../store/BoardInvitationsStore'
import './../styles/BoardInvitationAcceptModal.scss'

interface Props {
  boardInvitationsStore?: BoardInvitationsStore
}

const BoardInvitationAcceptModal: React.FC<Props> = ({ boardInvitationsStore }) => {
  const { acceptVM: vm } = boardInvitationsStore
  if (!vm) return null

  const handleAcceptClick = e => {
    e.preventDefault()
    vm.accept()
  }

  const handleDeclineClick = e => {
    e.preventDefault()
    vm.decline()
  }

  const handleDismiss = () => {
    if (!vm.isModalShown) return
    vm.toggle()
  }

  const renderSpinner = () => {
    if (!vm.isProcessing) return
    return <div id='spinner-container'>{renderActualSpinner()}</div>
  }

  const renderActualSpinner = () => {
    if (vm.isIos) return <IonSpinner />
    return <IonSpinner name='crescent' />
  }

  const renderButtons = () => {
    if (vm.isProcessing) return
    return (
      <div id='buttons'>
        <IonButton type='button' size='small' color='secondary' onClick={e => handleDeclineClick(e)}>
          Decline
        </IonButton>
        <IonButton type='button' size='small' color='tertiary' onClick={e => handleAcceptClick(e)}>
          <IonIcon icon={checkmark} />
          Accept
        </IonButton>
      </div>
    )
  }

  return (
    <IonModal
      id='BoardInvitationAcceptModal'
      isOpen={vm.isModalShown}
      showBackdrop={true}
      onDidDismiss={() => handleDismiss()}
    >
      <IonTitle>
        <MaterialCommunityIcons name='email-check-outline' size={20} />
        &nbsp;&nbsp;Board Invitation
      </IonTitle>

      <IonText>
        <strong>{vm.fromName}</strong> has invited you to collaborate on the board <strong>{vm.boardName}</strong>.
        <br />
        <br />
        Would you like to accept this invitation?
      </IonText>
      {renderSpinner()}
      {renderButtons()}
    </IonModal>
  )
}

export default inject('boardInvitationsStore')(observer(BoardInvitationAcceptModal))
