import { computed, makeObservable } from 'mobx'
import { RootStore } from '../../../stores/RootStore'
import Sugar from 'sugar'

export class NetWorthWidgetVM {
  constructor(private rootStore: RootStore) {
    makeObservable(this)
  }

  @computed
  public get netWorthAmount(): number {
    let amt = 0
    this.rootStore.accountsStore.currentBoardRecords.forEach((e) => (amt += e.ClearedBalance))
    return amt
  }

  @computed
  public get netWorth(): string {
    return Sugar.Number(this.netWorthAmount).format(0).raw
  }

  @computed
  public get bodyHeight(): number {
    return this.rootStore.appStore.bodyHeight
  }
}
