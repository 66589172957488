import { RootStore } from '../../../stores/RootStore'
import { computed, action } from 'mobx'
import { RemindersListVM } from './RemindersListVM'
import { Reminder } from '../../aggregate/Reminder'
import { RowVM } from '@elexient/elexiapp.bits.shared'
import moment from 'moment'
import Sugar from 'sugar'
import { ReminderTypeEnumName } from '../../enum/ReminderTypeEnumName'
import { Category } from '../../../categories/aggregate/Category'

export class ReminderRowVM extends RowVM<RootStore, RemindersListVM, Reminder> {
  constructor(rootStore: RootStore, listVM: RemindersListVM, reminder: Reminder, self: any) {
    super(rootStore, listVM, reminder, self)
  }

  @computed
  public get keyword(): string {
    return this.record.Name
  }

  @computed
  public get name(): string {
    return this.record.Name
  }

  @computed
  public get key(): string {
    return this.record.ReminderGuid
  }

  @computed
  public get color(): string {
    return this.record.Color
  }

  @action
  public click() {
    this.rootStore.appStore.history.push('/reminderedit/' + this.key)
  }

  @computed
  public get notes(): string {
    return this.record.Notes
  }

  @computed
  public get hasNotes(): boolean {
    return this.record.hasNotes
  }

  @computed
  public get amount(): string {
    let str = Sugar.Number(this.record.Amount).format(2).raw
    if (!this.record.IsPositive) str = '-' + str
    return str
  }

  @computed
  public get isPositive(): boolean {
    return this.record.IsPositive
  }

  @computed
  public get day(): string {
    return Sugar.Number.pad(this.record.Schedule?.day, 2)
  }

  @computed
  public get accountName(): string {
    if (this.typeName === 'To Do') return '_To Do'
    if (!this.record.AccountGuid) return 'Other'
    const acc = this.rootStore.accountsStore.get(this.record.AccountGuid)
    if (!acc) return ''
    return acc.Name
  }

  @computed
  public get typeName(): string {
    return ReminderTypeEnumName.fromId(this.record.TypeId)
  }

  @computed
  public get autoPaid(): boolean {
    return this.record.AutoPaid
  }

  public get height(): number {
    if (this.isHidden) return 0
    return 44
  }

  @computed
  public get hasCategory(): boolean {
    return Boolean(this.category)
  }

  @computed
  private get category(): Category {
    if (!this.record.CategoryGuid) return undefined
    return this.rootStore.categoriesStore.get(this.record.CategoryGuid)
  }

  @computed
  public get categoryName(): string {
    if (!this.hasCategory) return ''
    return this.category.Name
  }

  @computed
  public get categoryColor(): string {
    if (!this.hasCategory) return ''
    return this.category.Color
  }
}
