import React from 'react'
import {
  IonContent,
  IonIcon,
  IonButton,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonFab,
  IonFabButton,
  IonFabList,
  IonRefresher,
  IonRefresherContent,
} from '@ionic/react'
import { observer, inject, Observer } from 'mobx-react'
import { AppStore } from '../../../stores/AppStore'
import { TransactionsStore } from '../../store/TransactionsStore'
import { match } from 'react-router'
import TransactionsList from './TransactionsList'
import TransactionsListPageMoreMenu from './TransactionsListPageMoreMenu'
import BackIcon from '../../../shared/BackIcon'
import { add, exitOutline, enterOutline, repeatOutline, cardOutline, cashOutline } from 'ionicons/icons'
import { NewTransactionTypeEnum } from '../../enum/NewTransactionTypeEnum'

interface Props {
  transactionsStore: TransactionsStore
  appStore: AppStore
  match: match
}

@inject('transactionsStore')
@inject('appStore')
export default class TransactionsListPage extends React.Component<Props> {
  private accountGuid: string = undefined

  constructor(props) {
    super(props)
    this.accountGuid = this.props.match.params['accountGuid']
  }

  componentDidMount() {
    this.props.appStore.setCurrentRoute('/transactionslist/' + this.accountGuid)
    this.props.transactionsStore.loadListVM(this.accountGuid)
  }

  renderNoItemsMessage() {
    if (this.props.transactionsStore.hasTransactionsForAccount(this.accountGuid)) return null
    return (
      <div className='no-items'>
        This account has no transactions.
        <br />
        <br />
        Go deposit some money, so you can log it here... :)
      </div>
    )
  }

  renderAppHeader() {
    if (!this.props.appStore.showAppHeader) return
    return (
      <IonHeader>
        <IonToolbar />
      </IonHeader>
    )
  }

  handleGoBack() {
    this.props.appStore.handleGoBack()
  }

  renderAccountName() {
    return (
      <Observer>
        {() => {
          const listVM = this.props.transactionsStore.listVM
          if (!listVM) return null
          return <IonTitle>{listVM.accountName}</IonTitle>
        }}
      </Observer>
    )
  }

  addTransaction(newType: NewTransactionTypeEnum) {
    const listVM = this.props.transactionsStore.listVM
    if (!listVM) return
    listVM.addTransaction(newType)
  }

  renderFab() {
    return (
      <IonFab vertical='bottom' horizontal='end'>
        <IonFabButton color='tertiary'>
          <IonIcon icon={add} />
        </IonFabButton>
        <IonFabList side='top'>
          <IonFabButton
            color='tertiary'
            className='expense'
            onClick={() => this.addTransaction(NewTransactionTypeEnum.Expense)}
          >
            <IonIcon icon={exitOutline} />
            &nbsp; Expense
          </IonFabButton>
          <IonFabButton
            color='tertiary'
            className='income'
            onClick={() => this.addTransaction(NewTransactionTypeEnum.Income)}
          >
            <IonIcon icon={enterOutline} />
            &nbsp; Income
          </IonFabButton>
          <IonFabButton
            color='tertiary'
            className='transfer'
            onClick={() => this.addTransaction(NewTransactionTypeEnum.Transfer)}
          >
            <IonIcon icon={repeatOutline} />
            &nbsp; Transfer
          </IonFabButton>
          <IonFabButton
            color='tertiary'
            className='cc-payment'
            onClick={() => this.addTransaction(NewTransactionTypeEnum.Credit_Card_Payment)}
          >
            <IonIcon icon={cardOutline} />
            &nbsp; Credit Card Payment
          </IonFabButton>
        </IonFabList>
      </IonFab>
    )
  }

  renderTransactionsList() {
    if (!this.props.transactionsStore.hasTransactionsForAccount(this.accountGuid)) return null
    return <TransactionsList />
  }

  renderRefresher() {
    return (
      <Observer>
        {() => {
          const listVM = this.props.transactionsStore.listVM
          if (!listVM) return null
          return (
            <IonRefresher slot='fixed' onIonRefresh={(e) => listVM.onPullToRefresh(e)} disabled={!listVM.atTop}>
              <IonRefresherContent />
            </IonRefresher>
          )
        }}
      </Observer>
    )
  }

  render() {
    return (
      <Observer>
        {() => {
          return (
            <>
              {this.renderAppHeader()}
              <IonHeader id='TransactionsListPage_Header'>
                <IonToolbar color='secondary'>
                  <IonButtons slot='start'>
                    <IonButton onClick={() => this.handleGoBack()}>
                      <BackIcon />
                    </IonButton>
                  </IonButtons>
                  {this.renderAccountName()}
                  <IonButtons slot='end'>
                    <TransactionsListPageMoreMenu />
                  </IonButtons>
                </IonToolbar>
              </IonHeader>
              <IonContent id='TransactionsListPage_Content' style={{ height: this.props.appStore.listHeightPx }}>
                {this.renderRefresher()}
                {this.renderTransactionsList()}
                {this.renderNoItemsMessage()}
                {this.renderFab()}
              </IonContent>
            </>
          )
        }}
      </Observer>
    )
  }
}
