import { observable, action, makeObservable, computed } from 'mobx'
import generateUUID from '../../utils/UUID'
import { IReminderDTO } from '../dtos/IReminderDTO'
import { serialize, deserialize, serializable, object, list } from 'serializr'
import { IAggregrate } from '../../shared/IAggregate'
import { Schedule } from '../../schedules/aggregate/Schedule'
import Sugar from 'sugar'
import { ReminderTypeEnum } from '../enum/ReminderTypeEnum'
import { Transaction } from './Transaction'

export class Reminder implements IReminderDTO, IAggregrate {
  public static create(boardId: number) {
    const rem = new Reminder()
    rem.ReminderGuid = generateUUID()
    rem.BoardId = boardId
    rem.Schedule = Schedule.create()
    return rem
  }

  constructor() {
    makeObservable(this)
  }

  @serializable @observable public ReminderGuid: string = ''
  @serializable @observable public Name: string = ''
  @serializable @observable public Color: string = ''
  @serializable @observable public Notes: string = ''
  @serializable @observable public BoardId: number = 0
  @serializable @observable public TypeId: number = 0
  @serializable @observable public IsDeleted: boolean = false
  @serializable @observable public AccountGuid: string = undefined
  @serializable @observable public CategoryGuid: string = undefined
  @serializable @observable public Amount: number = 0
  @serializable @observable public AutoPaid: boolean = false
  @serializable @observable public SendPastDueNotification: boolean = false
  @serializable(object(Schedule)) @observable public Schedule: Schedule
  @serializable(list(object(Transaction))) @observable public Transactions: Transaction[] = []

  @action
  public setAmount(val: number) {
    this.Amount = val
  }

  @action
  public setAccount(val: string) {
    this.AccountGuid = val
  }

  @action
  public setName(val: string) {
    this.Name = val
  }

  @action
  public setType(val: number) {
    this.TypeId = Number(val)
  }

  @action
  public setNotes(val: string) {
    this.Notes = val
  }

  @action
  public setColor(val: string) {
    this.Color = val
  }

    @action
  public setCategory(val: string) {
    this.CategoryGuid = val
  }

  @computed
  public get hasNotes(): boolean {
    return Boolean(this.Notes)
  }

  @computed
  public get IsPositive() {
    return Boolean(this.TypeId === ReminderTypeEnum.Income)
  }

  @computed
  public get day(): string {
    return Sugar.Number.pad(this.Schedule?.day, 2)
  }

  @action
  public toggleAutoPaid() {
    this.AutoPaid = !this.AutoPaid
  }
  
  @action
  public toggleSendPastDueNotification() {
    this.SendPastDueNotification = !this.SendPastDueNotification
  }

  public clone(): Reminder {
    if (!this.Schedule) this.Schedule = Schedule.create()
    return deserialize(Reminder, serialize(this))
  }

  public toDTO(): IReminderDTO {
    return serialize(this)
  }
}
