import React from 'react'
import { IonContent, IonList, IonItem, IonLabel, IonInput, IonButton, IonPage, IonText } from '@ionic/react'
import { inject, observer } from 'mobx-react'
import { AppStore } from 'src/stores/AppStore'
import { AuthStore } from '../login/store/AuthStore'
import './ForgotPasswordPage.scss'
import { UserStore } from '../user/store/UserStore'

interface Props {
  appStore: AppStore
  authStore: AuthStore
  userStore: UserStore
  history: any
}

@inject('appStore')
@inject('authStore')
@inject('userStore')
@observer
export default class ForgotPasswordPage extends React.Component<Props, any> {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.props.appStore.setCurrentRoute('/forgot-password')
    if (this.props.appStore.isLoggedIn) this.props.history.push('/')
    this.props.appStore.themeGenVM.loadTheme('default')
  }

  async handleSubmit(e) {
    e.preventDefault()
    const success = await this.props.userStore.forgotPasswordForm.save()
    if (success) {
      alert('Success')
      this.props.history.push('/login')
    }
  }

  handleEmailChange(e) {
    const { forgotPasswordForm: form } = this.props.userStore
    form.setEmail(e.target.value)
  }

  handleBackClick() {
    this.props.history.push('/login')
  }

  renderErrorMessages() {
    const { forgotPasswordForm: form } = this.props.userStore
    const el = []
    form.errorMessages.forEach((e, idx) => {
      el.push(
        <span className='error-message' key={'msg' + idx}>
          {e}
        </span>
      )
      el.push(<br key={'br' + idx} />)
    })
    return el
  }

  render() {
    const authStore = this.props.authStore
    const loginVM = authStore.loginVM
    if (!loginVM) return null

    return (
      <IonPage id='ForgotPasswordPage'>
        <IonContent>
          <br />

          <IonText>
            <h1>Grocery Board</h1>
          </IonText>

          <br />

          <form onSubmit={this.handleSubmit.bind(this)}>
            <IonList>
              <IonItem>
                <IonLabel position='floating'>Email</IonLabel>
                <IonInput
                  type='text'
                  id='email2'
                  value={loginVM.email}
                  onIonChange={this.handleEmailChange.bind(this)}
                />
              </IonItem>
            </IonList>

            {this.renderErrorMessages()}

            <IonButton expand='full' type='submit' color='secondary'>
              Reset Password
            </IonButton>
            <br />
            <IonButton expand='full' type='button' color='secondary' onClick={() => this.handleBackClick()}>
              Back to Login
            </IonButton>
          </form>
        </IonContent>
      </IonPage>
    )
  }
}
