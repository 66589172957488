import React from 'react'
import { Observer, inject, observer, useLocalObservable } from 'mobx-react'
import {
  IonList,
  IonItem,
  IonButton,
  IonIcon,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonButtons,
  IonToolbar,
  IonItemOptions,
  IonItemSliding,
  IonText,
  IonBadge,
} from '@ionic/react'
import { cogSharp, refresh } from 'ionicons/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-regular-svg-icons'
import { UpcomingBillsWidgetVM } from '../view-models/UpcomingBillsWidgetVM'
import { RootStore } from '../../stores/RootStore'
import { UpcomingBillRowVM } from '../view-models/UpcomingBillRowVM'

interface Props {
  rootStore?: RootStore
}

const UpcomingBillsWidget: React.FC<Props> = ({ rootStore }) => {
  const vm = useLocalObservable(() => new UpcomingBillsWidgetVM(rootStore))
  if (!vm) return null
  if (vm.bodyHeight === 0) return null

  const renderCategory = (row: UpcomingBillRowVM) => {
    if (!row.categoryBadge) return null
    return (
      <IonBadge color='dark' style={{ backgroundColor: row.categoryBadge.color }}>
        {row.categoryBadge.name}
      </IonBadge>
    )
  }

  const renderAutoPaid = (row: UpcomingBillRowVM) => {
    if (!row.autoPaid) return
    return (
      <div className='auto-paid'>
        <IonIcon icon={refresh} size='small' />
      </div>
    )
  }

  const renderRows = () => {
    return vm.rows.map((row) => (
      <Observer key={row.key}>
        {() => (
          <IonItemSliding onClick={() => row.click()} key={row.key}>
            <IonItem lines='none' className={'normal-row'} onClick={() => row.click()}>
              <div className='date'>
                <div>
                  <div>{row.monthAbbr}</div>
                  <div>{row.day}</div>
                </div>
              </div>
              <div className='main-cell'>
                <div className='account'>{row.accountName}</div>
                <div className='payee'>{row.payeeName}</div>
                <div className={row.hasNotes ? 'notes' : 'notes no-notes'}>{row.notes}</div>
              </div>
              <div className='categories'>{renderCategory(row)}</div>
              {renderAutoPaid(row)}
              <IonText className='numbers'>
                <div className={row.isPositive ? 'positive' : 'negative'}>{row.amountFormatted}</div>
              </IonText>
            </IonItem>
            <IonItemOptions side='end'>
              {/* {renderSkipOption(row)}
              {renderPaidOption(row)}
              {renderClearedOption(row)}
              {renderUnskipOption(row)} */}
            </IonItemOptions>
          </IonItemSliding>
        )}
      </Observer>
    ))
  }

  return (
    <IonCard color='light' id='UpcomingBillsWidget' className='widget'>
      <IonCardHeader color='light'>
        <IonToolbar color='light'>
          <IonCardTitle>Upcoming Bills</IonCardTitle>
          <IonButtons slot='end'>
            <IonButton>
              <IonIcon icon={cogSharp} color='medium' />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonCardHeader>
      <IonCardContent>
        <IonList className='list'>{renderRows()}</IonList>
      </IonCardContent>
    </IonCard>
  )
}

export default inject('rootStore')(observer(UpcomingBillsWidget))
