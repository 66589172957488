import React from 'react'
import { observer, inject } from 'mobx-react'
import { AppStore } from '../../../stores/AppStore'
import { RootStore } from '../../../stores/RootStore'
import { match } from 'react-router'
import { CategoriesStore } from 'src/categories/store/CategoriesStore'
import CategoryEditArea from './CategoryEditArea'

interface Props {
  categoriesStore: CategoriesStore
  appStore: AppStore
  rootStore: RootStore
  history: any
  match: match
}

@inject('categoriesStore')
@inject('appStore')
export default class CategoryEditPage extends React.Component<Props> {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.props.appStore.setCurrentRoute(this.props.match.url)
    this.props.categoriesStore.lazyLoadEditVM(this.props.match.params['id'], this.props.match.params['newType'])
  }

  componentWillUnmount() {
    this.props.categoriesStore.lazyLoadEditVM('empty')
  }

  render() {
    return <CategoryEditArea />
  }
}
