import React from 'react'
import {
  IonContent,
  IonIcon,
  IonButton,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonAlert,
  IonFab,
  IonFabButton,
} from '@ionic/react'
import { observer, inject } from 'mobx-react'
import { close, trashOutline, checkmarkSharp } from 'ionicons/icons'
import { CategoriesStore } from '../../store/CategoriesStore'
import { AppStore } from '../../../stores/AppStore'
import InfoForm from './InfoForm'
import BackIcon from '../../../shared/BackIcon'

interface Props {
  categoriesStore?: CategoriesStore
  appStore?: AppStore
}

const CategoryEditArea: React.FC<Props> = ({ categoriesStore, appStore }) => {
  const { editVM } = categoriesStore
  if (!editVM) return null

  const renderAppHeader = () => {
    if (!appStore.showAppHeader) return
    return (
      <IonHeader>
        <IonToolbar />
      </IonHeader>
    )
  }

  const renderPageHeader = () => {
    return (
      <IonHeader id='CategoryEditPage_Header'>
        <IonToolbar color='secondary'>
          <IonButtons slot='start'>
            <IonButton onClick={() => editVM.goBack()}>
              <BackIcon />
            </IonButton>
          </IonButtons>
          <IonTitle>{editVM.pageTitle}</IonTitle>
          <IonButtons slot='end'>
            {!editVM.isNew && (
              <IonButton onClick={() => editVM.showDeleteConfirm()}>
                <IonIcon icon={trashOutline} />
              </IonButton>
            )}
            {editVM.isNew && (
              <IonButton onClick={() => editVM.goBack()}>
                <IonIcon icon={close} />
              </IonButton>
            )}
            <IonButton disabled={!editVM.isValid} onClick={() => editVM.save()}>
              <IonIcon icon={checkmarkSharp} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
    )
  }

  const renderDeleteConfirm = () => {
    if (!editVM.deleteConfirmShown) return
    return (
      <IonAlert
        isOpen={true}
        onDidDismiss={() => editVM.hideDeleteConfirm()}
        header={'Delete Category'}
        message={'Are you sure you wish to delete ' + editVM.name}
        buttons={[
          {
            text: 'No',
            role: 'cancel',
            cssClass: 'primary',
            handler: () => editVM.hideDeleteConfirm(),
          },
          {
            text: 'Yes',
            handler: () => editVM.delete(),
          },
        ]}
      />
    )
  }

  const renderFab = () => {
    return (
      <IonFab vertical='bottom' horizontal='end'>
        <IonFabButton
          color='tertiary'
          disabled={!editVM.isValid}
          onClick={() => editVM.save()}
          style={{
            marginTop: '-60px',
            marginLeft: '-60px',
          }}
        >
          <IonIcon icon={checkmarkSharp} />
        </IonFabButton>
      </IonFab>
    )
  }

  return (
    <>
      {renderAppHeader()}
      {renderPageHeader()}
      <IonContent
        id='CategoryEditPage_Content'
        ref={(e) => {
          // editVM.setContentRef(e)
          // appStore.applyScrollStyles(e)
        }}
      >
        <InfoForm />
      </IonContent>
      {renderDeleteConfirm()}
      {renderFab()}
    </>
  )
}

export default inject('categoriesStore', 'appStore')(observer(CategoryEditArea))
