import React from 'react'
import {
  IonContent,
  IonIcon,
  IonButton,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonRefresher,
  IonRefresherContent,
} from '@ionic/react'
import { observer, inject, Observer } from 'mobx-react'
import { add } from 'ionicons/icons'
import { RootStore } from '../../../stores/RootStore'
import AccountsList from './AccountsList'
import { AppStore } from '../../../stores/AppStore'
import { AccountsStore } from '../../store/AccountsStore'

interface Props {
  accountsStore: AccountsStore
  appStore: AppStore
  rootStore: RootStore
  history: any
}

interface State {
  isNewModalOpen: boolean
  isEditModalOpen: boolean
  editCategoryGuid: string
  isDeleteConfirmShown: boolean
  deleteCategoryGuid: string
  deleteCategoryName: string
}

@inject('accountsStore')
@inject('appStore')
export default class AccountsListPage extends React.Component<Props, State> {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.props.appStore.setCurrentRoute('/accountslist')
  }

  handleAddButtonClick() {
    this.setState({ isNewModalOpen: true })
  }

  handleEditClick(categoryGuid) {
    this.setState({
      isEditModalOpen: true,
      editCategoryGuid: categoryGuid,
    })
  }

  handleGoBack() {
    this.props.appStore.handleGoBack(this.props.history)
  }

  renderNoItemsMessage() {
    if (this.props.accountsStore.currentBoardRecords.length > 1) return null // uncategorized is static
    return (
      <div className='no-items'>
        You have no accounts set up!
        <br />
        Add some accounts to start your Grocery Board journey...
        <br />
        <br />
        <IonButton slot='icon-only' type='button' onClick={() => this.props.appStore.navigateTo('/accountedit/new')}>
          <IonIcon icon={add} />
        </IonButton>
      </div>
    )
  }

  renderRefresher() {
    const listVM = this.props.accountsStore.listVM
    if (!listVM) return null
    return (
      <Observer>
        {() => {
          return (
            <IonRefresher slot='fixed' onIonRefresh={(e) => listVM.refresh(e)} disabled={!listVM.atTop}>
              <IonRefresherContent />
            </IonRefresher>
          )
        }}
      </Observer>
    )
  }

  render() {
    return (
      <Observer>
        {() => {
          return (
            <>
              {this.props.appStore.showAppHeader && (
                <IonHeader>
                  <IonToolbar />
                </IonHeader>
              )}
              <IonHeader id='AccountsListPage_Header'>
                <IonToolbar color='secondary'>
                  <IonButtons slot='start'></IonButtons>
                  <IonTitle>Accounts</IonTitle>
                  <IonButtons slot='end'>
                    <IonButton onClick={() => this.props.appStore.navigateTo('/accountedit/new')}>
                      <IonIcon icon={add} />
                    </IonButton>
                  </IonButtons>
                </IonToolbar>
              </IonHeader>
              <IonContent
                id='AccountsListPage_Content'
                style={{ height: this.props.appStore.listHeightPx }}
              >
                {/* {this.renderNoItemsMessage()} */}
                {this.renderRefresher()}
                <AccountsList />
              </IonContent>
            </>
          )
        }}
      </Observer>
    )
  }
}
