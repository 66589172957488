import { RootStore } from '../../stores/RootStore'
import { computed, action, observable } from 'mobx'
import { RowVM } from '@elexient/elexiapp.bits.shared'
import { Account } from '../aggregate/Account'
import { PlaidItemAccountsListVM } from './PlaidItemAccountsListVM'

export class PlaidItemAccountRowVM extends RowVM<RootStore, PlaidItemAccountsListVM, Account> {
  constructor(rootStore: RootStore, listVM: PlaidItemAccountsListVM, reminder: Account, self: any) {
    super(rootStore, listVM, reminder, self)
  }

  @observable public runningTotal: number = 0

  public get height(): number {
    if (this.isHidden) return 0
    return 60
  }

  public get isEven(): boolean {
    return (this.normalRowIndex + 1) % 2 === 0
  }

  @computed
  public get keyword(): string {
    return this.name
  }

  @computed
  public get name(): string {
    return this.record.Name
  }

  @computed
  public get key(): string {
    return this.record.AccountGuid + String(this.record.AvailableBalance)
  }

  @action
  public click() {
    const isSlidOpen = document.getElementsByClassName('item-sliding-active-slide').length > 0
    if (isSlidOpen) return
    let url = '/plaiditems'
    url = url + '/' + this.record.AccountGuid
    alert('not implemented')
    // this.rootStore.appStore.navigateTo(url)
  }

  // @computed
  // public get hasNotes(): boolean {
  //   return Boolean(this.record.Notes)
  // }

  public get statusRank(): string {
    return '1'
    // return this.record.TransactionStatusId + TransactionStatusEnumName.fromId(this.record.TransactionStatusId)
  }

  public get statusName(): string {
    return 'Pending'
  }
}
