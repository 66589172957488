import { observable, action, computed, makeObservable } from 'mobx'
import { RootStore } from 'src/stores/RootStore'
import { SelectableColors } from '../../shared/SelectableColors'
import { Category } from '../aggregate/Category'
import { CategoryGroupEnumName } from '../enums/CategoryGroupEnumName'
import { CategoriesService } from '../services/CategoriesService'

export class CategoryEditVM {
  private rootStore: RootStore
  private category: Category

  constructor(rootStore: RootStore, category: Category, newType: number = undefined) {
    makeObservable(this)
    this.rootStore = rootStore
    this.category = category.clone()
    this.isNew = Boolean(newType)
    if (this.isNew) this.setGroup(Number(newType))
  }

  @observable public deleteConfirmShown: boolean = false
  public isNew: boolean = false

  @action
  public setName(val) {
    this.category.setName(val)
  }

  @computed
  public get name(): string {
    return this.category.Name
  }

  @computed
  public get isValid(): boolean {
    if (!this.name || this.name === '') return false
    return true
  }

  @action
  public setGroup(val: number) {
    this.category.setGroup(val)
  }

  @computed
  public get groupName(): string {
    return CategoryGroupEnumName.fromId(this.category.CategoryGroupId)
  }

  @computed
  public get pageTitle(): string {
    if (this.isNew) return 'New Category'
    return 'Edit Category'
  }

  @action
  public save() {
    const svc = new CategoriesService(this.rootStore)
    svc.save(this.category.toDTO())
    this.rootStore.appStore.history.push('/categorieslist')
  }

  @action
  public showDeleteConfirm() {
    this.deleteConfirmShown = true
  }

  @action
  public hideDeleteConfirm() {
    this.deleteConfirmShown = false
  }

  @computed
  public get color(): string {
    if (!this.category.Color) return ''
    return this.category.Color
  }

  @action
  public setColor(hex: string) {
    this.category.setColor(hex)
  }

  public delete() {
    const svc = new CategoriesService(this.rootStore)
    svc.delete(this.category.toDTO())
    this.rootStore.appStore.handleGoBack()
  }
  
  @action
  public goBack() {
    this.rootStore.appStore.handleGoBack()
  }

  public get colors() {
    return SelectableColors.colors
  }

  @computed
  public get categoryGroupId(): number {
    return this.category.CategoryGroupId
  }

  public get groupNames(): { id: number; name: string }[] {
    return CategoryGroupEnumName.list
  }
}
