import { ActionsStore } from '../store/ActionsStore'
import { Action } from '../aggregate/Action'
import { ActionTypeEnum } from '../enum/ActionTypeEnum'
import { RootStore } from '../../stores/RootStore'
import { action, computed, observable } from 'mobx'
import * as Sentry from '@sentry/browser'
import { CategoriesService } from '../../categories/services/CategoriesService'
import { BoardsService } from '../../boards/service/BoardsService'
import { BoardInvitationsService } from '../../board-invitations/service/BoardInvitationsService'
import { RemindersService } from '../../reminders/services/RemindersService'
import { AccountsService } from '../../accounts/services/AccountsService'
import { TransactionsService } from '../../transactions/services/TransactionsService'
import { BankTransactionsService } from '../../bank-transactions/services/BankTransactionsService'

export class ActionsService {
  private actionsStore: ActionsStore
  private rootStore: RootStore

  constructor(rootStore: RootStore, actionsStore: ActionsStore) {
    this.rootStore = rootStore
    this.actionsStore = actionsStore
  }

  @observable public isProcessing: boolean = false

  @computed
  private get actions(): Array<Action> {
    return this.actionsStore.actions
  }

  @action
  public async processActions() {
    if (window && window.Offline && window.Offline.state === 'down') return
    if (this.actions.length === 0) return
    if (this.isProcessing) return
    this.isProcessing = true
    const nextAction = this.actions[0]
    if (nextAction.attempts > 5) {
      this.actions.shift()
      this.isProcessing = false
      return
    }
    console.log('Processing action: ' + nextAction.typeName + ' dto:' + nextAction.dtoString)
    let success = false
    try {
      if (nextAction.type === ActionTypeEnum.CATEGORY_SAVE) await this.processCategorySave(nextAction)
      if (nextAction.type === ActionTypeEnum.BOARD_SAVE) await this.processBoardSave(nextAction)
      if (nextAction.type === ActionTypeEnum.BOARDINVITATION_SAVE) await this.processBoardInvitationSave(nextAction)
      if (nextAction.type === ActionTypeEnum.REMINDER_SAVE) await this.processReminderSave(nextAction)
      if (nextAction.type === ActionTypeEnum.ACCOUNT_SAVE) await this.processAccountSave(nextAction)
      if (nextAction.type === ActionTypeEnum.TRANSACTION_SAVE) await this.processTransactionSave(nextAction)
      if (nextAction.type === ActionTypeEnum.BANK_TRANSACTION_SAVE) await this.processBankTransactionSave(nextAction)
      success = true
    } catch (e) {
      console.error(e)
      Sentry.captureException(e)
    }
    if (success) {
      this.actions.shift()
      if (process.env.NODE_ENV === 'test') this.processActions()
      else setTimeout(() => this.processActions(), 1)
    } else {
      nextAction.attempts += 1
    }
    this.isProcessing = false
  }

  private async processCategorySave(nextAction: Action) {
    const svc = new CategoriesService(this.rootStore)
    await svc.saveOnServer(nextAction.dto)
  }

  private async processBoardSave(nextAction: Action) {
    const svc = new BoardsService(this.rootStore)
    await svc.saveOnServer(nextAction.dto)
  }

  private async processBoardInvitationSave(nextAction: Action) {
    const svc = new BoardInvitationsService(this.rootStore)
    await svc.saveOnServer(nextAction.dto)
  }

  private async processReminderSave(nextAction: Action) {
    const svc = new RemindersService(this.rootStore)
    await svc.saveOnServer(nextAction.dto)
  }

  private async processAccountSave(nextAction: Action) {
    const svc = new AccountsService(this.rootStore)
    await svc.saveOnServer(nextAction.dto)
  }

  private async processTransactionSave(nextAction: Action) {
    const svc = new TransactionsService(this.rootStore)
    await svc.saveOnServer(nextAction.dto)
  }

  private async processBankTransactionSave(nextAction: Action) {
    const svc = new BankTransactionsService(this.rootStore)
    await svc.saveOnServer(nextAction.dto)
  }
}
