import { RootStore } from '../../stores/RootStore'
import { ListVM } from '@elexient/elexiapp.bits.shared'
import { Transaction } from '../aggregate/Transaction'
import { TransactionRowVM } from './TransactionRowVM'
import { TransactionGroupRowVM } from './TransactionGroupRowVM'
import { action, computed, makeObservable, observable, reaction, runInAction } from 'mobx'
import { Account } from '../../accounts/aggregate/Account'
import { DateRangeEnum } from '../../accounts/enum/DateRangeEnum'
import { TransactionTypeEnum } from '../enum/TransactionTypeEnum'
import moment from 'moment'
import { RefresherEventDetail } from '@ionic/core'
import { NewTransactionTypeEnum } from '../enum/NewTransactionTypeEnum'
import { TransactionStatusEnum } from '../enum/TransactionStatusEnum'
import { TransactionLastRowVM } from './TransactionLastRowVM'
import { TransactionsService } from '../services/TransactionsService'
import { ITransactionDTO } from '../dtos/ITransactionDTO'
import { deserialize } from 'serializr'

export class TransactionsListVM extends ListVM<
  RootStore,
  Transaction,
  TransactionGroupRowVM,
  TransactionRowVM,
  TransactionLastRowVM
> {
  private processingTimeout: NodeJS.Timeout
  private pageSize: number = 10

  constructor(rootStore: RootStore, accountGuid: string) {
    super(rootStore, TransactionRowVM, TransactionGroupRowVM, TransactionLastRowVM)
    this.accountGuid = accountGuid
    this.stickyHeaders = true
    this.autoGrow = true
    this.startCollapsed = false
    this.setGroups('statusRank', '')
    reaction(
      () => this.rootStore.appStore.listHeight,
      () => this.setHeight(this.rootStore.appStore.listHeight),
      { delay: 200 }
    )
    reaction(
      () => this.account || this.account?.DateRangeId,
      () => this.setViewThru(this.account.DateRangeId),
      { delay: 200 }
    )
    reaction(
      () => this.rootStore.transactionsStore.recordJustUpdated,
      () => this.reloadRows(),
      { delay: 200 }
    )
    this.setHeight(this.rootStore.appStore.listHeight)
    this.setViewThru(this.account?.DateRangeId)
    this.getFirstPageForAccount()
  }

  protected getRecords() {
    return this.rootStore.transactionsStore.getTransactionsWithInboxTransactions().filter((trans) => {
      if (!this.account) return false
      if (trans.AccountGuid !== this.accountGuid) return false
      if (!this.transactionsThruDate) return true
      if (trans.TransactionStatusId === TransactionStatusEnum.Pending) return true
      if (trans.TransactionStatusId === TransactionStatusEnum.Cleared) return true
      if (trans.TransactionStatusId === TransactionStatusEnum.Inbox) return true
      if (trans.TransactionStatusId === TransactionStatusEnum.Skipped) return false
      if (trans.transactionDateAsDate >= this.transactionsThruDate) return false
      return true
    })
  }

  @observable public event: any
  @observable public menuShown: boolean = false
  @observable public viewThru: DateRangeEnum = DateRangeEnum.This_Month
  @observable public isProcessing: boolean = false
  @observable public pageNumber: number = 0

  public accountGuid: string = undefined

  protected aggregateSortFunction = (a: Transaction, b: Transaction): number => {
    if (a.TransactionStatusId === TransactionStatusEnum.Inbox)
      return a.transactionDateAsDate > b.transactionDateAsDate ? -1 : 0
    return a.TransactionNumber < b.TransactionNumber ? -1 : 0
  }

  @computed
  public get firstPageLoaded(): boolean {
    return this.pageNumber > 0
  }

  @action
  public increasePageNumber() {
    this.pageNumber++
  }

  @computed
  public get hasMorePages(): boolean {
    const pages = Math.round(Number(this.account.ClearedTransactionsCount) / 10)
    return this.pageNumber < pages
  }

  public async getFirstPageForAccount(): Promise<void> {
    const svc = new TransactionsService(this.rootStore)
    const trans = await svc.getFirstPageForAccount(this.accountGuid, this.pageSize)
    this.deleteExistingTransactions()
    runInAction(() => {
      this.rootStore.transactionsStore.persistedRecords.push.apply(
        this.rootStore.transactionsStore.persistedRecords,
        trans.map((e) => {
          return makeObservable(deserialize(Transaction, e))
        })
      )
    })
    this.reloadRows()
    this.increasePageNumber()
  }

  public async getMoreClearedTransactions(): Promise<void> {
    this.increasePageNumber()
    const svc = new TransactionsService(this.rootStore)
    const trans = await svc.getMoreClearedTransactions(this.accountGuid, this.pageSize, this.pageNumber)
    runInAction(() => {
      this.rootStore.transactionsStore.persistedRecords.push.apply(
        this.rootStore.transactionsStore.persistedRecords,
        trans.map((e) => {
          return makeObservable(deserialize(Transaction, e))
        })
      )
    })
  }

  @action
  private deleteExistingTransactions() {
    let idx = -1
    do {
      idx = this.findNextTransactionToDelete()
      if (idx > -1) this.rootStore.transactionsStore.persistedRecords.splice(idx, 1)
    } while (idx > -1)
  }

  private findNextTransactionToDelete() {
    return this.rootStore.transactionsStore.persistedRecords.findIndex((e) => {
      if (e.AccountGuid === this.accountGuid) return true
      return false
    })
  }

  @action
  public setViewThru(val: number) {
    if (!val) return
    this.viewThru = val
    this.closeMenu()
    this.reloadRows()
  }

  @action
  public openMenu(e: React.MouseEvent) {
    e.persist()
    this.event = e
    this.menuShown = true
  }

  @action
  public closeMenu() {
    this.menuShown = false
  }

  @action
  public editAccount() {
    this.closeMenu()
    this.rootStore.appStore.navigateTo('/accountedit/' + this.accountGuid)
  }

  @action
  public adjustBalance() {
    this.closeMenu()
    this.addTransaction(NewTransactionTypeEnum.Balance_Adjustment)
  }

  @computed
  public get account(): Account {
    if (!this.rootStore.accountsStore) return undefined
    if (!this.rootStore.accountsStore.isHydrated) return undefined
    return this.rootStore.accountsStore.get(this.accountGuid)
  }

  @computed
  public get accountName(): string {
    if (!this.account) return ''
    return this.account.Name
  }

  @computed
  public get transactionsThruDate(): Date {
    if (!this.viewThru) return undefined
    if (this.viewThru === DateRangeEnum.This_Month) {
      return moment().endOf('month').toDate()
    }
    if (this.viewThru === DateRangeEnum.Next_Month) {
      return moment().add(1, 'month').endOf('month').toDate()
    }
    if (this.viewThru === DateRangeEnum.Next_Income) {
      const nextIncome = this.rootStore.transactionsStore.currentBoardRecords
        .filter(
          (e) =>
            e.AccountGuid === this.accountGuid &&
            e.TransactionTypeId === TransactionTypeEnum.Income &&
            e.transactionDateAsDate > new Date() &&
            e.ReminderGuid
        )
        .sort((a, b) => (a.TransactionDate < b.TransactionDate ? -1 : 0))
        .find((e) => e.AccountGuid === this.accountGuid)
      if (!nextIncome) return undefined
      return nextIncome.transactionDateAsDate
    }
  }

  @action
  public async onPullToRefresh(e: CustomEvent<RefresherEventDetail>) {
    await this.getFirstPageForAccount()
    if (e) (e.target as any).complete()
  }

  public addTransaction(newType: NewTransactionTypeEnum) {
    let url = '/transactionedit'
    if (newType === NewTransactionTypeEnum.Expense) url = '/expenseedit'
    if (newType === NewTransactionTypeEnum.Transfer) url = '/transferedit'
    if (newType === NewTransactionTypeEnum.Credit_Card_Payment) url = '/ccpaymentedit'
    if (newType === NewTransactionTypeEnum.Balance_Adjustment) url = '/balanceadjustmentedit'
    this.rootStore.appStore.navigateTo(url + '/' + this.accountGuid + '/new')
  }

  @action
  public async loadNextPage() {
    if (this.isProcessing) return
    this.setProcessing()
    await this.getMoreClearedTransactions()
    this.setProcessing(false)
  }

  @action
  public setProcessing(forcedVal: boolean = true) {
    this.isProcessing = forcedVal
    // if (this.processingTimeout) clearTimeout(this.processingTimeout)
    // this.processingTimeout = setTimeout(() => runInAction(() => (this.isProcessing = false)), 1000)
  }
}
