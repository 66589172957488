import React from 'react'
import { IonButton, IonList, IonItemDivider, IonItem, IonText, IonToggle } from '@ionic/react'
import { inject, observer } from 'mobx-react'
import { UserStore } from '../store/UserStore'

interface Props {
  userStore?: UserStore
}

const WebNotifications: React.FC<Props> = ({ userStore }) => {
  const { userEditVM: vm } = userStore
  if (!vm) return
  if (!vm.isWebApp) return

  return (
    <IonList mode='md'>
      <IonItemDivider color='medium' mode='md'>
        Enable Push Notifications
      </IonItemDivider>
      <IonItem lines={'inset'}>
        <IonToggle checked={vm.WebNotificationsEnabled} onIonChange={() => vm.toggleWebNotifications()} />
      </IonItem>
    </IonList>
  )
}

export default inject('userStore')(observer(WebNotifications))
