import superagentPromise from 'superagent-promise'
import _superagent from 'superagent'

const superagent = superagentPromise(_superagent, global.Promise)
const API_ROOT = process.env.REACT_APP_API_URL + '/api'
const handleErrors = (err) => err
const responseBody = (res) => res.body

const requests = {
  get: (url, token) =>
    superagent
      .get(`${API_ROOT}${url}`)
      .use((req) => {
        let tk = null
        if (token) tk = token
        if (tk) req.set('Authorization', `Bearer ${tk}`)
      })
      .end(handleErrors)
      .then(responseBody),

  post: (url, body = {}, token = null) =>
    superagent
      .post(`${API_ROOT}${url}`, body)
      .use((req) => {
        let tk = null
        if (token) tk = token
        else if (window && window.token) tk = window.token
        req.set('Authorization', `Bearer ${tk}`)
      })
      .end(handleErrors)
      .then(responseBody),
}

const Reminders = {
  getAll: (token) => requests.get('/reminders/all', token),
  save: (form) => requests.post('/reminders/save', { ...form }),
}

const Categories = {
  getAll: (token) => requests.get('/categories/all', token),
  save: (form) => requests.post('/categories/save', { ...form }),
}

const Accounts = {
  getAll: (token) => requests.get('/accounts/all', token),
  save: (form) => requests.post('/accounts/save', { ...form }),
}

const Transactions = {
  getAll: (token) => requests.get('/transactions/all', token),
  getFirstPageForAccount: (form) => requests.post('/transactions/getFirstPageForAccount', { ...form }),
  getMoreCleared: (form) => requests.post('/transactions/getMoreCleared', { ...form }),
  save: (form) => requests.post('/transactions/save', { ...form }),
}

const BankTransactions = {
  getAll: (token) => requests.get('/banktransactions/all', token),
  save: (form) => requests.post('/banktransactions/save', { ...form }),
  matches: (guid) => requests.post('/banktransactions/matches', { bankTransactionGuid: guid }),
}

const UpcomingBills = {
  getAll: (token) => requests.get('/upcomingbills/all', token),
}

const PlaidItems = {
  getAll: (token) => requests.get('/plaiditems/all', token),
  getLinkToken: (boardId) => requests.post('/plaiditems/linkToken?boardId=' + boardId),
  getLinkTokenForUpdate: (guid) => requests.post('/plaiditems/linkTokenForUpdate?plaidItemGuid=' + guid),
  doneUpdating: (guid) => requests.post('/plaiditems/doneUpdating?plaidItemGuid=' + guid),
  tradePublicToken: (req) => requests.post('/plaiditems/tradePublicToken', { ...req }),
  refresh: (boardId) => requests.post('/plaiditems/refresh?boardId=' + boardId),
  save: (form) => requests.post('/plaiditems/save', { ...form }),
}

const Boards = {
  getAll: (token) => requests.get('/boards/all', token),
  save: (form) => requests.post('/boards/save', { ...form }),
  generateInvitationCode: (form) => requests.post('/boards/generateinvitationcode', { ...form }),
  joinBoard: (invitationCode) => requests.post('/boards/join', { invitationCode: invitationCode }),
}

const BoardInvitations = {
  getAll: (token) => requests.get('/boardinvitations/all', token),
  save: (form) => requests.post('/boardinvitations/save', { ...form }),
}

export default {
  Accounts,
  UpcomingBills,
  Categories,
  Boards,
  BoardInvitations,
  Reminders,
  Transactions,
  BankTransactions,
  PlaidItems,
}
