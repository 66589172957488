import { observable, action, computed } from 'mobx'
import { RootStore } from 'src/stores/RootStore'
import agent from '../../Agent'

export default class ForgotPasswordForm {
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  rootStore: RootStore

  @observable email: string = ''
  @observable errorMessages: Array<string> = []

  @computed public get isValid() {
    if (this.email === '') return false
    return true
  }

  @action
  public setEmail(val) {
    this.email = val
  }

  @action public async save(): Promise<boolean> {
    const email = document.getElementById('email2').getElementsByTagName('input')[0].value
    this.setEmail(email)

    this.errorMessages = []
    const form = {
      Email: this.email,
    }
    return await this.saveOnServer(form)
  }

  async saveOnServer(form) {
    try {
      const results: Array<string> = await agent.Users.forgotPassword(form)
      if (results[0] === 'Success' && results.length === 1) {
        return true
      } else {
        results.forEach(e => {
          this.errorMessages.push(e)
        })
      }
    } catch (e) {
      console.error(e)
    }
    return false
  }
}
