import React from 'react'
import {
  IonContent,
  IonIcon,
  IonButton,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonAlert,
  IonFab,
  IonFabButton,
} from '@ionic/react'
import { observer, inject, Observer } from 'mobx-react'
import { close, trashOutline, checkmarkSharp, arrowForwardSharp, pencilSharp, linkSharp } from 'ionicons/icons'
import { AppStore } from '../../../stores/AppStore'
import DetailsForm from './DetailsForm'
import BackIcon from '../../../shared/BackIcon'
import { BankTransactionsStore } from '../../store/BankTransactionsStore'

interface Props {
  bankTransactionsStore?: BankTransactionsStore
  appStore?: AppStore
}

const BankTransactionEditArea: React.FC<Props> = ({ bankTransactionsStore, appStore }) => {
  const { editVM } = bankTransactionsStore
  if (!editVM) return null

  const renderAppHeader = () => {
    if (!appStore.showAppHeader) return
    return (
      <IonHeader>
        <IonToolbar />
      </IonHeader>
    )
  }

  const renderSmallFab = () => {
    if (!editVM.showEditButton) return
    return (
      <IonFab vertical='bottom' horizontal='end'>
        <IonFabButton
          size='small'
          className='small-edit'
          color='primary'
          disabled={!editVM.isValid}
          onClick={() => editVM.save(true)}
          style={{
            marginTop: '-115px',
            marginLeft: '-47px',
            height: '30px',
          }}
        >
          <IonIcon size='small' icon={pencilSharp} />
          &nbsp; Link &amp; Edit
        </IonFabButton>
      </IonFab>
    )
  }

  const renderBigFab = () => {
    return (
      <IonFab vertical='bottom' horizontal='end'>
        <IonFabButton
          color='tertiary'
          disabled={!editVM.isValid}
          onClick={() => editVM.save()}
          style={{
            marginTop: '-60px',
            marginLeft: '-60px',
          }}
        >
          <IonIcon
            icon={editVM.icon === 'next' ? arrowForwardSharp : editVM.icon === 'link' ? linkSharp : checkmarkSharp}
          />
        </IonFabButton>
      </IonFab>
    )
  }

  return (
    <>
      {renderAppHeader()}
      <IonHeader id='BankTransactionEditPage_Header'>
        <IonToolbar color='secondary'>
          <IonButtons slot='start'>
            <IonButton onClick={() => editVM.goBack()}>
              <BackIcon />
            </IonButton>
          </IonButtons>
          <IonTitle>{editVM.pageTitle}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent id='BankTransactionEditPage_Content'>
        <DetailsForm />
      </IonContent>
      {renderSmallFab()}
      {renderBigFab()}
    </>
  )
}

export default inject('bankTransactionsStore', 'appStore')(observer(BankTransactionEditArea))
