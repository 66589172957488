import { injectable } from 'tsyringe'
import { RootStore } from '../../stores/RootStore'
import { Transaction } from '../aggregate/Transaction'
import { NewTransactionTypeEnum } from '../enum/NewTransactionTypeEnum'
import { BalanceAdjustmentEditVM } from '../view-models/BalanceAdjustmentEditVM'
import { CCPaymentEditVM } from '../view-models/CCPaymentEditVM'
import { ExpenseEditVM } from '../view-models/ExpenseEditVM'
import { TransactionEditVM } from '../view-models/TransactionEditVM'
import { TransferEditVM } from '../view-models/TransferEditVM'

@injectable()
export class TransactionsRouteService {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  public processNewRoute(
    accountGuid: string,
    newType: number
  ): TransactionEditVM | ExpenseEditVM | TransferEditVM | CCPaymentEditVM {
    let vm: any = this.checkForNewExpenseVM(accountGuid)
    if (vm) return vm
    vm = this.checkForNewTransferVM(accountGuid)
    if (vm) return vm
    vm = this.checkForNewCCPaymentVM(accountGuid)
    if (vm) return vm
    vm = this.checkForNewBalanceAdjustmentVM(accountGuid)
    if (vm) return vm
    return new TransactionEditVM(
      this.rootStore,
      Transaction.create(this.rootStore.boardsStore.currentBoardId, accountGuid),
      newType
    )
  }

  public processExistingRoute(transactionGuid: string): TransactionEditVM | ExpenseEditVM | TransferEditVM {
    const trans = this.rootStore.transactionsStore.get(transactionGuid)
    if (!trans) return undefined
    if (this.checkForRedirectToExistingExpense(trans)) return
    const vm = this.checkForExistingExpenseVM(trans)
    if (vm) return vm
    return new TransactionEditVM(this.rootStore, trans.clone())
  }

  private checkForRedirectToExistingExpense(trans: Transaction) {
    if (this.isOnExpenseRoute()) return false
    this.rootStore.appStore.navigateTo('/expenseedit/' + trans.AccountGuid + '/' + trans.TransactionGuid)
    return true
  }

  private isOnExpenseRoute() {
    return this.rootStore.appStore.currentRoute.includes('/expenseedit')
  }

  private isOnTransferRoute() {
    return this.rootStore.appStore.currentRoute.includes('/transferedit')
  }

  private isOnCCPaymentRoute() {
    return this.rootStore.appStore.currentRoute.includes('/ccpaymentedit')
  }

  private isOnBalanceAdjustmentRoute() {
    return this.rootStore.appStore.currentRoute.includes('/balanceadjustmentedit')
  }

  private checkForNewExpenseVM(accountGuid: string) {
    if (!this.isOnExpenseRoute()) return undefined
    return new ExpenseEditVM(
      this.rootStore,
      Transaction.create(this.rootStore.boardsStore.currentBoardId, accountGuid),
      true
    )
  }

  private checkForNewCCPaymentVM(accountGuid: string) {
    if (!this.isOnCCPaymentRoute()) return undefined
    return new CCPaymentEditVM(
      this.rootStore,
      Transaction.create(this.rootStore.boardsStore.currentBoardId, accountGuid),
      true
    )
  }

  private checkForNewBalanceAdjustmentVM(accountGuid: string) {
    if (!this.isOnBalanceAdjustmentRoute()) return undefined
    return new BalanceAdjustmentEditVM(
      this.rootStore,
      Transaction.create(this.rootStore.boardsStore.currentBoardId, accountGuid),
      true
    )
  }

  private checkForNewTransferVM(accountGuid: string) {
    if (!this.isOnTransferRoute()) return undefined
    return new TransferEditVM(
      this.rootStore,
      Transaction.create(this.rootStore.boardsStore.currentBoardId, accountGuid),
      true
    )
  }

  private checkForExistingExpenseVM(tran: Transaction) {
    if (!this.isOnExpenseRoute()) return undefined
    return new ExpenseEditVM(this.rootStore, tran.clone(), false)
  }
}
