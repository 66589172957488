import { RootStore } from '../../stores/RootStore'
import { ListVM } from '@elexient/elexiapp.bits.shared'
import { Account } from '../aggregate/Account'
import { AccountRowVM } from './AccountRowVM'
import { AccountGroupRowVM } from './AccountGroupRowVM'
import { action, computed, observable, override, reaction } from 'mobx'
import { RefresherEventDetail } from '@ionic/core'

export class AccountsListVM extends ListVM<RootStore, Account, AccountGroupRowVM, AccountRowVM, undefined> {
  constructor(rootStore: RootStore) {
    super(rootStore, AccountRowVM, AccountGroupRowVM, undefined)
    this.autoGrow = true
    this.startCollapsed = false
    this.stickyHeaders = true
    this.setGroups('type', '')
    reaction(
      () => this.rootStore.appStore.listHeight,
      () => this.setHeight(this.rootStore.appStore.listHeight),
      { delay: 200 }
    )
    reaction(
      () => this.rootStore.accountsStore.recordJustUpdated,
      () => this.reloadRows()
    )
    this.setHeight(this.rootStore.appStore.listHeight)
  }

  protected getRecords() {
    return this.rootStore.accountsStore.currentBoardRecords
  }

  protected sortFunction = (a: Account, b: Account): number => {
    const aVal = a.Name.toLowerCase()
    const bVal = b.Name.toLowerCase()
    if (aVal < bVal) return -1
    return 0
  }

  @action
  public async refresh(e: CustomEvent<RefresherEventDetail>) {
    await this.rootStore.accountsStore.loadData()
    if (e) (e.target as any).complete()
  }
}
