import React from 'react'
import { IonButton, IonIcon, IonPopover, IonItem } from '@ionic/react'
import { observer, inject } from 'mobx-react'
import { ellipsisVertical, eye } from 'ionicons/icons'
import { MaterialCommunityIcons, EvilIcons } from 'react-web-vector-icons'
import { TransactionsStore } from '../../store/TransactionsStore'
import { DateRangeEnum } from '../../../accounts/enum/DateRangeEnum'

interface Props {
  transactionsStore?: TransactionsStore
}

const TransactionsListPageMoreMenu: React.FC<Props> = ({ transactionsStore }) => {
  const vm = transactionsStore.listVM
  if (!vm) return null

  const renderOpenMenu = () => {
    return (
      <IonButton onClick={(e) => vm.openMenu(e)}>
        <IonIcon icon={ellipsisVertical} mode='ios' />
      </IonButton>
    )
  }

  return (
    <div id='TransactionsListPageMoreMenu'>
      {renderOpenMenu()}

      <IonPopover
        id='TransactionsListPageMoreMenu_Content'
        isOpen={vm.menuShown}
        event={vm.event}
        onDidDismiss={() => vm.closeMenu()}
      >
        <IonItem lines='full' onClick={() => vm.editAccount()}>
          <MaterialCommunityIcons name='broom' size={18} />
          &nbsp; Edit Account
        </IonItem>

        <IonItem lines='full' onClick={() => vm.adjustBalance()}>
          <EvilIcons name='gear' size={18} />
          &nbsp; Adjust Balance
        </IonItem>

        <IonItem
          lines='full'
          onClick={() => vm.setViewThru(DateRangeEnum.This_Month)}
          className={vm.viewThru === DateRangeEnum.This_Month ? 'bold' : ''}
        >
          <IonIcon icon={eye} size='small' />
          &nbsp; View Thru: This Month
        </IonItem>

        <IonItem
          lines='full'
          onClick={() => vm.setViewThru(DateRangeEnum.Next_Month)}
          className={vm.viewThru === DateRangeEnum.Next_Month ? 'bold' : ''}
        >
          <IonIcon icon={eye} size='small' />
          &nbsp; View Thru: Next Month
        </IonItem>

        <IonItem
          lines='full'
          onClick={() => vm.setViewThru(DateRangeEnum.Next_Income)}
          className={vm.viewThru === DateRangeEnum.Next_Income ? 'bold' : ''}
        >
          <IonIcon icon={eye} size='small' />
          &nbsp; View Thru: Next Income
        </IonItem>
      </IonPopover>
    </div>
  )
}

export default inject('transactionsStore')(observer(TransactionsListPageMoreMenu))
