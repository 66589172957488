import React from 'react'
import {
  IonIcon,
  IonMenu,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonMenuToggle,
  IonListHeader,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
} from '@ionic/react'
import './../styles/SideMenu.scss'
import { inject, observer } from 'mobx-react'
import { MaterialCommunityIcons, AntDesign } from 'react-web-vector-icons'
import { AppStore } from '../../stores/AppStore'
import { faTasks } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import BackIcon from '../../shared/BackIcon'
import Icon from '../../assets/images/Icon_512.png'
import { peopleOutline, personCircleOutline } from 'ionicons/icons'

interface Props {
  appStore?: AppStore
}

const SideMenu: React.FC<Props> = ({ appStore }) => {
  const { sideMenuVM } = appStore
  const mainColor = 'medium'

  const renderBackButton = () => {
    if (appStore.isSplitPaneVisible) return null
    return (
      <IonHeader>
        <IonToolbar color={mainColor}>
          <IonButtons slot='start'>
            <IonButton onClick={() => appStore.closeSideMenu()}>
              <BackIcon />
            </IonButton>
          </IonButtons>
          <div id='LogoContainer'>
            <div>
              <div id='g'>Budget</div>
              <div id='b'>Board</div>
            </div>
            <img src={Icon} alt='Budget Board' />
          </div>
        </IonToolbar>
      </IonHeader>
    )
  }

  const renderLogo = () => {
    if (!appStore.isSplitPaneVisible) return null
    return (
      <div id='LogoContainer'>
        <img src={Icon} alt='Budget Board' />
        <div>
          <div id='g'>Budget</div>
          <div id='b'>Board</div>
        </div>
      </div>
    )
  }

  return (
    <IonMenu
      contentId='main'
      id='SideMenu'
      type={'overlay'}
      swipeGesture={false}
      onIonWillOpen={() => appStore.setMenuOpen(true)}
      onIonWillClose={() => appStore.setMenuOpen(false)}
      ref={(e) => appStore.setSideMenu(e)}
    >
      {renderBackButton()}
      <IonContent color={mainColor} ref={(e) => sideMenuVM.applyScrollStyles(e)}>
        {renderLogo()}

        <IonList color={mainColor}>
          <IonMenuToggle autoHide={false}>
            <IonItem
              lines={'none'}
              key={`dashboard-${sideMenuVM.isDashboardSection}`}
              button
              className={sideMenuVM.isDashboardSection ? 'active' : ''}
              color={sideMenuVM.isDashboardSection ? 'tertiary' : mainColor}
              onClick={(e) => {
                e.currentTarget.classList.add('active')
                e.currentTarget.classList.add('ion-color-tertiary')
                appStore.navigateTo('/')
              }}
            >
              <FontAwesomeIcon icon={faTasks} style={{ fontSize: '20px', marginLeft: '2px' }} />
              <IonLabel>Dashboard</IonLabel>
            </IonItem>
          </IonMenuToggle>

          <IonMenuToggle autoHide={false}>
            <IonItem
              button
              lines={'none'}
              key={`accountslist-${sideMenuVM.isRemindersSection}`}
              color={sideMenuVM.isAccountsSettingsSection ? 'tertiary' : mainColor}
              className={'nav-boardsettings ' + (sideMenuVM.isAccountsSettingsSection ? 'active' : '')}
              onClick={() => appStore.navigateTo('/accountslist')}
            >
              <AntDesign name='bank' size={24} />
              <IonLabel>Accounts</IonLabel>
            </IonItem>
          </IonMenuToggle>

          {/* <IonMenuToggle autoHide={false}>
            <IonItem
              lines={'none'}
              key={`inbox-${sideMenuVM.isInboxSection}`}
              button
              className={sideMenuVM.isInboxSection ? 'active' : ''}
              color={sideMenuVM.isInboxSection ? 'tertiary' : mainColor}
              onClick={(e) => {
                e.currentTarget.classList.add('active')
                e.currentTarget.classList.add('ion-color-tertiary')
                appStore.navigateTo('/banktransactionslist')
              }}
            >
              <AntDesign name='inbox' size={24} />
              <IonLabel>Inbox</IonLabel>
            </IonItem>
          </IonMenuToggle> */}

          <IonMenuToggle autoHide={false}>
            <IonItem
              lines={'none'}
              key={`reminderslist-${sideMenuVM.isRemindersSection}`}
              button
              className={sideMenuVM.isRemindersSection ? 'active' : ''}
              color={sideMenuVM.isRemindersSection ? 'tertiary' : mainColor}
              onClick={(e) => {
                e.currentTarget.classList.add('active')
                e.currentTarget.classList.add('ion-color-tertiary')
                appStore.navigateTo('/reminderslist')
              }}
            >
              <AntDesign name='calendar' size={24} />
              <IonLabel>Reminders</IonLabel>
            </IonItem>
          </IonMenuToggle>

          <IonListHeader color={mainColor}>
            <IonLabel>Settings</IonLabel>
          </IonListHeader>

          <IonMenuToggle autoHide={false}>
            <IonItem
              button
              lines={'none'}
              color={sideMenuVM.isCategoriesSettingsSection ? 'tertiary' : mainColor}
              className={'nav-boardsettings ' + (sideMenuVM.isCategoriesSettingsSection ? 'active' : '')}
              onClick={() => appStore.navigateTo('/categorieslist')}
            >
              <FontAwesomeIcon icon={faTasks} style={{ fontSize: '20px', marginLeft: '2px' }} />
              <IonLabel>Categories</IonLabel>
            </IonItem>
          </IonMenuToggle>

          {/*<IonMenuToggle autoHide={false}>
            <IonItem
              button
              lines={'none'}
              color={sideMenuVM.isRecipeCategoriesSettingsSection ? 'tertiary' : mainColor}
              className={'nav-boardsettings ' + (sideMenuVM.isRecipeCategoriesSettingsSection ? 'active' : '')}
              onClick={() => appStore.navigateTo('/recipecategorieslist')}
            >
              <Octicons name='book' size={24} />
              <IonLabel>Recipe Categories</IonLabel>
            </IonItem>
          </IonMenuToggle> */}

          <IonMenuToggle autoHide={false}>
            <IonItem
              button
              lines={'none'}
              color={sideMenuVM.isAccountSettingsSection ? 'tertiary' : mainColor}
              className={'nav-boardsettings ' + (sideMenuVM.isAccountSettingsSection ? 'active' : '')}
              onClick={() => appStore.navigateTo('/accountsettings')}
            >
              <IonIcon icon={personCircleOutline} />
              <IonLabel>My Account</IonLabel>
            </IonItem>
          </IonMenuToggle>

          <IonMenuToggle autoHide={false}>
            <IonItem
              button
              lines={'none'}
              color={sideMenuVM.isBoardSettingsSection ? 'tertiary' : mainColor}
              className={'nav-boardsettings ' + (sideMenuVM.isBoardSettingsSection ? 'active' : '')}
              onClick={() => appStore.navigateTo('/boardsettings')}
            >
              <IonIcon icon={peopleOutline} />
              <IonLabel>Board Sharing</IonLabel>
            </IonItem>
          </IonMenuToggle>

          <IonMenuToggle autoHide={false}>
            <IonItem
              button
              lines={'none'}
              color={sideMenuVM.isThemesSection ? 'tertiary' : mainColor}
              className={sideMenuVM.isThemesSection ? 'active' : ''}
              onClick={() => appStore.navigateTo('/themeslist')}
            >
              <MaterialCommunityIcons name='brightness-6' size={24} />
              <IonLabel>Theme</IonLabel>
            </IonItem>
          </IonMenuToggle>

          {sideMenuVM.showThemeEditor && (
            <IonMenuToggle autoHide={false}>
              <IonItem
                button
                lines={'none'}
                color={sideMenuVM.isThemeEditSection ? 'tertiary' : mainColor}
                className={sideMenuVM.isThemeEditSection ? 'active' : ''}
                onClick={() => appStore.navigateTo('/themegen')}
              >
                <MaterialCommunityIcons name='pencil' size={24} />
                <IonLabel>Theme Editor</IonLabel>
              </IonItem>
            </IonMenuToggle>
          )}
        </IonList>
      </IonContent>
    </IonMenu>
  )
}

export default inject('appStore')(observer(SideMenu))
