import { computed, makeObservable } from 'mobx'
import { RootStore } from '../../../stores/RootStore'
import { ReminderTypeEnum } from '../../../reminders/enum/ReminderTypeEnum'
import { ToDoRowVM } from './ToDoRowVM'

export class ToDoWidgetVM {
  constructor(private rootStore: RootStore) {
    makeObservable(this)
  }

  @computed
  public get rows(): ToDoRowVM[] {
    return this.rootStore.remindersStore.currentBoardRecords
      .filter((e) => e.TypeId === ReminderTypeEnum.To_Do)
      .map((e) => new ToDoRowVM(this.rootStore, e))
  }

  @computed
  public get bodyHeight(): number {
    return this.rootStore.appStore.bodyHeight
  }
}
