import { observable } from 'mobx'
import { persist } from '../../shared/persist'

export default class SubscribedTopic {

  static create(path) {
    const topic = new SubscribedTopic()
    topic.path = path
    return topic
  }

  @persist @observable path: string = ''

}