import { RootStore } from '../../stores/RootStore'
import { ListVM } from '@elexient/elexiapp.bits.shared'
import { PlaidItem } from '../aggregate/PlaidItem'
import { PlaidItemAccountRowVM } from './PlaidItemAccountRowVM'
import { PlaidItemAccountGroupRowVM } from './PlaidItemAccountGroupRowVM'
import { action, computed, observable, reaction } from 'mobx'
import moment from 'moment'
import { RefresherEventDetail } from '@ionic/core'
import AgentV2 from '../../AgentV2'
import { Account } from '../aggregate/Account'

export class PlaidItemAccountsListVM extends ListVM<
  RootStore,
  Account,
  PlaidItemAccountGroupRowVM,
  PlaidItemAccountRowVM,
  undefined
> {
  constructor(rootStore: RootStore, accountGuid: string) {
    super(rootStore, PlaidItemAccountRowVM, PlaidItemAccountGroupRowVM, undefined)
    this.stickyHeaders = true
    this.autoGrow = true
    this.startCollapsed = false
    this.setGroups('statusRank', '')
    reaction(
      () => this.rootStore.appStore.listHeight,
      () => this.setHeight(this.rootStore.appStore.listHeight),
      { delay: 200 }
    )
    reaction(
      () => this.rootStore.plaidItemsStore.recordJustUpdated,
      () => this.reloadRows(),
      { delay: 200 }
    )
    this.setHeight(this.rootStore.appStore.listHeight)
  }

  @observable public event: any
  @observable public menuShown: boolean = false

  protected getRecords() {
    return this.rootStore.plaidItemsStore.currentBoardRecords.map((e) => e.Accounts).flat(1)
  }

  protected sortFunction = (a: Account, b: Account): number => {
    const aVal = a.Name.toString()
    const bVal = b.Name.toString()
    if (aVal > bVal) return -1
    return 0
  }

  // protected filterFunction = (item: PlaidItem): boolean => {
  //   // if (!this.account) return false
  //   // if (trans.AccountGuid !== this.accountGuid) return false
  //   // if (!this.transactionsThruDate) return true
  //   return true
  // }

  public openMenu(e: React.MouseEvent) {
    e.persist()
    this.event = e
    this.menuShown = true
  }

  @action
  public closeMenu() {
    this.menuShown = false
  }

  @action
  public editAccount() {
    this.closeMenu()
    // this.rootStore.appStore.navigateTo('/accountedit/' + this.accountGuid)
  }

  @action
  public async onPullToRefresh(e: CustomEvent<RefresherEventDetail>) {
    const success = await AgentV2.PlaidItems.refresh(this.rootStore.boardsStore.currentBoardId)
    console.log(success)
    await this.rootStore.bankTransactionsStore.loadData()
    if (e) (e.target as any).complete()
  }
}
