import { GroupRowVM } from '@elexient/elexiapp.bits.shared'
import { computed } from 'mobx'
import { RootStore } from '../../../stores/RootStore'
import { TransactionsListVM } from './TransactionsListVM'

export class TransactionGroupRowVM extends GroupRowVM<RootStore, TransactionsListVM> {
  @computed
  public get displayName(): string {
    return this.name.substring(1)
  }

  public get color(): string {
    return '#c00000'
  }
}
