import React, { useState } from 'react'
import { inject, observer } from 'mobx-react'
import { CategoriesStore } from '../../store/CategoriesStore'
import { AppStore } from '../../../stores/AppStore'
import {
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonListHeader,
  IonDatetime,
  IonIcon,
  IonSelectOption,
  IonSelect,
} from '@ionic/react'
import { CirclePicker } from 'react-color'

interface Props {
  categoriesStore?: CategoriesStore
  appStore?: AppStore
}

const InfoForm: React.FC<Props> = ({ categoriesStore, appStore }) => {
  const { editVM } = categoriesStore
  if (!editVM) return null
  if (appStore.bodyHeight === 0) return null

  const renderGroup = () => {
    return (
      <IonItem lines={'inset'}>
        <IonLabel position='floating'>Group</IonLabel>
        <IonSelect
          interface='popover'
          value={editVM.categoryGroupId}
          onIonChange={(e: any) => editVM.setGroup(e.target.value)}
        >
          {editVM.groupNames.map((e) => (
            <IonSelectOption key={e.id} value={e.id}>
              {e.name}
            </IonSelectOption>
          ))}
        </IonSelect>
      </IonItem>
    )
  }

  const renderColor = () => {
    return (
      <IonItem lines='inset'>
        <IonLabel position='floating' className='forced-floating'>
          Color
        </IonLabel>
        <CirclePicker
          color={editVM.color}
          circleSize={20}
          width={'100%'}
          colors={editVM.colors}
          onChange={(e) => editVM.setColor(e.hex)}
        />
      </IonItem>
    )
  }

  const renderName = () => {
    return (
      <IonItem lines={'inset'}>
        <IonLabel position='floating'>Name</IonLabel>
        <IonInput type='text' value={editVM.name} onIonChange={(e: any) => editVM.setName(e.target.value)} />
      </IonItem>
    )
  }

  return (
    <form
      id='InfoForm'
      onSubmit={(e) => {
        e.preventDefault()
        editVM.save()
      }}
    >
      <IonList>
        {renderGroup()}
        {renderName()}
        {renderColor()}
      </IonList>
    </form>
  )
}

export default inject('categoriesStore', 'appStore')(observer(InfoForm))
