import { reaction, action } from 'mobx'
import {
    serialize, deserialize,
    update,
    serializable, getDefaultModelSchema
} from 'serializr'
import { types, Types } from './types'
import { persistObject } from './persist-object'

export function persist(type: Types, schema?: any): (target: Object, key: string, baseDescriptor?: PropertyDescriptor) => void // two
export function persist(target: Object, key: string, baseDescriptor?: PropertyDescriptor): void // method decorator
export function persist(schema: Object): <T>(target: T) => T // object
export function persist(...args: any[]): any {
    const [a, b, c] = args
    if (a in types) {
        return serializable(types[a](b))
    } else if (args.length === 1) {
        return (target: any) => persistObject(target, a)
    } else {
        return serializable.apply(null, args)
    }
}

export interface optionsType {
    storage?: any
    jsonify?: boolean
    debounce?: number
}

export interface IHydrateResult<T> extends Promise<T> {
    rehydrate: () => IHydrateResult<T>
}

