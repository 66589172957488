import 'reflect-metadata'
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { HashRouter } from 'react-router-dom'
import App from './App'
import { Provider } from 'mobx-react'
import promiseFinally from 'promise.prototype.finally'
import './offline.min'
import { RootStore } from './stores/RootStore'
import * as Sentry from '@sentry/browser'
import '@codetrix-studio/capacitor-google-auth'
import { defineCustomElements } from '@ionic/pwa-elements/loader'
import { StatusBar, Style } from '@capacitor/status-bar'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import reportWebVitals from './reportWebVitals'
import { configurePersistable } from 'mobx-persist-store'
import { Capacitor } from '@capacitor/core'
require('./container')
require('react-web-vector-icons/fonts')

// import './offline-theme-dark-indicator.css'

let doSentry = true
const reportOnLocal = false
if (process.env.REACT_APP_IS_DEV_MODE === '1' && !reportOnLocal) doSentry = false
let environment = 'Production'
if (process.env.REACT_APP_IS_DEV_MODE === '1') environment = 'Development'
if (doSentry) {
  Sentry.init({
    dsn: 'https://cc29526f8b974c32ad87c520f16f4592@o391845.ingest.sentry.io/5761844',
    environment: environment,
  })
}
declare global {
  interface Window {
    rootStore: RootStore
    Offline: any
    Ionic: any
    token: any
    firebase: any
  }
}

if (Capacitor.platform != 'web') {
  const options = {
    style: Style.Light,
  }
  StatusBar.setStyle(options)
}

configurePersistable(
  {
    storage: window.localStorage,
  },
  { fireImmediately: true }
)

const rootStore = new RootStore()
rootStore.loadData('app loaded')
rootStore.processActions()

const stores = {
  rootStore: rootStore,
}

const manifest = [
  'AppStore',
  'AuthStore',
  'BoardsStore',
  'RemindersStore',
  'AccountsStore',
  'TransactionsStore',
  'ActionsStore',
  'CategoriesStore',
  'UserStore',
  'BoardInvitationsStore',
  'NotificationsStore',
  'BankTransactionsStore',
  'PlaidItemsStore',
]

manifest.forEach((e) => {
  const key = e //(e as any).constructor.name <-- Not working in minified webpack
  const camelKey = key.charAt(0).toLowerCase() + key.substring(1)
  stores[camelKey] = rootStore[camelKey]
})

window.Offline.options = {
  checkOnLoad: false,
  interceptRequests: false,
  requests: false,
  reconnect: false, //{ initialDelay: 3, delay: 3 },
  game: false,
}

window.rootStore = rootStore
promiseFinally.shim()

ReactDOM.render(
  <Provider {...stores}>
    <HashRouter>
      <App />
    </HashRouter>
  </Provider>,
  document.getElementById('root') as HTMLElement
)

defineCustomElements(window)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
