import { RootStore } from '../../../stores/RootStore'
import { ListVM } from '@elexient/elexiapp.bits.shared'
import { Reminder } from '../../aggregate/Reminder'
import { action, IReactionDisposer, makeObservable, observable, reaction } from 'mobx'
import { RefresherEventDetail } from '@ionic/core'
import { ReminderTypeEnum } from '../../enum/ReminderTypeEnum'
import { Transaction } from '../../aggregate/Transaction'
import { TransactionGroupRowVM } from './TransactionGroupRowVM'
import { TransactionRowVM } from './TransactionRowVM'

export class TransactionsListVM extends ListVM<
  RootStore,
  Transaction,
  TransactionGroupRowVM,
  TransactionRowVM,
  undefined
> {
  private otherReactionDisposers: IReactionDisposer[] = []

  constructor(rootStore: RootStore, reminder: Reminder) {
    super(rootStore, TransactionRowVM, TransactionGroupRowVM, undefined)
    makeObservable(this)
    this.reminder = reminder
    this.autoGrow = true
    this.startCollapsed = false
    this.stickyHeaders = true
    this.atTopCheckTimeout = 500
    // this.group1CollapsedNames = ['group1_0Upcoming']
    this.setGroups('period', '')
    this.otherReactionDisposers.push(
      reaction(
        () => this.rootStore.appStore.listHeight,
        () => this.setHeight(this.rootStore.appStore.listHeight),
        { delay: 200 }
      )
    )
    this.otherReactionDisposers.push(
      reaction(
        () => this.rootStore.remindersStore.recordJustUpdated,
        () => this.reloadRows()
      )
    )
    this.otherReactionDisposers.push(
      reaction(
        () => this.rootStore.accountsStore.isHydrated,
        () => {
          this.reloadRows()
          this.shown = true
        }
      )
    )
    this.setHeight(this.rootStore.appStore.listHeight)
  }

  @observable public shown: boolean = false
  public reminder: Reminder

  protected getRecords() {
    return this.reminder.Transactions
  }

  protected aggregateSortFunction = (a: Transaction, b: Transaction): number => {
    const aVal = a.TransactionDate
    const bVal = b.TransactionDate
    if (aVal > bVal) return -1
    if (aVal === bVal) {
      const aaVal = a.Amount
      const bbVal = b.Amount
      if (aaVal < bbVal) return -1
      if (aaVal === bbVal) {
        const aaaVal = a.TransactionGuid
        const bbbVal = b.TransactionGuid
        if (aaaVal > bbbVal) return -1
      }
    }
    return 0
  }

  @action
  public async refresh(e: CustomEvent<RefresherEventDetail>) {
    await this.rootStore.remindersStore.loadData()
    if (e) (e.target as any).complete()
  }

  @action
  public addReminder(newType: ReminderTypeEnum) {
    let url = '/reminderedit'
    this.rootStore.appStore.navigateTo(url + '/new/' + newType)
  }

  public dispose() {
    this.otherReactionDisposers.forEach((e) => e())
  }
}
