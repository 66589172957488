import agent from '../../AgentV2'
import { RootStore } from '../../stores/RootStore'
import { IReminderDTO } from '../dtos/IReminderDTO'
import { ActionTypeEnum } from '../../actions/enum/ActionTypeEnum'
import { ITransactionDTO } from '../dtos/ITransactionDTO'

export class RemindersService {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  // public saveTransaction(trans: ITransactionDTO) {
  //   this.rootStore.remindersStore.updateFromServer(dto)
  //   this.rootStore.actionsStore.addAction(ActionTypeEnum.REMINDER_TRANSACTION_SAVE, trans)
  // }

  // public async saveTransactionOnServer(trans: ITransactionDTO) {
  //   const savedDTO = await agent.Reminders.saveTransaction(dto)
  //   this.rootStore.remindersStore.updateFromServer(savedDTO)
  // }

  public save(dto: IReminderDTO) {
    this.rootStore.remindersStore.updateFromServer(dto)
    this.rootStore.actionsStore.addAction(ActionTypeEnum.REMINDER_SAVE, dto)
  }

  public async saveOnServer(dto: IReminderDTO) {
    const savedDTO = await agent.Reminders.save(dto)
    this.rootStore.remindersStore.updateFromServer(savedDTO)
  }

  public delete(dto: IReminderDTO) {
    dto.IsDeleted = true
    this.save(dto)
  }
}
