import { observable, action, reaction, computed, autorun, makeAutoObservable, makeObservable } from 'mobx'
import { isHydrated, makePersistable } from 'mobx-persist-store'
import { RootStore } from './RootStore'
import { ThemeGenVM } from '../theme-gen/ThemeGenVM'
import { SideMenuVM } from '../side-menu/view-models/SideMenuVM'
import { ThemesListVM } from '../themes/view-models/ThemesListVM'
import { SignalRServiceV3 } from '../signal-r/SignalRServiceV3'
import { IAppStore } from '@elexient/elexiapp.bits.shared'
import { Capacitor } from '@capacitor/core'
import { App, AppState } from '@capacitor/app'
import { SplashScreen } from '@capacitor/splash-screen'
import { StatusBar, Style } from '@capacitor/status-bar'
import { FirebaseService } from '../firebase/FirebaseService'

export class AppStore implements IAppStore {
  private rootStore: RootStore
  @observable private activeModalId: string = ''
  @observable private activeModalHeaderHeight: number = 0
  private skipBackButton: boolean = false
  private listeningToAppStateChange: boolean = false
  @observable private bottomNavBarHeight: number = 56
  @observable private appHeaderHeight: number = 56
  private splashScreenHidden: boolean = false
  private signalRService: SignalRServiceV3
  private statusBarColor: string = 'light'
  private backJustPressedTO: NodeJS.Timer

  constructor(rootStore: RootStore) {
    makeObservable(this)
    makePersistable(this, { name: 'AppStore', properties: ['token', 'theme'] }).then((st) => {
      let isHydrated = false
      if (process.env.NODE_ENV === 'test') isHydrated = true
      if (st?.isHydrated) isHydrated = true
      if (isHydrated) this.onHydrationCompleted()
    })
    this.rootStore = rootStore
    this.signalRService = new SignalRServiceV3(this.rootStore)
    this.sideMenuVM = new SideMenuVM(this.rootStore)
    this.themesListVM = new ThemesListVM(this.rootStore)
    reaction(
      () => this.token,
      (token) => (window.token = token)
    )
    reaction(
      () => this.theme,
      (val) => {
        document.body.classList.remove('dark')
        if (val) document.body.classList.add(val)
        this.checkNativeStatusBarColor()
      }
    )
  }

  @observable public token: string = ''
  @observable public theme = ''
  @observable public currentRoute = ''
  @observable public isMenuOpen: boolean = false
  @observable public isSplitPaneVisible: boolean = false
  @observable public bodyHeight: number = 0
  @observable public themeGenVM: ThemeGenVM = new ThemeGenVM()
  @observable public themesListVM: ThemesListVM = null
  @observable public sideMenuVM: SideMenuVM = null
  public sideMenu: any = null
  public history: any = null
  @observable public pastRoutes: Array<string> = []
  @observable public loadingData: string = ''
  public backJustPressed: boolean = false
  public isHydrated: boolean = false

  public listenToAppStateChange() {
    if (process.env.NODE_ENV === 'test') return
    if (this.listeningToAppStateChange) return
    if (Capacitor.getPlatform() !== 'web') {
      setTimeout(
        () =>
          App.addListener('appStateChange', (state: AppState) => {
            if (!this.isLoggedIn) return
            if (state.isActive) {
              this.rootStore.loadData('app restored')
              this.themeGenVM.listenToSystemThemeChange(true)
            }
          }),
        3000
      )
    }
    this.listeningToAppStateChange = true
  }

  public listenForSignalRUpdate(updateType, callback) {
    this.signalRService.attachNewListener(updateType, callback)
  }

  @action
  public setBodyHeight(val: number) {
    this.bodyHeight = val
  }

  @action
  public setLoadingData(val: string) {
    this.loadingData = val
  }

  @action
  public navigateTo(val: string) {
    this.setCurrentRoute(val)
    this.setMenuOpen(false)
    let doDelay = true
    if (this.currentRoute === '/itemsselect') doDelay = false
    if (!this.history) return
    if (doDelay) {
      setTimeout(() => this.history.push(val), 1)
      return
    }
    this.history.push(val)
  }

  @action
  public navigateToSection(idx: number) {
    if (idx === 0) this.navigateTo('/')
    if (idx === 1) this.navigateTo('/accountslist')
    if (idx === 2) this.navigateTo('/reminderslist')
  }

  public setHistory(e) {
    this.history = e
  }

  public setSideMenu(e) {
    this.sideMenu = e
  }

  public closeSideMenu() {
    this.sideMenu.close()
  }

  public setRootStore(root) {
    this.rootStore = root
    this.sideMenuVM = new SideMenuVM(this.rootStore)
    this.themeGenVM.setRootStore(this.rootStore)
    this.themesListVM.setRootStore(this.rootStore)
  }

  public setMenuOpen(isOpen) {
    this.isMenuOpen = isOpen
  }

  @action
  public setSplitPaneVisible(isVisible) {
    this.isSplitPaneVisible = isVisible
    if (isVisible) {
      document.getElementsByTagName('html')[0].className += ' split-pane-visible'
      return
    }
    let className = document.getElementsByTagName('html')[0].className
    className = className.replace(' split-pane-visible', '')
    document.getElementsByTagName('html')[0].className = className
  }

  @computed
  public get isLoggedIn() {
    if (!this.token) return false
    if (this.token === '') return false
    return true
  }

  @computed
  public get showAppHeader() {
    if (this.isSplitPaneVisible) return true
    if (this.currentRoute === 'login') return false
    if (this.currentRoute === '/itemsselect') return false
    if (this.currentRoute === '/maintabs/itemnew') return false
    if (this.currentRoute === '/maintabs/listitemedit') return false
    if (this.currentRoute === '/accountsettings') return false
    if (this.currentRoute.includes('/itemedit')) return false
    if (this.currentRoute.includes('/accountedit')) return false
    if (this.currentRoute.includes('/transactionedit')) return false
    if (this.currentRoute.includes('/boardsettings')) return false
    if (this.currentRoute.includes('/themegen')) return false
    if (this.currentRoute.includes('/themeslist')) return false
    if (this.currentRoute.includes('/categoryedit')) return false
    if (this.currentRoute.includes('/reminderedit')) return false
    if (this.currentRoute.includes('/transferedit')) return false
    if (this.currentRoute.includes('/ccpaymentedit')) return false
    if (this.currentRoute.includes('/balanceadjustmentedit')) return false
    if (this.currentRoute.includes('/itemsselect')) return false
    if (this.currentRoute.includes('/categorieslist')) return false
    if (this.currentRoute.includes('/transactionslist')) return false
    if (this.currentRoute.includes('/banktransactionslist')) return false
    if (this.currentRoute.includes('/banktransactionedit')) return false
    if (this.sideMenuVM.isTransactionEditPage) return false
    return true
  }

  @computed
  public get pageTitle() {
    if (this.currentRoute === 'login') return 'Budget Board'.toUpperCase()
    if (this.currentRoute === '/itemsselect') return 'Add Item'.toUpperCase()
    if (this.currentRoute === '/') return 'Shopping List'.toUpperCase()
    if (this.currentRoute === '/maintabs/itemnew') return 'New Item'.toUpperCase()
    if (this.currentRoute === '/maintabs/tab2') return 'Meals'.toUpperCase()
    if (this.currentRoute === '/maintabs/tab3') return 'Cookbook'.toUpperCase()
    if (this.currentRoute === '/maintabs/listitemedit') return 'Edit List Item'.toUpperCase()
    return this.currentRoute.toUpperCase()
  }

  @computed
  public get showBackButton() {
    if (this.currentRoute === '/itemsselect') return true
    if (this.currentRoute === '/maintabs/itemnew') return true
    if (this.currentRoute === '/maintabs/listitemedit') return true
    if (this.currentRoute === '/accountsettings') return true
    if (this.currentRoute === '/themegen') return false
    if (this.currentRoute === '/themeslist') return false
    return false
  }

  @computed
  public get currentBottomTabIndex(): number {
    if (this.sideMenuVM.isAccountsSettingsSection) return 1
    if (this.sideMenuVM.isRemindersSection) return 2
    return 0
  }

  @action
  public setToken(token) {
    this.token = token
  }

  @action
  public clearData() {
    this.token = null
    console.log('logged out')
  }

  @action
  public setCurrentRoute(routeName: string) {
    routeName = routeName.toLowerCase()
    if (routeName === '/register/') routeName = '/register'
    this.logPastRoute(routeName)
    if (routeName === this.currentRoute) return
    this.currentRoute = routeName
    if (this.lastRoute === routeName) return
    if (this.currentRoute === '/') this.pastRoutes = []
  }

  @action
  private logPastRoute(routeName) {
    if (this.pastRoutes.length > 0 && this.pastRoutes[this.pastRoutes.length - 1] === routeName) return
    this.pastRoutes.push(routeName)
  }

  @computed
  public get lastRoute(): string {
    if (this.pastRoutes.length === 0) return null
    if (this.pastRoutes.length === 1) return null
    return this.pastRoutes[this.pastRoutes.length - 2]
  }

  @action
  public computeBottomNavBarHeight() {
    this.bottomNavBarHeight = this.getBottomNavBarHeight()
  }

  @action
  public computeAppHeaderHeight() {
    this.appHeaderHeight = this.getAppHeaderHeight()
  }

  private getAppHeaderHeight(): number {
    try {
      const computed = window.getComputedStyle(document.getElementById('HeaderToolbar')).height
      if (computed) {
        // console.log('App Header Height: ' + computed)
        if (computed === 'auto') return 56
        return Number(computed.replace('px', ''))
      }
    } catch (ex) {}
    return 56
  }

  private getBottomNavBarHeight(): number {
    try {
      const computed = window.getComputedStyle(document.getElementById('BottomTabs')).height
      if (computed) {
        // console.log('Bottom Tabs Height: ' + computed)
        if (computed === 'auto') return 56
        return Number(computed.replace('px', ''))
      }
    } catch (ex) {}
    return 56
  }

  @action
  public setSkipBackButton(val: boolean = true) {
    this.skipBackButton = val
  }

  @action
  public handleGoBack(history = null) {
    if (this.skipBackButton) {
      this.skipBackButton = false
      return
    }

    let debug = false
    if (process.env.NODE_ENV === 'production') debug = false
    if (debug) console.log('Current: ' + this.currentRoute)
    if (debug) console.log('Past: ' + this.lastRoute)

    if (!history) history = this.history
    if (!this.currentRoute) this.setCurrentRoute(this.history.location.pathname)
    let newRoute = '/'
    if (this.currentRoute === '/maintabs/itemnew') newRoute = '/itemsselect'
    if (this.currentRoute.includes('/itemedit') && this.lastRoute === '/itemsselect') newRoute = '/itemsselect'
    if (this.currentRoute.includes('/itemedit') && this.lastRoute === '/itemslist') newRoute = '/itemslist'
    if (this.currentRoute === '/categorieslist') newRoute = '/'
    if (this.currentRoute.includes('/recipecategoryedit')) newRoute = '/recipecategorieslist'
    if (this.currentRoute.includes('/recipecategorieslist')) newRoute = '/recipeslist'
    if (this.currentRoute.includes('/mealcategorieslist')) newRoute = '/mealslist'
    if (this.currentRoute.includes('/mealcategoryedit')) newRoute = '/mealcategorieslist'
    if (this.currentRoute.includes('/categoryedit')) newRoute = '/categorieslist'
    if (this.currentRoute.includes('/mealedit')) newRoute = '/mealslist'
    if (this.currentRoute.includes('/accountedit')) newRoute = '/accountslist'
    if (this.currentRoute.includes('/transactionslist')) newRoute = '/accountslist'
    if (this.currentRoute.includes('/reminderedit')) newRoute = '/reminderslist'
    if (this.currentRoute === '/register') newRoute = '/login'
    if (this.currentRoute === '/forgot-password') newRoute = '/login'

    if (this.pastRoutes.length >= 1) {
      if (debug) console.log('before pop')
      if (debug) console.log(this.pastRoutes.slice())
      if (this.lastRoute && this.lastRoute.includes('/mealedit/') && !this.currentRoute.includes('/mealedit/')) {
        newRoute = this.lastRoute
      }
      this.pastRoutes.pop()
    }

    if (!this.isWeb) {
      if (this.currentRoute === '/' || this.currentRoute === '/login') {
        App.exitApp()
      }
    }

    this.setBackJustPressed()

    if (debug) console.log('after pop')
    if (debug) console.log(this.lastRoute)
    if (debug) console.log(this.pastRoutes.slice())

    history.replace(newRoute)
  }

  @action
  public setBackJustPressed() {
    this.backJustPressed = true
    if (this.backJustPressedTO) clearTimeout(this.backJustPressedTO)
    this.backJustPressedTO = setTimeout(() => this.clearBackJustPressed(), 1000)
  }

  @action
  public clearBackJustPressed() {
    this.backJustPressed = false
  }

  @computed
  public get isHybridIos() {
    const classNames = document.getElementsByTagName('html')[0].className
    if (classNames.indexOf('plt-hybrid') === -1) return false
    if (classNames.indexOf('plt-ios') === -1) return false
    return true
  }

  @computed
  public get isIos() {
    const classNames = document.getElementsByTagName('html')[0].className
    if (classNames.indexOf('plt-ios') === -1) return false
    return true
  }

  @computed
  public get isAndroid() {
    const classNames = document.getElementsByTagName('html')[0].className
    if (classNames.indexOf('plt-android') === -1) return false
    return true
  }

  @computed
  public get isAndroidNative() {
    const classNames = document.getElementsByTagName('html')[0].className
    if (classNames.indexOf('plt-android') === -1) return false
    if (classNames.indexOf('plt-hybrid') === -1) return false
    return true
  }

  @computed
  public get isWeb() {
    if (process.env.NODE_ENV === 'test') return false
    if (Capacitor.platform === 'web') return true
    return false
  }

  @computed
  public get isWindows() {
    const classNames = document.getElementsByTagName('html')[0].className
    if (classNames.indexOf('plt-windows') === -1) return false
    return true
  }

  @action
  public hideSplashScreen() {
    if (process.env.NODE_ENV === 'test') return
    if (Capacitor.platform === 'web') return
    if (this.splashScreenHidden) return
    SplashScreen.hide()
    this.splashScreenHidden = true
  }

  @action
  public async checkNativeStatusBarColor() {
    if (Capacitor.getPlatform() === 'web') return
    if (!this.splashScreenHidden) return
    // if (!this.isLoggedIn) return
    let statusBarColor = 'light'
    if (this.isLoggedIn) statusBarColor = 'dark'
    const options = {
      style: statusBarColor === 'dark' ? Style.Dark : Style.Light,
    }
    await StatusBar.setStyle(options)
    this.statusBarColor = statusBarColor
    if (this.isIos) return
    let color = this.themeGenVM.primaryColor
    if (!this.isLoggedIn) color = this.themeGenVM.mediumColor
    await StatusBar.setBackgroundColor({
      color: color,
    })
  }

  @computed
  private get pageOffsetHeight() {
    if (this.currentRoute === '/recipeslist') {
      if (this.isIos) return 0
      return 0
    }
    if (this.currentRoute === '/recipeedit') {
      return 83
    }
    // if (this.currentRoute === '/reminderslist') {
    //   if (this.isIos) return 44
    //   return 56
    // }
    if (this.currentRoute === '/mealslist') {
      return 0
    }
    if (this.currentRoute === '/') {
      if (this.isIos) return 0
      return 0
    }
    if (this.isIos) return 0
    return 10
  }

  @computed
  public get listHeight(): number {
    const BOTTOM_NAV_BAR_HEIGHT = this.bottomNavBarHeight
    const APP_HEADER_HEIGHT = this.appHeaderHeight
    // const IOS_HYBRID_TOP_PADDING = 20
    const PAGE_TOOLBAR_HEIGHT = !this.isSplitPaneVisible ? 56 : 46
    let height = this.bodyHeight
    height = height - PAGE_TOOLBAR_HEIGHT
    height = height - this.pageOffsetHeight
    if (!this.isSplitPaneVisible && this.showAppHeader) height = height - BOTTOM_NAV_BAR_HEIGHT
    if (this.showAppHeader) height = height - APP_HEADER_HEIGHT
    // if (this.isHybridIos) height = height - IOS_HYBRID_TOP_PADDING
    height = height + 10
    return height
  }

  @computed
  public get listHeightForModal(): number {
    const modal = document.getElementById(this.activeModalId)
    if (!modal) {
      if (this.isSplitPaneVisible) return 0
      return 0
    }
    const wrapper = document.getElementsByClassName('modal-wrapper')[0]
    let height = wrapper.clientHeight
    height = height - this.activeModalHeaderHeight
    if (this.isHybridIos) height = height - 20
    return height
  }

  @computed
  public get listHeightPx(): string {
    return this.listHeight.toString() + 'px'
  }

  @computed
  public get listHeightForModalPx(): string {
    return this.listHeightForModal.toString() + 'px'
  }

  @action
  public setActiveModal(val: string, modalHeaderHeight: number) {
    this.activeModalId = ''
    this.activeModalId = val
    this.activeModalHeaderHeight = modalHeaderHeight
  }

  @computed
  public get fabOffset(): string {
    if (this.isHybridIos && !this.isSplitPaneVisible && this.showAppHeader)
      return -(64 + this.bottomNavBarHeight) + 'px'
    if (!this.isSplitPaneVisible && this.showAppHeader) return '-120px'
    return '0px'
  }

  @action
  public onHydrationCompleted() {
    this.themeGenVM.onHydrationCompleted()
    this.listenToAppStateChange()
    this.computeIsWindows()
    this.checkNativeStatusBarColor()
    this.isHydrated = true
    if (this.theme) document.body.classList.add(this.theme)
  }

  private computeIsWindows() {
    let isWindows = false
    if (!navigator) return false
    if (navigator.platform.indexOf('Win') > -1) isWindows = true
    if (this.isHybridIos) isWindows = false
    if (this.isIos) isWindows = false
    if (this.isAndroid) isWindows = false
    document.getElementsByTagName('html')[0].classList.remove('plt-windows')
    if (!isWindows) return
    document.getElementsByTagName('html')[0].className += ' plt-windows'
  }

  @computed
  public get isSimulatedNative() {
    if (process.env.REACT_APP_IS_DEV_MODE === '1') return true
    if (Capacitor.getPlatform() === 'web') return true
    return false
  }

  public applyScrollStyles(e: any) {
    if (this.rootStore.appStore.isWindows) this.doApplyWindowsStyles(e)
    if (this.rootStore.appStore.isAndroid && this.isSimulatedNative) this.doApplyNonWindowsStyles(e)
    if (this.rootStore.appStore.isIos && this.isSimulatedNative) this.doApplyNonWindowsStyles(e)
  }

  private doApplyWindowsStyles(e: HTMLIonContentElement) {
    this.doApplyScrollStyles(e)
    setTimeout(() => this.doApplyScrollStyles(e), 300)
  }

  private doApplyNonWindowsStyles(e: HTMLIonContentElement) {
    this.doHideScrollbar(e)
    setTimeout(() => this.doHideScrollbar(e), 300)
  }

  private doApplyScrollStyles(e: any) {
    if (!e) return
    const styles = document.createElement('style')
    styles.textContent = `
    ::-webkit-scrollbar {
      width: 9px;
      height: 18px;
    }
  
    ::-webkit-scrollbar-thumb {
      height: 6px;
      border: 2px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      -webkit-border-radius: 7px;
      background-color: rgba(0, 0, 0, .4);
      -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
    }
  
    ::-webkit-scrollbar-button {
      width: 0;
      height: 0;
      display: none;
    }
  
    ::-webkit-scrollbar-corner {
      background-color: transparent;
    }
      `
    if (e.shadowRoot) e.shadowRoot.appendChild(styles)
    else if (e.rootNode) e.rootNode.appendChild(styles)
  }

  private doHideScrollbar(e: any) {
    if (!e) return
    const styles = document.createElement('style')
    styles.textContent = `
    ::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  
    ::-webkit-scrollbar-thumb {
      height: 0;
    }
  
    ::-webkit-scrollbar-button {
      width: 0;
      height: 0;
      display: none;
    }
  
    ::-webkit-scrollbar-corner {
      background-color: transparent;
    }
      `
    if (e.shadowRoot) e.shadowRoot.appendChild(styles)
    else if (e.rootNode) e.rootNode.appendChild(styles)
  }
}
