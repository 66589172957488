import React from 'react'
import { IonButton, IonList, IonItemDivider, IonItem, IonText } from '@ionic/react'
import { inject, observer } from 'mobx-react'
import { UserStore } from '../store/UserStore'

interface Props {
  userStore?: UserStore
}

const GoogleLogin: React.FC<Props> = ({ userStore }) => {
  const { userEditVM: vm } = userStore
  if (!vm) return

  const renderRemoveGoogleLogin = () => {
    if (!vm.hasGoogleLogin) return
    return (
      <>
        {/* <IonText>{vm.googleEmail}</IonText>
        &nbsp; */}
        <IonButton>Remove</IonButton>
      </>
    )
  }

  const renderConnectGoogleLogin = () => {
    if (vm.hasGoogleLogin) return
    return (
      <>
        <IonButton onClick={() => vm.connectGoogle()}>Connect</IonButton>
      </>
    )
  }

  return (
    <IonList mode='md'>
      <IonItemDivider color='medium' mode='md'>
        Connected Google Login
      </IonItemDivider>
      <IonItem lines={'inset'}>
        {renderRemoveGoogleLogin()}
        {renderConnectGoogleLogin()}
      </IonItem>
    </IonList>
  )
}

export default inject('userStore')(observer(GoogleLogin))
