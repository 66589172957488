import React from 'react'
import { inject, Observer, observer } from 'mobx-react'
import { IonIcon, IonItem, IonItemOption, IonItemOptions, IonItemSliding, IonText } from '@ionic/react'
import { List } from '@elexient/elexiapp.bits.shared'
import { chevronDown, chevronForward } from 'ionicons/icons'
import { TransactionRowVM } from '../../view-models/edit/TransactionRowVM'
import { RemindersStore } from '../../store/RemindersStore'
import { TransactionGroupRowVM } from '../../view-models/edit/TransactionGroupRowVM'

interface Props {
  remindersStore?: RemindersStore
}

const TransactionsList: React.FC<Props> = ({ remindersStore }) => {
  const editVM = remindersStore.editVM
  if (!editVM) return null
  if (editVM.currentTabIndex !== '2') return null
  const listVM = editVM.transListVM
  if (!listVM) return null

  const renderSkipOption = (row: TransactionRowVM) => {
    if (!row.skippable) return
    return (
      <IonItemOption
        onClick={(e) => {
          e.stopPropagation()
          row.skip()
        }}
      >
        <IonText>Skip</IonText>
        &nbsp;
      </IonItemOption>
    )
  }

  const renderPaidOption = (row: TransactionRowVM) => {
    if (!row.payable) return
    return (
      <IonItemOption
        color='success'
        onClick={(e) => {
          e.stopPropagation()
          row.paid()
        }}
      >
        <IonText>Paid</IonText>
        &nbsp;
      </IonItemOption>
    )
  }

  const renderUnskipOption = (row: TransactionRowVM) => {
    if (!row.unskippable) return
    return (
      <IonItemOption
        color='success'
        onClick={(e) => {
          e.stopPropagation()
          row.unskip()
        }}
      >
        <IonText>Unskip</IonText>
        &nbsp;
      </IonItemOption>
    )
  }

  const renderClearedOption = (row: TransactionRowVM) => {
    if (!row.clearable) return
    return (
      <IonItemOption
        color='success'
        onClick={(e) => {
          e.stopPropagation()
          row.cleared()
        }}
      >
        <IonText>Cleared</IonText>
        &nbsp;
      </IonItemOption>
    )
  }

  const renderNormalRow = (row: TransactionRowVM, index, style) => {
    const style2 = {
      ...style,
      borderLeft: 'none',
    }
    return (
      <Observer key={row.key + String(index) + row.rowType + row.statusName}>
        {() => (
          <IonItemSliding style={style2} onClick={() => row.click()} key={row.key}>
            <IonItem lines='none' className={row.isEven ? 'normal-row even' : 'normal-row'} onClick={() => row.click()}>
              <div className='date'>
                <div>{row.monthAbbr}</div>
                <div>{row.day}</div>
              </div>
              <div className='main-cell'>
                {row.payeeName}
                <div className={row.hasNotes ? 'notes' : 'notes no-notes'}>{row.notes}</div>
              </div>
              <IonText className='numbers'>
                <div className={row.isPositive ? 'positive' : 'negative'}>{row.amountFormatted}</div>
                <div className='status'>{row.statusName}</div>
              </IonText>
            </IonItem>
            <IonItemOptions side='end'>
              {renderSkipOption(row)}
              {renderPaidOption(row)}
              {renderClearedOption(row)}
              {renderUnskipOption(row)}
            </IonItemOptions>
          </IonItemSliding>
        )}
      </Observer>
    )
  }

  const renderRightIcon = (row: TransactionGroupRowVM) => {
    if (!row.isCollapsed) return
    return <IonIcon icon={chevronForward} />
  }

  const renderDownIcon = (row: TransactionGroupRowVM) => {
    if (row.isCollapsed) return
    return <IonIcon icon={chevronDown} />
  }

  const renderGroupRow = (row: TransactionGroupRowVM, index, style) => {
    return (
      <Observer key={row.key + String(index) + row.rowType + row.isCollapsed + row.isInvView}>
        {() => (
          <div style={style} className={row.rowType} onClick={() => row.click()}>
            <div className='main-cell'>{row.displayName}</div>
            {renderRightIcon(row)}
            {renderDownIcon(row)}
          </div>
        )}
      </Observer>
    )
  }

  return (
    <List
      listVM={listVM}
      className='list'
      normalRow={(row, index, style) => renderNormalRow(row, index, style)}
      groupRow={(row, index, style) => renderGroupRow(row, index, style)}
    />
  )
}

export default inject('remindersStore')(observer(TransactionsList))
