import { observable, action, makeObservable } from 'mobx'
import { serializable } from 'serializr'

export class User {
  constructor() {
    makeObservable(this)
  }

  @serializable @observable public AppUserId: number = 0
  @serializable @observable public Name: string = ''
  @serializable @observable public EmailAddress: string = ''
  @serializable @observable public IdentityId: string = ''
  @serializable @observable public GoogleEmailAddress: string = ''
  @serializable @observable public HasGoogleLogin: boolean = false
  @serializable @observable public AppleEmailAddress: string = ''
  @serializable @observable public HasAppleLogin: boolean = false
  @serializable @observable public WebNotificationsEnabled: boolean = false

  @action
  public setName(val) {
    this.Name = val
  }

  @action
  public setEmailAddress(val) {
    this.EmailAddress = val
  }

  @action
  public setWebNotificationsEnabled(val) {
    this.WebNotificationsEnabled = val
  }

  @action
  public setUserId(val) {
    this.AppUserId = val
  }
}
