import React from 'react'
import { inject, Observer, observer } from 'mobx-react'
import { BankTransactionsStore } from '../../store/BankTransactionsStore'
import { IonBadge, IonIcon, IonItem, IonItemOption, IonItemOptions, IonItemSliding, IonText } from '@ionic/react'
import { List } from '@elexient/elexiapp.bits.shared'
import { BankTransactionGroupRowVM } from '../../view-models/list/BankTransactionGroupRowVM'
import { chevronDown, chevronForward } from 'ionicons/icons'
import { BankTransactionRowVM } from '../../view-models/list/BankTransactionRowVM'

interface Props {
  bankTransactionsStore?: BankTransactionsStore
}

const BankTransactionsList: React.FC<Props> = ({ bankTransactionsStore }) => {
  const listVM = bankTransactionsStore.listVM
  if (!listVM) return null

  const renderIgnoreOption = (row: BankTransactionRowVM) => {
    return (
      <IonItemOption
        onClick={(e) => {
          e.stopPropagation()
          // row.ignore()
        }}
      >
        <IonText>Skip</IonText>
        &nbsp;
      </IonItemOption>
    )
  }

  const renderPending = (row: BankTransactionRowVM) => {
    if (!row.isPending) return
    return <IonBadge color='dark'>Pending</IonBadge>
  }

  const renderNormalRow = (row: BankTransactionRowVM, index, style) => {
    return (
      <Observer key={row.key + String(index) + row.rowType + row.amountFormatted}>
        {() => (
          <IonItemSliding style={style} onClick={() => row.click()} key={row.key}>
            <IonItem lines='none' className={row.isEven ? 'normal-row even' : 'normal-row'} onClick={() => row.click()}>
              <div className='date'>
                <div>{row.monthAbbr}</div>
                <div>{row.day}</div>
              </div>
              <div className='payee'>{row.payeeName}</div>
              <div className='badges'>{renderPending(row)}</div>
              <IonText className='numbers'>
                <div className={row.isPositive ? 'positive' : 'negative'}>{row.amountFormatted}</div>
              </IonText>
            </IonItem>
            <IonItemOptions side='end'>{renderIgnoreOption(row)}</IonItemOptions>
          </IonItemSliding>
        )}
      </Observer>
    )
  }

  const renderRightIcon = (row: BankTransactionGroupRowVM) => {
    if (!row.isCollapsed) return
    return <IonIcon icon={chevronForward} />
  }

  const renderDownIcon = (row: BankTransactionGroupRowVM) => {
    if (row.isCollapsed) return
    return <IonIcon icon={chevronDown} />
  }

  const renderChildrenCount = (row: BankTransactionGroupRowVM) => {
    if (!row.childrenCount) return
    if (!row.isCollapsed) return
    return (
      <IonBadge color='secondary' className='count'>
        {row.childrenCount}
      </IonBadge>
    )
  }

  const renderGroupRow = (row: BankTransactionGroupRowVM, index, style) => {
    return (
      <Observer key={row.key + String(index) + row.rowType + row.isCollapsed + row.isInvView}>
        {() => (
          <div style={style} className={row.rowType} onClick={() => row.click()}>
            <div className='main-cell'>
              {row.displayName}
              {renderChildrenCount(row)}
            </div>
            {renderRightIcon(row)}
            {renderDownIcon(row)}
          </div>
        )}
      </Observer>
    )
  }

  return (
    <List
      key={listVM.renderVersion}
      listVM={listVM}
      className='list'
      normalRow={(row, index, style) => renderNormalRow(row, index, style)}
      groupRow={(row, index, style) => renderGroupRow(row, index, style)}
    />
  )
}

export default inject('bankTransactionsStore')(observer(BankTransactionsList))
