import { action, computed, makeObservable } from 'mobx'
import { RootStore } from '../../../stores/RootStore'
import { Reminder } from '../../../reminders/aggregate/Reminder'

export class ToDoRowVM {
  constructor(private rootStore: RootStore, private reminder: Reminder) {
    makeObservable(this)
  }

  @computed
  public get name(): string {
    return this.reminder.Name
  }

  public get key(): string {
    return this.reminder.ReminderGuid
  }

  @action
  public click() {
    this.rootStore.appStore.navigateTo('/reminderedit/' + this.reminder.ReminderGuid)
  }
}
