import React from 'react'
import { inject, observer } from 'mobx-react'
import { AppStore } from '../../../stores/AppStore'
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCheckbox,
  IonCol,
  IonGrid,
  IonItem,
  IonList,
  IonRow,
  IonText,
} from '@ionic/react'
import { BankTransactionsStore } from '../../store/BankTransactionsStore'
import { OptionRowVM } from '../../view-models/edit/OptionRowVM'

interface Props {
  bankTransactionsStore?: BankTransactionsStore
  appStore?: AppStore
}

const DetailsForm: React.FC<Props> = ({ bankTransactionsStore, appStore }) => {
  const editVM = bankTransactionsStore.editVM
  if (!editVM) return null
  if (appStore.bodyHeight === 0) return null

  const renderRelative = (row: OptionRowVM) => {
    if (!row.relative) return
    return <div className='relative'>{row.relative}</div>
  }

  const renderMatches = () => {
    return (
      <IonList>
        {editVM.options.map((e) => (
          <IonItem key={e.key} className={e.className} onClick={() => e.click()}>
            <IonCheckbox checked={e.checked} mode='ios' />
            <div className='col-1'>
              {e.col1}
              {renderRelative(e)}
            </div>
            <div className='payee'>{e.payeeName}</div>
            <div className='amt'>{e.amountFormatted}</div>
          </IonItem>
        ))}
      </IonList>
    )
  }

  const renderDetails = () => {
    return (
      <IonCard>
        <IonCardHeader color='tertiary'>Bank Transaction Details</IonCardHeader>
        <IonCardContent>
          <IonGrid>
            <IonRow>
              <IonCol>
                <IonText>
                  <h2>{editVM.dateFormatted}</h2>
                </IonText>
              </IonCol>
              <IonCol className='status'>
                <IonText>
                  <h3>{editVM.statusName}</h3>
                </IonText>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonText color='primary'>
                  <h1>{editVM.payeeName}</h1>
                </IonText>
              </IonCol>
              <IonCol sizeXs='3' className='amt'>
                <IonText>
                  <h1>{editVM.amountFormatted}</h1>
                </IonText>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonText>
                  {editVM.longPayeeName}
                </IonText>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonCardContent>
      </IonCard>
    )
  }

  return (
    <div id='DetailsForm'>
      {renderDetails()}
      {renderMatches()}
    </div>
  )
}

export default inject('bankTransactionsStore', 'appStore')(observer(DetailsForm))
