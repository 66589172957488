import { GroupRowVM } from '@elexient/elexiapp.bits.shared'
import { computed } from 'mobx'
import { RootStore as ThisRootStore } from '../../stores/RootStore'
import { AccountsListVM } from './AccountsListVM'
import Sugar from 'sugar'

export class AccountGroupRowVM extends GroupRowVM<ThisRootStore, AccountsListVM> {
  @computed
  public get displayName(): string {
    return this.name
  }

  public get color(): string {
    throw new Error('Method not implemented.')
  }

  @computed
  public get isPositive(): boolean {
    return Boolean(this.balance >= 0)
  }

  @computed
  public get balance(): number {
    let sum = 0
    this.rootStore.accountsStore.currentBoardRecords
      .filter((e) => e.TypeName === this.name)
      .forEach((e) => (sum += e.Balance))
    return sum
  }

  @computed
  public get balanceString(): string {
    return Sugar.Number(this.balance).format(2).raw
  }
}
