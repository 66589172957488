import { observable, action, computed, makeObservable, runInAction } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { PlaidItem } from '../aggregate/PlaidItem'
import { PlaidItemAccountsListVM } from '../view-models/PlaidItemAccountsListVM'
import { IPlaidItemDTO } from '../dtos/IPlaidItemDTO'
import { DataStore } from '@elexient/elexiapp.bits.shared'
import AgentV2 from '../../AgentV2'
import { makePersistable } from 'mobx-persist-store'
import { deserialize } from 'serializr'

export class PlaidItemsStore extends DataStore<RootStore, PlaidItem, IPlaidItemDTO> {
  protected worker: any

  constructor(rootStore: RootStore) {
    super(rootStore, PlaidItem, AgentV2, 'PlaidItems', 'PlaidItem')
    makeObservable(this)
    makePersistable(this, { name: 'PlaidItemsStore', properties: ['persistedRecords'] }).then(
      action((st) => this.onHydrationCompleted(st?.isHydrated))
    )
  }

  @action
  public onHydrationCompleted(isHydrated?: boolean) {
    this.persistedRecords = observable.array(
      this.persistedRecords.map((e) => makeObservable(deserialize(PlaidItem, e)))
    )
    this.setRecords(this.persistedRecords)
    this.isHydrated = Boolean(isHydrated)
    if (process.env.NODE_ENV === 'test') this.isHydrated = true
  }

  @observable.shallow public persistedRecords: Array<PlaidItem> = []
  @observable public listVM: PlaidItemAccountsListVM = null
  @observable public isHydrated: boolean = false

  @action
  public loadListVM(accountGuid: string) {
    this.listVM = new PlaidItemAccountsListVM(this.rootStore, accountGuid)
  }

  public onLoadDataCompleted(): void {
    if (!this.listVM) return
    this.listVM.reloadRows()
  }

  @computed
  public get currentBoardRecords() {
    if (!this.isHydrated) return []
    return this.persistedRecords.filter((e) => e.BoardId === this.rootStore.boardsStore.currentBoardId)
  }
}
