import { observable, action, runInAction, computed, autorun } from 'mobx'
import { RootStore } from '../stores/RootStore'
import { deserialize, serialize } from 'serializr'
import { IAggregrate } from './IAggregate'
import { IDataStore } from './IDataStore'
import * as DataStoreServiceWorker from './DataStoreService.worker'
import { IDTO } from './IDTO'
import Agent from '../Agent'
import { delay } from './delay'

export class DataStore<T extends IAggregrate, DTO extends IDTO> implements IDataStore<T, DTO> {
  protected rootStore: RootStore
  private type: any
  private agentCategory: string
  private primaryKey: string
  private records: Array<T> = null
  private itemType: string

  constructor(rootStore: RootStore, c: { new (): T }, agentCategory: string, itemType: string) {
    this.rootStore = rootStore
    this.rootStore.appStore.listenForSignalRUpdate(itemType, e => this.updateFromServer(e))
    this.primaryKey = itemType + 'Guid'
    this.agentCategory = agentCategory
    this.type = c
    this.itemType = itemType
  }

  protected setRecords(records) {
    this.records = records
  }

  public async loadData(): Promise<void> {
    if (this.rootStore.actionsStore.hasPendingActions(this.itemType)) {
      return delay(2000).then(() => this.loadData())
    }
    const items = await this.getItems()
    if (!items) return
    const arr = this.records as any
    arr.replace(items.map(e => deserialize(this.type, e)))
  }

  private async getItems(): Promise<IDTO[]> {
    return await Agent[this.agentCategory].getAll()
  }

  @action
  public delete(guid: string) {
    const idx = this.records.findIndex(e => e[this.primaryKey] === guid)
    if (idx > -1) this.records.splice(idx, 1)
  }

  private getIndex(guid: string): number {
    return this.records.findIndex(e => e[this.primaryKey] === guid)
  }

  public get(guid: string): T {
    return this.records.find(e => e[this.primaryKey] === guid)
  }

  public updateFromServer(fromItem: DTO) {
    if (!fromItem) return
    const itemGuid = fromItem[this.primaryKey]
    if (fromItem.IsDeleted) {
      this.delete(itemGuid)
      return
    }
    const item: T = deserialize(this.type, fromItem)
    const existingItemIndex = this.getIndex(fromItem[this.primaryKey])
    if (existingItemIndex === -1) {
      this.records.push(item)
      return
    }
    this.records[existingItemIndex] = item
  }

  @action
  public clearData() {
    const arr = this.records as any
    arr.replace([])
  }
}
