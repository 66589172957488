import agent from '../../Agent'
import { RootStore } from '../../stores/RootStore'
import { IAccountDTO } from '../dtos/IAccountDTO'
import { ActionTypeEnum } from '../../actions/enum/ActionTypeEnum'

export class AccountsService {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  public save(dto: IAccountDTO) {
    this.rootStore.accountsStore.updateFromServer(dto)
    this.rootStore.actionsStore.addAction(ActionTypeEnum.ACCOUNT_SAVE, dto)
  }

  public async saveOnServer(dto: IAccountDTO) {
    const savedDTO = await agent.Accounts.save(dto)
    this.rootStore.accountsStore.updateFromServer(savedDTO)
  }

  public delete(dto: IAccountDTO) {
    dto.IsDeleted = true
    this.save(dto)
  }
}
