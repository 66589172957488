import React from 'react'
import {
  IonContent,
  IonIcon,
  IonButton,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonAlert,
  IonFab,
  IonFabButton,
} from '@ionic/react'
import { observer, inject, Observer } from 'mobx-react'
import { close, trashOutline, checkmarkSharp } from 'ionicons/icons'
import { TransactionsStore } from '../../store/TransactionsStore'
import { AppStore } from '../../../stores/AppStore'
import DetailsForm from './DetailsForm'
import BackIcon from '../../../shared/BackIcon'

interface Props {
  transactionsStore?: TransactionsStore
  appStore?: AppStore
}

const TransactionEditArea: React.FC<Props> = ({ transactionsStore, appStore }) => {
  const { editVM } = transactionsStore
  if (!editVM) return null

  const renderAppHeader = () => {
    if (!appStore.showAppHeader) return
    return (
      <IonHeader>
        <IonToolbar />
      </IonHeader>
    )
  }

  const renderDeleteConfirm = () => {
    if (!editVM.deleteConfirmShown) return
    return (
      <IonAlert
        isOpen={true}
        onDidDismiss={() => editVM.hideDeleteConfirm()}
        header={'Delete Transaction'}
        message={'Are you sure you wish to delete ' + editVM.payeeName}
        buttons={[
          {
            text: 'No',
            role: 'cancel',
            cssClass: 'primary',
            handler: () => editVM.hideDeleteConfirm(),
          },
          {
            text: 'Yes',
            handler: () => editVM.delete(),
          },
        ]}
      />
    )
  }

  return (
    <>
      {renderAppHeader()}
      <IonHeader id='TransactionEditPage_Header'>
        <IonToolbar color='secondary'>
          <IonButtons slot='start'>
            <IonButton onClick={() => editVM.goBack()}>
              <BackIcon />
            </IonButton>
          </IonButtons>
          <IonTitle>{editVM.pageTitle}</IonTitle>
          <IonButtons slot='end'>
            {!editVM.isNew && (
              <IonButton onClick={() => editVM.showDeleteConfirm()}>
                <IonIcon icon={trashOutline} />
              </IonButton>
            )}
            {editVM.isNew && (
              <IonButton onClick={() => editVM.goBack()}>
                <IonIcon icon={close} />
              </IonButton>
            )}
            <IonButton disabled={!editVM.isValid} onClick={() => editVM.save()}>
              <IonIcon icon={checkmarkSharp} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent id='TransactionEditPage_Content'>
        <DetailsForm />
      </IonContent>
      {renderDeleteConfirm()}
      <Observer>
        {() => {
          return (
            <IonFab vertical='bottom' horizontal='end'>
              <IonFabButton
                color='tertiary'
                disabled={!editVM.isValid}
                onClick={() => editVM.save()}
                style={{
                  marginTop: '-60px',
                  marginLeft: '-60px',
                }}
              >
                <IonIcon icon={checkmarkSharp} />
              </IonFabButton>
            </IonFab>
          )
        }}
      </Observer>
    </>
  )
}

export default inject('transactionsStore', 'appStore')(observer(TransactionEditArea))
